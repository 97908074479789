import { Typography } from 'antd';
import moment from 'moment';
import { useRef, useEffect } from 'react';
import { useReactToPrint } from 'react-to-print';
import PropTypes from 'prop-types';

import './PrintMedia.scss';

const PrintMedia = ({ printData, setPrintData, setting, language }) => {
  const componentRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  useEffect(() => {
    handlePrint();
    setPrintData({});
  }, []);

  
  return (
    <div ref={componentRef} className="receipt-wrapper">
      {Object.keys(printData).length ? (
        <div className="print-data-wrapper">
          <div className="company-customer-info">
            <div
              className="letter-head-wrapper"
              dangerouslySetInnerHTML={{ __html: setting.letterhead }}
            />
            <div
              className={`${
                setting.is_rtl === '1'
                  ? 'print-wrapper-user-info-is_rtl'
                  : 'print-wrapper-user-info'
              }`}
            >
              <Typography style={{ color: 'black' }}>
                {`${language[setting.user_language].invoiceNumber}: ${
                  printData.rtno
                  
                }`}
              </Typography>
             
              <Typography style={{ color: 'black' }}>
                {`${language[setting.user_language].date} : ${moment(
                  printData.date
                ).format('DD/MM/YYYY hh:mm:ss')}`}
              </Typography>

              <Typography
                style={
                  setting.is_rtl === '1'
                    ? { direction: 'rtl' }
                    : { direction: 'ltr' }
                }
              >
                {`${language[setting.user_language].party_Name} : ${
                  printData.customerName || 'Walking Customer'
                }`}
              </Typography>
            </div>
          </div>
          <table
            className="item-table"
            width="97%"
            style={
              setting.is_rtl === '1'
                ? { direction: 'rtl' }
                : { direction: 'ltr' }
            }
          >
            <thead>
              <tr>
                <th
                  style={{
                    color: 'black',
                  }}
                >
                  <div className="top_row">
                    <div>{language[setting.user_language].quantity}</div>
                    <div>{language[setting.user_language].price}</div>
                    {setting.productdiscount === 1 ? (
                      <div>{language[setting.user_language].discount}</div>
                    ) : null}
                    {setting.packsquantity === 1 ? (
                      <div>{language[setting.user_language].pack}</div>
                    ) : null}
                    <div>{language[setting.user_language].total}</div>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody style={{ color: 'black' }}>
              {printData.data.map(
                (d) =>
                  !d.deleted && (
                    <tr key={d.uuid}>
                      <td>
                        <div className="top_row">{d.productname}</div>
                        <div className="top_row">
                          <div style={{ color: 'black', direction: 'ltr' }}>
                            {d.quantity}
                          </div>
                          <div style={{ color: 'black', direction: 'ltr' }}>
                            {d.price}
                          </div>
                          {setting.productdiscount === 1 ? (
                            <div style={{ color: 'black', direction: 'ltr' }}>
                              {d.discount}
                            </div>
                          ) : null}
                          {setting.packsquantity === 1 ? (
                            <div style={{ color: 'black', direction: 'ltr' }}>
                              {d.pack}
                            </div>
                          ) : null}
                          <div style={{ color: 'black', direction: 'ltr' }}>
                            {d.total}
                          </div>
                        </div>
                      </td>
                    </tr>
                  )
              )}
            </tbody>
          </table>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              borderBottom: '1px solid black',
            }}
          >
            <Typography style={{ color: 'black' }}>
              <span>{`${language[setting.user_language].total_excule}`}</span>
              <span style={{ direction: 'ltr' }}>{printData.total}</span>
            </Typography>
          </div>

          <div style={{ width: '97%' }}>
            <table
              width="100%"
              style={
                setting.is_rtl === '1'
                  ? { direction: 'rtl' }
                  : { direction: 'ltr' }
              }
            >
              <tbody>
                <tr>
                  <td>
                    <Typography style={{ color: 'black' }}>
                      {language[setting.user_language].gst}
                    </Typography>
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    <Typography style={{ color: 'black', direction: 'ltr' }}>
                      {printData.gstPercentage || 0}
                    </Typography>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Typography style={{ color: 'black' }}>
                      {language[setting.user_language].serviceCharges}
                    </Typography>
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    <Typography style={{ color: 'black', direction: 'ltr' }}>
                      {printData.extraCharges || 0}
                    </Typography>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Typography style={{ color: 'black' }}>
                      {language[setting.user_language].discount}
                    </Typography>
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    <Typography style={{ color: 'black', direction: 'ltr' }}>
                      {printData.discount || 0}
                    </Typography>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Typography style={{ color: 'black' }}>
                      {language[setting.user_language].grandTotal}
                    </Typography>
                  </td>
                  <td style={{ textAlign: 'right', direction: 'ltr' }}>
                    <Typography style={{ color: 'black' }}>
                      {printData.gTotal}
                    </Typography>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Typography style={{ color: 'black' }}>
                      {language[setting.user_language].promo_code}
                    </Typography>
                  </td>
                  <td style={{ textAlign: 'right', direction: 'ltr' }}>
                    <Typography style={{ color: 'black' }}>
                      {printData.promodiscount}
                    </Typography>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Typography style={{ color: 'black' }}>
                      {language[setting.user_language].payableAmount}
                    </Typography>
                  </td>
                  <td style={{ textAlign: 'right', direction: 'ltr' }}>
                    <Typography style={{ color: 'black' }}>
                      {printData.payableamount}
                    </Typography>
                  </td>
                </tr>

                <tr>
                  <td>
                    <Typography style={{ color: 'black' }}>
                      {language[setting.user_language].amountPaid}
                    </Typography>
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    <Typography style={{ color: 'black', direction: 'ltr' }}>
                      {printData.paidAmount}
                    </Typography>
                  </td>
                </tr>
                <tr>
                  <td />
                </tr>
                <tr
                  style={{
                    borderBottom: '1px solid black',
                    borderTop: '1px solid black',
                  }}
                >
                  <td>
                    <Typography style={{ color: 'black' }}>
                      {language[setting.user_language].balance}
                    </Typography>
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    <Typography style={{ color: 'black', direction: 'ltr' }}>
                      {printData.payableamount - printData.paidAmount}
                    </Typography>
                  </td>
                </tr>
                
                {printData.showOnReceipt&&<tr
                 style={
                  setting.is_rtl === '1'
                    ? { direction: 'rtl' }
                    : { direction: 'ltr' }
                }
                >
                  <td>
                    <Typography style={{ color: 'black' }}>
                    {language[setting.user_language].comments}: {printData.comment}
                    </Typography>
                  </td>
                </tr>}
              </tbody>
            </table>
          </div>
        </div>
      ) : null}
    </div>
  );
};
export default PrintMedia;

PrintMedia.propTypes = {
  setting: PropTypes.object.isRequired,
  language: PropTypes.object.isRequired,
  setPrintData: PropTypes.func.isRequired,
  printData: PropTypes.object.isRequired,
};
