import { setLocalStorage } from 'modules/common/utility/localStorage';
import instance from './interceptor';

export const customerApi = {
  getCustomerList: async () => instance.get('customers/userCustomers'),
  getCustomerProduct: async (id) =>
    instance.get(`inovice/purchasedProducts/customer/${id}`),
};

export const getCustomers = async () => {
  try {
    const { data } = await instance.get('customers/userCustomers');
    setLocalStorage('customers', data.data.customers);
    return data;
  } catch (error) {
    return error;
  }
};

export const getCustomerData = async (id) => {
  try {
    const { data } = await instance.get(`customers/customer/${id}`);
    return data;
  } catch (error) {
    return error;
  }
};
