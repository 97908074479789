import { BrowserRouter } from 'react-router-dom';

import ProtectedRouting from 'modules/common/routing/ProtectedRouting';

const Routing = () => (
  <BrowserRouter basename={process.env.REACT_PUBLIC_URL}>
    <ProtectedRouting />
  </BrowserRouter>
);

export default Routing;
