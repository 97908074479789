import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import App from './App';
import './index.scss';
import 'antd/dist/antd.min.css';

import { store } from './store/store';
/* eslint-disable */
import reportWebVitals from './reportWebVitals';
/* eslint-enable */

Sentry.init({
  dsn: 'https://e4e87e56730c4978bdd6f92eee79c31a@o1320088.ingest.sentry.io/6576113',
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

reportWebVitals();
