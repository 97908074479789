import Icon from '@ant-design/icons';
import { ReactComponent as PaymentIcon } from 'assets/icons/payment.svg';
import { ReactComponent as InvoiceIcon } from 'assets/icons/invoice.svg';
import { ReactComponent as DashboardIcon } from 'assets/icons/dashboard.svg';
import { ReactComponent as DeliveryIcon } from 'assets/icons/dashboard.svg';
// import { ReactComponent as RemainderIcon } from 'assets/icons/remainder.svg';
import { ReactComponent as SalaryIcon } from 'assets/icons/salary.svg';
import { ReactComponent as ExpenditureIcon } from 'assets/icons/expenditure.svg';
import { ReactComponent as EditInvoiceIcon } from 'assets/icons/edit_invoice.svg';
import { ReactComponent as RemainderIcon } from 'assets/icons/remainder.svg';
import { ReactComponent as SettingIcon } from 'assets/icons/setting.svg';
import { ReactComponent as ReturnIcon } from 'assets/icons/remainder.svg';


import { AdminPanel } from 'modules/common/utility/config';
// import { ReactComponent as ExpenseIcon } from 'assets/icons/expense.svg';

export const routes = [
    {
    id: 1,
    key: '1',
    name: 'Dashboard',
    path: '/dashboard',
    icon: (
      <Icon
        component={() => (
          <DashboardIcon style={{ width: '16px', height: '16px' }} />
        )}
      />
    ),
  },
  {
    id: 2,
    key: '2',
    name: 'Invoice Sale',
    path: '/invoice',
    icon: (
      <Icon
        component={() => (
          <InvoiceIcon
            style={{
              width: '16px',
              height: '16px',
            }}
          />
        )}
      />
    ),

    showOnDashboard : true,
  },
  {
    id: 11,
    key: '11',
    name: 'Invoice purchase',
    path: '/invoice/purchase',
    icon: (
      <Icon
        component={() => (
          <InvoiceIcon
            style={{
              width: '16px',
              height: '16px',
            }}
          />
        )}
      />
    ),

    showOnDashboard : true,
  },
  {
    id: 12,
    key: '12',
    name: 'Invoice Return',
    path: '/invoice/return',
    icon: (
      <Icon
        component={() => (
          <InvoiceIcon
            style={{
              width: '16px',
              height: '16px',
            }}
          />
        )}
      />
    ),

    showOnDashboard : true,
  },
  {
    id: 3,
    key: '3',
    name: 'Payment',
    path: '/payment',
    icon: (
      <Icon
        component={() => (
          <PaymentIcon style={{ width: '16px', height: '16px' }} />
        )}
      />
    ),

    showOnDashboard: true,
  },
  {
    id: 4,
    key: '4',
    name: 'Expenditure',
    path: '/expenditure',
    icon: (
      <Icon
        component={() => (
          <ExpenditureIcon style={{ width: '16px', height: '16px' }} />
        )}
      />
    ),
  },
  // {
  //   id: 5,
  //   key: '5',
  //   name: 'Salary',
  //   path: '/salary',
  //   icon: (
  //     <Icon
  //       component={() => (
  //         <SalaryIcon style={{ width: '16px', height: '16px' }} />
  //       )}
  //     />
  //   ),
  // },


  // {
  //   id: 6,
  //   key: '6',
  //   name: 'Edit Invoice',
  //   path: '/edit-invoice',
  //   icon: (
  //     <Icon
  //       component={() => (
  //         <EditInvoiceIcon style={{ width: '16px', height: '16px' }} />
  //       )}
  //     />
  //   ),
  // },



  // {
  //   id: 7,
  //   key: '7',
  //   name: 'Remainder',
  //   path: '/remainder',
  //   icon: (
  //     <Icon
  //       component={() => (
  //         <RemainderIcon style={{ width: '16px', height: '16px' }} />
  //       )}
  //     />
  //   ),
  //   showOnDashboard: true,
  // },
  
  {
    id: 9,
    key: '9',
    name: 'Delivery',
    path: '/delivery',
    icon: (
      <Icon
        component={() => (
          <DeliveryIcon style={{ width: '16px', height: '16px' }} />
        )}
      />
    ),
    showOnDashboard: true,
  },
  {
    id: 10,
    key: '10',
    name: 'Return',
    path: '/return',
    icon: (
      <Icon
        component={() => (
          <ReturnIcon style={{ width: '16px', height: '16px' }} />
        )}
      />
    ),
    showOnDashboard: true,
  },

  
  {
    id: 8,
    key: '-1',
    name: 'Admin Panel',
    path: AdminPanel,
    icon: (
      <Icon
        component={() => (
          <SettingIcon style={{ width: '16px', height: '16px' }} />
        )}
      />
    ),
    showOnDashboard: true,
  },
 ]

  
 
 export default routes;
// if(roles && roles.includes("Products.view"))
// { export default routes }
// else {export default routes11}