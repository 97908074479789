import { Drawer, Menu } from 'antd';
import { Link, NavLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import logo from '../../assets/images/Maltiverse-new.png';
import 'theme/globalStyle.css';
import './LeftSideBar.scss';
import { routes } from './routing';
import { useSelector } from 'react-redux';

export const LeftSider = ({
  showSideBar,
  setShowSidebar,
  placement,
  width
}) => {
  
  const location = useLocation();
  
  let path = { ...location }.pathname;
  
  const roles = useSelector((state) => state.commonReducer.response)?.data?.roles
  let modifiedRoutes = routes;

  // validation function
  (() => {

    if (roles && !roles.includes("Invoices.edit")) {
      modifiedRoutes = modifiedRoutes.filter(item => item.id !== 6);
    }
    
    if (roles && !roles.includes("Invoices.add")) {
      modifiedRoutes = modifiedRoutes.filter(item => item.id !== 2)
    }
    
    if (roles && !roles.includes("Expenditure.add")) {
      modifiedRoutes = modifiedRoutes.filter(item => item.id !== 4);
    }
    
    if (roles && !roles.includes("Payments.add")) {
      modifiedRoutes = modifiedRoutes.filter(item => item.id !== 3);
    }
  })()


  return (

    <div className="hide-from-print ">
      <Drawer
        className="drawer-wrapper"
        title="Multiverse POS"
        placement={placement === '1' ? 'right' : 'left'}
        // onClick={() => setShowSidebar(false)}
        onClose={() => setShowSidebar(false)}
        visible={width < 520 ? false : showSideBar}
      >
        <Link to="/">
          <img src={logo} className="logo" alt="logo" />
        </Link>
        <Menu theme="dark" selectedKeys={[`${path}`]} mode="inline">
          {modifiedRoutes.map(({ key, icon, path, name }) => (
            <Menu.Item key={path} icon={icon}>
              {key !== '-1' ? (
                <NavLink to={path}>{name}</NavLink>
              ) : (
                <a target="_blank" href={path} rel="noreferrer">
                  {name}
                </a>
              )}
            </Menu.Item>
          ))}
        </Menu>
      </Drawer>
      {/* </Sider> */}
    </div>
  )
};

export default LeftSider;

LeftSider.propTypes = {
  showSideBar: PropTypes.bool.isRequired,
  setShowSidebar: PropTypes.func.isRequired,
  placement: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  defaultKey: PropTypes.any.isRequired,
  width: PropTypes.number.isRequired,
};
