import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Form } from 'antd';
import { Button, Input } from 'common';
import { saveInvoice } from 'modules/components/invoice/invoiceReducer';
import language from 'translation/en/en.json';
import { getLocalStorage } from 'modules/common/utility/localStorage';
import './Salary.scss';

/**
 * This component is for add salary data to the form
 */
const SalaryForm = () => {
  const dispatch = useDispatch();

  // using antD form
  const [form] = Form.useForm();

  // using localstorage to access setting to update the language accordingly
  const [setting] = useState(getLocalStorage('setting'));

  /**
   * this function is for form handling. THis function will execute on submitting form.
   * We are using antD form and it is default behavior of form to get value of the form.
   * We have added name attribute in <Form.Item name="name"/> , and according to this form
   * value will add in handleFinish
   *
   * @param {object} values
   * dispatching salary object to the saveInvoice action of reducer
   */
  const handleFinish = (values) => {
    const salary = {
      RequestType: 'Salary',
      data: values,
    };
    // dispatch action
    dispatch(saveInvoice(salary));
    // reseting form - method of antD form
    form.resetFields();
  };
  return (
    <div className="salary-wrapper">
      <div className="salary-form-wrapper">
        <Form form={form} onFinish={handleFinish}>
          <Form.Item name="employeeName">
            <Input
              placeholder={`${
                language[setting.user_language].employeeName ||
                language.en.employeeName ||
                'Employee Name'
              }`}
            />
          </Form.Item>
          <Form.Item name="employeeTotSalary">
            <Input
              placeholder={`${
                language[setting.user_language].employeeTotalSalary ||
                language.en.employeeTotalSalary ||
                "Employee's Total Salary"
              }`}
            />
          </Form.Item>
          <Form.Item
            // help="(Total days employee worked in this month.)"
            name="employeeWorkingDays"
            hep={`${
              language[setting.user_language].employeeWorkingDays ||
              language.en.employeeWorkingDays ||
              "Employee's total working days"
            }`}
          >
            <Input
              placeholder={`${
                language[setting.user_language].employeeTotalSalary ||
                language.en.employeeTotalSalary ||
                "Employee's Total Salary"
              }`}
            />
          </Form.Item>
          <Form.Item
            help={`(${
              language[setting.user_language].employeeWorkingDays ||
              language.en.employeeWorkingDays ||
              "Employee's total working days"
            })`}
            name="totalWorkingdays"
          >
            <Input
              placeholder={`${
                language[setting.user_language].totalWorkingdays ||
                language.en.totalWorkingdays ||
                'Total Days Employee Worked'
              }`}
            />
          </Form.Item>
          <Form.Item
            help={`(${
              language[setting.user_language].totalLeaves_help ||
              language.en.totalLeaves_help ||
              'Total Days Employee Worked'
            })`}
            name="totalLeaves"
          >
            <Input
              placeholder={`${
                language[setting.user_language].totalLeaves ||
                language.en.totalLeaves ||
                'Total Leaves of the Employee'
              }`}
            />
          </Form.Item>
          <Form.Item
            help={`${
              language[setting.user_language].netSalary_help ||
              language.en.netSalary_help ||
              'Salary paid to the employee in this month.'
            }`}
            name="netSalary"
          >
            <Input
              placeholder={`${
                language[setting.user_language].netSalary ||
                language.en.netSalary ||
                'Net Salary'
              }`}
            />
          </Form.Item>
          <Form.Item
            help={`${
              language[setting.user_language].amountPaid ||
              language.en.amountPaid ||
              'Total Amount Paid'
            }`}
            name="amountPaid"
          >
            <Input
              placeholder={`${
                language[setting.user_language].netSalary_help ||
                language.en.netSalary_help ||
                'Salary paid to the employee in this month.'
              }`}
            />
          </Form.Item>
          <Form.Item className="salary-save-btn">
            <Button type="primary" htmlType="submit">
              {`${
                language[setting.user_language].save ||
                language.en.save ||
                'Save'
              }`}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};
export default SalaryForm;
