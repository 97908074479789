import { InputNumber } from 'antd';
import PropTypes from 'prop-types';
import './InputNumber.scss';

const antDInputNumber = ({ name, value, onChange, ...rest }) => (
  <div className="antD-input-number-wrapper">
    <InputNumber name={name} value={value} onChange={onChange} {...rest} />
  </div>
);

export default antDInputNumber;

antDInputNumber.defaultProps = {
  name: '',
  value: '',
  onChange: () => {},
};
antDInputNumber.propTypes = {
  name: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
};
