import { setLocalStorage } from 'modules/common/utility/localStorage';
import { invoiceApi } from 'service/invoice';

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

export const getIds = createAsyncThunk(
  'delivery_boy_assign_page_data/inovice',
  async (payload, { rejectWithValue }) => {
    try {
      const data  = await invoiceApi.getInvoiceIds();
      return data;
    } catch (error) {
      return rejectWithValue({ error });
    }
  }
);

const initialState = {
  ids: [],
  isLoading: false,
  isError: false,
};
const invoiceIds = createSlice({
  name: 'invoiceIds',
  initialState,
  extraReducers: {
    [getIds.pending]: (state) => {
      state.isLoading = true;
      state.isError = false;
    },
    [getIds.fulfilled]: (state, { payload }) => {
      setLocalStorage('invoiceIds', payload.data);
      state.isLoading = false;
      state.isError = false;
      state.ids = payload.data;
    },
    [getIds.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isError = true;
      state.ids = payload;
    },
  },
});

export default invoiceIds.reducer;
