import { AutoComplete } from 'antd';
import PropTypes from 'prop-types';
import './AutoComplete.scss';

const antDAutocomplete = ({
  value,
  placeholder,
  onChange,
  options,
  ...rest
}) => (
  <div className="antd-autocomplete-wrapper">
    <AutoComplete
      placeholder={placeholder}
      onChange={onChange}
      options={options}
      value={value}
      {...rest}
    />
  </div>
);

export default antDAutocomplete;

antDAutocomplete.propsType = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any.isRequired,
  options: PropTypes.array.isRequired,
};
