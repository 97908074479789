import { setLocalStorage } from 'modules/common/utility/localStorage';
import { deliveryBoy } from 'service/deliveryBoy';

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

export const getDeliveryBoys = createAsyncThunk(
  'delivery_boy_assign_page_data/delivery_boy',
  async (payload, { rejectWithValue }) => {
    try {
      const data  = await deliveryBoy.getAll();
      return data;
    } catch (error) {
      return rejectWithValue({ error });
    }
  }
);
export const assignDelivery = createAsyncThunk(
  'inovice/delivery_boy_assign',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await deliveryBoy.assignDelivery(payload);
      return { data, payload };
    } catch (error) {
      return rejectWithValue({ error, payload });
    }
  }
);

const initialState = {
  deliveryBoys: [],
  isLoading: false,
  isError: false,
};
const deliveryBoys = createSlice({
  name: 'deliveryBoys',
  initialState,
  extraReducers: {
    [getDeliveryBoys.pending]: (state) => {
      state.isLoading = true;
      state.isError = false;
    },
    [getDeliveryBoys.fulfilled]: (state, { payload }) => {
      setLocalStorage('deliveryBoys', payload.data);
      state.isLoading = false;
      state.isError = false;
      state.deliveryBoys = payload.data;
    },
    [getDeliveryBoys.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isError = true;
      state.ids = payload;
    },
  },
});

export default deliveryBoys.reducer;
