import { DeleteOutlined } from '@ant-design/icons';
import { Col, Form, Row } from 'antd';
import { Input } from 'common';

import {
  handleDeleteInvoices,
  handleChangeInvoices,
} from 'modules/common/utility/utility';
import PropTypes from 'prop-types';

const ItemListForm = ({
  invoice,
  setChangeValue,
  setInvoiceItemChanged,
  language,
  setting,
}) => {
  const handleDelete = () => {
    handleDeleteInvoices(invoice);

    setInvoiceItemChanged(true);
  };
  const handleChange = ({ name, value }, uuid) => {
    handleChangeInvoices(name, value, uuid);
    setChangeValue(true);
    setInvoiceItemChanged(true);
  };
  return (
    <Form
      layout="inline"
      labelCol={{
        flex: '35px',
      }}
      labelWrap
      wrapperCol={{
        flex: '1',
      }}
    >
      <Row gutter={4}>
        <Col md={4} sm={8} xs={8}>
          <Form.Item
            label={
              language[setting.user_language].quantity ||
              language.en.quantity ||
              'Qty'
            }
          >
            {invoice.deleted ? (
              <span style={{ textDecoration: 'line-through' }}>
                {invoice.quantity}
              </span>
            ) : (
              <Input
                name="quantity"
                value={invoice.quantity}
                onChange={(event) => handleChange(event.target, invoice.uuid)}
              />
            )}
          </Form.Item>
        </Col>
        <Col md={4} sm={8} xs={8}>
          <Form.Item
            label={
              language[setting.user_language].pack || language.en.pack || 'Pack'
            }
          >
            {invoice.deleted ? (
              <span style={{ textDecoration: 'line-through' }}>
                {invoice.pack}
              </span>
            ) : (
              <Input
                value={invoice.pack}
                name="pack"
                onChange={(event) => handleChange(event.target, invoice.uuid)}
              />
            )}
          </Form.Item>
        </Col>

        <Col md={4} sm={8} xs={8}>
          <Form.Item
            label={
              language[setting.user_language].price ||
              language.en.price ||
              'Price'
            }
          >
            {invoice.deleted ? (
              <span style={{ textDecoration: 'line-through' }}>
                {invoice.price}
              </span>
            ) : (
              <Input
                value={invoice.price}
                onChange={(event) => handleChange(event.target, invoice.uuid)}
                name="price"
              />
            )}
          </Form.Item>
        </Col>
        <Col md={4} sm={8} xs={8}>
          <Form.Item
            label={
              language[setting.user_language].discount ||
              language.en.discount ||
              'Disc'
            }
          >
            {invoice.deleted ? (
              <span style={{ textDecoration: 'line-through' }}>
                {invoice.discount}
              </span>
            ) : (
              <Input
                value={invoice.discount}
                onChange={(event) => handleChange(event.target, invoice.uuid)}
                name="discount"
              />
            )}
          </Form.Item>
        </Col>
        <Col md={4} sm={8} xs={8}>
          <Form.Item
            label={
              language[setting.user_language].discount ||
              language.en.discount ||
              'Disc'
            }
          >
            {invoice.deleted ? (
              <span style={{ textDecoration: 'line-through' }}>
                {invoice.discount}
              </span>
            ) : (
              <Input
                value={invoice.percentageDiscount}
                name="percentageDiscount"
                onChange={(event) => handleChange(event.target, invoice.uuid)}
              />
            )}
          </Form.Item>
        </Col>
        <Col md={4} sm={8} xs={8}>
          <Form.Item
            label={
              language[setting.user_language].total ||
              language.en.total ||
              'Total'
            }
          >
            {invoice.deleted ? (
              <span style={{ textDecoration: 'line-through' }}>
                {invoice.total}
              </span>
            ) : (
              <span>{invoice.total || 0}</span>
            )}
          </Form.Item>
        </Col>
        <Col
          md={4}
          sm={8}
          xs={8}
          style={{
            display: 'flex',
            justifyContent: 'end',
            alignItems: 'center',
          }}
        >
          <DeleteOutlined
            className="colored-icon"
            style={{ cursor: 'pointer' }}
            onClick={handleDelete}
          />
        </Col>
      </Row>
    </Form>
  );
};
export default ItemListForm;

ItemListForm.propTypes = {
  language: PropTypes.object.isRequired,
  setting: PropTypes.object.isRequired,
  invoice: PropTypes.object.isRequired,
  setInvoiceItemChanged: PropTypes.func.isRequired,
  setChangeValue: PropTypes.func.isRequired,
};
