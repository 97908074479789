import { invoiceApi } from 'service/invoice';
import { setLocalStorage } from 'modules/common/utility/localStorage';
import { v4 as uuid } from 'uuid';
import { getInvoiceLength } from 'modules/common/utility/utility';
import { encryptData } from 'modules/common/utility/utility';

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

export const saveInvoice = createAsyncThunk(
  'invoice/save-invoice',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await invoiceApi.saveInvoice(payload.invoiceRecord);
      if(payload.data){
        const { data } = await invoiceApi.saveInvoice([payload]);
      }
      
      return { data, payload };
    } catch (error) {
      return rejectWithValue({ error, payload });
    }
  }
);

export const returnInvoice = createAsyncThunk(
  'invoice/return-invoice', //inovice/change_invoice_type/{id}
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await invoiceApi.returnInvoice(payload);
      
      return { data, payload };
    } catch (error) {
      return rejectWithValue({ error, payload });
    }
  }
);


export const editInvoice = createAsyncThunk(
  'invoice/edit-invoice',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await invoiceApi.editInvoice(payload);
      return data;
    } catch (error) {
      return rejectWithValue({ error, payload });
    }
  }
);

const initialState = {
  invoiceList: [],
  isLoading: false,
  isError: false,
  response: '',
  invoiceSize: 0,
  invoiceLoader: false,
  invoice: {},
  sync: false,
};
const invoice = createSlice({
  name: 'invoice',
  initialState,
  reducers: {
    invoiceListComplete: (state) => {
      state.invoiceSize = getInvoiceLength();
    },
    saveInvoiceLoader: (state) => {
      state.invoiceLoader = true;
    },
    resetLoader: (state) => {
      state.invoiceLoader = false;
    },
  },

  extraReducers: {
    [saveInvoice.pending]: (state) => {
      state.isLoading = true;
      state.isError = false;
      state.sync = true;

      state.invoiceSize = getInvoiceLength();
    },
    [saveInvoice.fulfilled]: (state, { payload }) => {
      // removeFromLocalStorage('invoice');
      if (payload.payload.requestType === 'inovice') {
        setLocalStorage('invoice_record', encryptData(['notDeleted']));

        state.invoiceSize = getInvoiceLength();
        state.sync = false;
      }
      state.isLoading = false;
      state.isError = false;
      state.response = payload.data;
    },
    [saveInvoice.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isError = true;
      state.error = payload.error;
    },
    [editInvoice.pending]: (state) => {
      state.isLoading = true;
      state.isError = false;
    },
    [editInvoice.fulfilled]: (state, { payload }) => {
      if (payload.status === 'Success') {
        if (payload.data.inovice === null) {
          setLocalStorage('invoice', []);
          state.invoice = {};
        } else {
          state.invoice = { ...payload.data.inovice };
          const invoiceProds = [...payload.data.inovice.inovice_prod];
          invoiceProds.forEach((prod) => {
            prod.total = prod.quantity * prod.price;
            prod.discount = prod.discount || 0;
            prod.uuid = uuid();
          });
          setLocalStorage('invoice', payload.data.inovice.inovice_prod);
        }
      }

      state.isLoading = false;
      state.isError = false;
      state.response = payload;
    },
    [editInvoice.rejected]: (state) => {
      state.isLoading = false;
      state.isError = true;
    },
  },
});
export const { invoiceListComplete, saveInvoiceLoader, resetLoader } =
  invoice.actions;
export default invoice.reducer;
