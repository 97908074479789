import ErrorFallback from 'modules/common/utility/ErrorBoundary';
import { ErrorBoundary } from 'react-error-boundary';

import Routing from 'Routing';
import { useDispatch } from 'react-redux';
import { getSetting } from 'modules/components/common/commonReducer';
import { isAuthFlag } from 'modules/common/utility/utility';
import { getPromo } from 'modules/components/PromoDiscount/PromoDiscount';
import { getProducts } from 'modules/components/product/productReducer';
import { getIds } from 'modules/components/invoiceIds/invoiveIdsReducer';
import { getDeliveryBoys } from 'modules/components/deliveryBoy/deliveryBoyReducer';


const App = () => {
  const isAuth = isAuthFlag();
  const dispatch = useDispatch();                                              //promo Discount values code 

  if (isAuth) {
    dispatch(getSetting())
    dispatch(getPromo());
    dispatch(getProducts());
    dispatch(getIds());
    dispatch(getDeliveryBoys())
  }
  return (<ErrorBoundary FallbackComponent={ErrorFallback}>
    <Routing />
  </ErrorBoundary>
  )
};

export default App;
