import { Typography } from 'antd';
import SalemanInfo from 'components/sale/invoice/SalemanInfo/SalemanInfo';
import { getLocalStorage } from 'modules/common/utility/localStorage';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import './CalculatedPayment.scss';
import { promoDiscountFunction } from 'modules/common/utility/utility';
import { useDispatch, useSelector } from 'react-redux';
import { updateTotal } from 'modules/components/common/commonReducer';
const CalculatedPayment = ({
  language,
  setting,
  prevBalance,
  paymentForm,
  totalItems,
  totalPrice,
  totalQuantity,
  invoiceComplete,
  editInvoiceLoading,
  params,
  editInvoice,
  setCustomerInformation,
  customerInformation,
  setSelectedCustomer,
  grandTotal,
  selectedCustomer

}) => {
  //const [promoDiscount, setpromoDiscount] = useState(0);
  const invoiceList = getLocalStorage('invoice', [])
  //console.log("invoicelist from ", invoiceList)
  const promoDiscountfromState = useSelector(
    (state) => state.PromoDiscount.selectedPromo
  );
  //console.log(promoDiscountfromState)
  let result = 0;
  promoDiscountfromState && invoiceList.map(record => {
    //console.log("thisisisisisisi",record)
    if (!record.deleted) {
      if (promoDiscountfromState.discount_percent === null) {
        let dis = promoDiscountFunction(record, promoDiscountfromState)
        if (dis === null) dis = 0;
        result += record.quantity * parseFloat(dis)
      }
      else {
        //console.log("isdeleted", record, record.deleted)
        let dis = promoDiscountFunction(record, promoDiscountfromState)
        if (dis === null) dis = 0;
        result += (((record.quantity * record.price) / 100) * parseFloat(dis))
      }
    }
  });
 
  const dispatch = useDispatch();
  
  
  const grandTotalVal = useSelector(
    (state) => state.commonReducer.grandTotalVal
  );  
  
  
  // const payAbleAmount = useSelector(
  //   (state) => state.commonReducer.payAbleAmount
  // );  
   
  //payAbleAmount=payAbleAmount+grandTotal;
  grandTotal = parseFloat(grandTotal).toFixed(2)-result;
  let number = selectedCustomer&&(selectedCustomer.current_balance)>0||(selectedCustomer.current_balance)<0?parseFloat(selectedCustomer.current_balance):0
  // console.log("this number",number)
  const payableAmount = parseFloat(
      number+parseFloat(grandTotal)
  ).toFixed(2);
  

  //console.log("this is grand total from calcullated pay,ent",grandTotal,"   ",payableAmount)
  
  useEffect(
    () => {
   // console.log("this is grand total from calcullated pay,ent",grandTotal,"in use   ",payableAmount)
    dispatch(updateTotal({ grandTotal, payableAmount }));
  });
  
 // useSelector((state)=>{console.log(state)})
  
  
  return (
    <div className="calculated-container">
      <SalemanInfo
        invoiceComplete={invoiceComplete}
        language={language}
        setting={setting}
        setSelectedCustomer={setSelectedCustomer}
        customerInformation={customerInformation}
        setCustomerInformation={setCustomerInformation}
        editInvoiceLoading={editInvoiceLoading}
        params={params}
        editInvoice={editInvoice}
      />
      <table>
        <tbody>
          <tr>
            <td>
              <Typography.Title level={5}>
                {language[setting.user_language].items ||
                  language.en.items ||
                  'Items'}
                :
              </Typography.Title>
            </td>
            <td>
              <Typography.Title level={5} className="payment-black-color">
                {totalItems}
              </Typography.Title>
            </td>
            <td rowSpan="7">
              <div className="invoice-total">
                <span className="title">Invoice Total</span>
                <span className="amount">
                  { parseFloat(grandTotal).toFixed(2) /*- (result > 0 ? result : 0)*/}
                </span>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <Typography.Title level={5}>
                {language[setting.user_language].quantity ||
                  language.en.quantity ||
                  'Qty'}
                :
              </Typography.Title>
            </td>
            <td>
              <Typography.Title level={5} className="payment-black-color">
                {totalQuantity}
              </Typography.Title>
            </td>
          </tr>
          <tr className="total-price">
            <td style={{ width: '35%' }}>
              <Typography.Title level={5}>
                {`${language[setting.user_language].total ||
                  language.en.total ||
                  'Total'
                  }:`}
              </Typography.Title>
            </td>
            <td>
              <Typography.Title level={5}>
                {parseFloat(totalPrice).toFixed(2)}
              </Typography.Title>
            </td>
          </tr>
          <tr>
            <td style={{ width: '35%' }} className="left-space">
              <Typography.Title level={5}>
                {`${language[setting.user_language].gst || language.en.gst || 'GST'
                  }(${paymentForm.gstpercentage || 0}%): `}
              </Typography.Title>
            </td>
            <td style={{ width: '20%' }}>
              <Typography.Title level={5} className="payment-black-color">
                {paymentForm.gstAmmount}
              </Typography.Title>
            </td>
          </tr>
          <tr>
            <td className="left-space">
              <Typography.Title level={5}>
                {`${language[setting.user_language].extraCharges ||
                  language.en.extraCharges ||
                  'Extra Charges'
                  }: `}
              </Typography.Title>
            </td>
            <td>
              <Typography.Title level={5} className="payment-black-color">
                {paymentForm.extraCharges || 0}
              </Typography.Title>
            </td>
          </tr>
          <tr>
            <td className="left-space">
              <Typography.Title level={5}>
                {`${language[setting.user_language].discount ||
                  language.en.discount ||
                  'Discount'
                  }: `}
              </Typography.Title>
            </td>
            <td>
              <Typography.Title level={5} className="payment-black-color">
                {paymentForm.discount || 0}
              </Typography.Title>
            </td>
          </tr>
          <tr>
            <td className="left-space">
              <Typography.Title level={5}>
                {`${language[setting.user_language].prev_bal ||
                  language.en.prev_bal ||
                  'Previous Balabnce'
                  }: `}
              </Typography.Title>
            </td>
            <td>
              <Typography.Title level={5} className="payment-black-color">
                {prevBalance}
              </Typography.Title>
            </td>
          </tr>
          <tr>
            <td className="left-space">
              <Typography.Title level={5}>
                {`${language[setting.user_language].promo_code ||
                  language.en.promo_code ||
                  'Promo Discount'
                  }: `}
              </Typography.Title>
            </td>
            <td>
              <Typography.Title level={5} className="payment-black-color">
                {result}
              </Typography.Title>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
};

export default CalculatedPayment;



CalculatedPayment.propTypes = {
  setting: PropTypes.object.isRequired,
  language: PropTypes.object.isRequired,
  paymentForm: PropTypes.object.isRequired,
  prevBalance: PropTypes.number.isRequired,
  totalItems: PropTypes.number.isRequired,
  totalPrice: PropTypes.number.isRequired,
  grandTotal: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  totalQuantity: PropTypes.number.isRequired,
  invoiceComplete: PropTypes.bool.isRequired,
  editInvoiceLoading: PropTypes.bool.isRequired,
  params: PropTypes.object.isRequired,
  editInvoice: PropTypes.object.isRequired,
  setCustomerInformation: PropTypes.func.isRequired,
  customerInformation: PropTypes.object.isRequired,
  setSelectedCustomer: PropTypes.func.isRequired,
};
