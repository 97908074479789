import TextArea from 'antd/lib/input/TextArea';
import PropTypes from 'prop-types';
import './TextArea.scss';

const antDTextArea = ({ name, value, onChange, rows, ...rest }) => (
  <div className="antD-textarea-wrapper">
    <TextArea
      name={name}
      value={value}
      onChange={onChange}
      {...rest}
      rows={rows}
    />
  </div>
);

export default antDTextArea;
antDTextArea.defaultProps = {
  rows: 1,
  name: '',
  value: '',
  onChange: () => {},
};

antDTextArea.propTypes = {
  name: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  rows: PropTypes.number,
};
