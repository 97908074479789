import { Typography } from 'antd';
import PropTypes from 'prop-types';
import './heading.scss';

const H2Heading = ({ align, text }) => {
  const { Title } = Typography;
  const H2Styles = {
    fontSize: '24px',
    color: '#000066',
  };
  return (
    <div>
      <Title level={2} style={H2Styles} align={align}>
        {text}
      </Title>
    </div>
  );
};

export default H2Heading;

H2Heading.defaultProps = {
  align: 'middle',
  text: 'Enter text here in text prop',
};

H2Heading.propTypes = {
  align: PropTypes.string,
  text: PropTypes.string,
};
