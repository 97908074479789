import { createAsyncThunk } from '@reduxjs/toolkit';
import { customerApi } from 'service/customer';

export const getCustomersApi = createAsyncThunk(
  'customer/get-customers',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await customerApi.getCustomerList();
      return data;
    } catch (error) {
      return rejectWithValue({ error, payload });
    }
  }
);

export const getCustomerProduct = createAsyncThunk(
  'customer/get-product',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await customerApi.getCustomerProduct(payload);
      return data;
    } catch (error) {
      return rejectWithValue({ error, payload });
    }
  }
);
