import React, { useEffect, useState } from 'react';
import { Button, Input, TextArea, DatePicker } from 'common';
import moment from 'moment';
import { Form } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { saveInvoice } from 'modules/components/invoice/invoiceReducer';
import { useDispatch, useSelector } from 'react-redux';

// const validateMessages = {
//   required: `${label} is required!`,
// };
const initialValues = {
  dateTime: moment(),
  amount: '',
  description: '',
};
const wrapperCol = {
  xs: { span: 24 },
  sm: { span: 24 },
  md: { span: 8, offset: 8 },
  lg: { span: 8, offset: 8 },
};
const AddExpenditure = () => {
  const dispatch = useDispatch();
  const isError = useSelector((state) => state.invoiceReducer.isError);
  const isLoading = useSelector((state) => state.invoiceReducer.isLoading);
  const [formSubmit, setFormSubmit] = useState(false);
  const [form] = useForm();
  const onFinish = (values) => {
    console.log("here are values s", values)
    const data = { ...values };
    data.dateTime = moment(data.dateTime); // unix
    const expenditure = {
      RequestType: 'expenditure',
      data,
    };
    dispatch(saveInvoice(expenditure));
    setFormSubmit(true);
  };

  useEffect(() => {
    if (!isError && !isLoading && formSubmit) {
      form.resetFields();
      setFormSubmit(true);
    }
  }, [isLoading]);
  return (
    <Form
      form={form}
      name="add-expenditure"
      onFinish={onFinish}
      wrapperCol={wrapperCol}
      initialValues={initialValues}
    >
      <Form.Item
        name="dateTime"
        rules={[{ required: true, message: 'Date Time is Required' }]}
      >
        <DatePicker
          showTime
          style={{ width: '100%' }}
          format="YYYY/MM/DD hh:mm"
        />
      </Form.Item>

      <Form.Item
        name="amount"
        rules={[{ required: true, message: 'Amount is required' }]}
      >
        <Input placeholder='Amount' type='number' />
      </Form.Item>

      <Form.Item
        name="Category"
        rules={[{ required: true, message: 'category is required' }]}
      >
        <Input placeholder='category' />
      </Form.Item>


      <Form.Item name="description"
        rules={[{ required: true, message: 'description is required' }]}
      >
        <TextArea row={2} placeholder='description' />
      </Form.Item>

      <Form.Item style={{ display: 'flex', justifyContent: 'center' }}>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default AddExpenditure;
