/* eslint-disable */
import { Result } from 'antd';
import { Button } from 'common';
import { useNavigate } from 'react-router-dom';
import { auth } from 'service/authentication';
import PropTypes from 'prop-types';

import { removeFromLocalStorage } from './localStorage';

const ErrorFallback = ({ error, resetErrorBoundary }) => {
  const navigate = useNavigate();
  const handleReset = async () => {
    try {
      const isUserLogOut = await auth.logout();
      const removeData =
        removeFromLocalStorage('products') &&
        removeFromLocalStorage('customers') &&
        removeFromLocalStorage('setting') &&
        removeFromLocalStorage('user');
      document.getElementsByTagName('html')[0].setAttribute('dir', 'ltr');
      navigate('/login');
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div role="alert">
      <Result
        status="500"
        title="500"
        subTitle="Sorry, something went wrong."
        extra={
          <Button type="primary" onClick={handleReset}>
            Log Out
          </Button>
        }
      />
    </div>
  );
};
export default ErrorFallback;

ErrorFallback.propTypes = {
  error: PropTypes.any.isRequired,
  resetErrorBoundary: PropTypes.any.isRequired,
};
