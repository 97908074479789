import { Col, Form, Row, Input } from 'antd';
import { memo, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { AutoComplete, Button } from 'common';
import './invoiceItem.scss';
import 'theme/globalStyle.css';
import {
  getLocalStorage,
  setLocalStorage,
  clearLocalStorage,
} from 'modules/common/utility/localStorage';
import { v4 as uuid } from 'uuid';
import { useSelector } from 'react-redux';
import { decryptData } from 'modules/common/utility/utility';
import { useNavigate } from 'react-router-dom';
import { promoDiscountFunction } from 'modules/common/utility/utility';

const initalValues = {
  productcode: '',
};

const InvoiceItem = ({
  method,
  setInvoiceItemChanged,
  selectedCustomer,
  language,
  setting,
}) => {
  const products = useSelector((state) => state.productReducer.products);
  const productLoading = useSelector((state) => state.productReducer.isLoading);
  const productError = useSelector((state) => state.productReducer.isError);
  const [productList, setProductLists] = useState([]);
  const navigate = useNavigate();


  const customerProducts = useSelector(
    (state) => state.customerReducer.customerProduct
  );

  const invoiceList = getLocalStorage('invoice', [])
  const promoDiscountfromState = useSelector(
    (state) => state.PromoDiscount.selectedPromo
  );

  //console.log("promoDiscountfromState",promoDiscountfromState)
  const promoDiscountFunctionForInvoice = (record, promoDiscountfromState) => {
         

        
    if (promoDiscountfromState && promoDiscountfromState.product_type == "all_stock" && promoDiscountfromState.flat_discount !== null) {
      return ({
        promocode: promoDiscountfromState.flat_discount || 0,
        name : promoDiscountfromState.name,
        type : promoDiscountfromState.product_type,
        promo_type :promoDiscountfromState.discount_percent 
      })
    }
    else if (promoDiscountfromState && promoDiscountfromState.product_type == "all_stock" && promoDiscountfromState.discount_percent !== null) {
      return ({
        promocode: promoDiscountfromState.flat_discount || 0,
        name : promoDiscountfromState.name,
        type : promoDiscountfromState.product_type,
        promo_type :promoDiscountfromState.discount_percent 
      })
    }
    else {
      if (promoDiscountfromState && promoDiscountfromState.product_type == null && promoDiscountfromState.flat_discount !== null) {
        promoDiscountfromState.product_detail.map((item) => {
          if (item == record.id) {
            return ({
              promocode: promoDiscountfromState.flat_discount || 0,
              name : promoDiscountfromState.name,
              type : promoDiscountfromState.product_type,
              promo_type :promoDiscountfromState.discount_percent 
            })
          }
        })
      }
      if (promoDiscountfromState && promoDiscountfromState.product_type == null && promoDiscountfromState.flat_discount === null) {
        promoDiscountfromState.product_detail.map((item) => {
          return ({
            promocode: promoDiscountfromState.flat_discount || 0,
            name : promoDiscountfromState.name,
            type : promoDiscountfromState.product_type,
            promo_type :promoDiscountfromState.discount_percent 
          })
        })
      }
    }
  }



  const ref = useRef();
  const [form] = Form.useForm();



  const checkAlreadyExit = (alreadyAddedInvoice, values) => {
    let productFound = {};
    //  console.log("these are values idk", values)
    const index = alreadyAddedInvoice.findIndex(
      (invoice) =>
        invoice.productcode === values.productcode ||
        invoice.productname === values.productcode
    );
    if (index >= 0) {
      productFound = { ...alreadyAddedInvoice[index] };
    }
    return [index, productFound];
  };

  const addItemInItemList = (product, price = 1, filterProduct = {}) => {
    const newProductFound = { ...product };
   // console.log("newProductFound", newProductFound);
    newProductFound.quantity = parseFloat(newProductFound.quantity) + 1;
    newProductFound.deleted = 0;
    if (Object.keys(filterProduct).length < 0) {
      if (setting.productdiscount) {
        newProductFound.percentageDiscount = filterProduct.sale_discount_percent
          ? filterProduct.sale_discount_percent
          : (newProductFound.discount * 100) / price;

        newProductFound.discount = filterProduct.sale_discount_percent
          ? (newProductFound.singlePercentAgeDiscount *
            newProductFound.quantity *
            price) /
          100
          : newProductFound.sale_discount || 0;
      }

      newProductFound.price = price;
      newProductFound.promocode = promoDiscountFunctionForInvoice()
      newProductFound.total =
        newProductFound.price -
        (setting.productdiscount ? newProductFound.discount : 0);

    } else {
      newProductFound.discount = newProductFound.discount || 0;
      newProductFound.total =
        newProductFound.quantity * newProductFound.price -
        (setting.productdiscount ? newProductFound.discount : 0);
    }
    //console.log("newProductFound1", newProductFound);
    return newProductFound;
  };
  //useSelector((state)=>console.log(state))
  /**
   * We have stored products in localstorage because we need products when internet or server is down
   * Checking if redux thrown an error or  server fetching data in that case use localstorage
   * When data is fetched successfully us data from api
   */


  useEffect(() => {
    if (productLoading || productError) {
      setProductLists(getLocalStorage('products', []));
    } else {
      setProductLists(products);
    }
  }, [productLoading]);

  /* Need to clean this function */

  const handleFinish = (values) => {
    /* Filtering products according to the customer. Here we are
    checking if this product exist in our products collection
    */
    const filterProduct = productList.filter(
      (product) =>
        product.productname === values.productcode ||
        product.productcode === values.productcode
    );
    //console.log("filterProduct", filterProduct)
    /* Getting products that are alreadyAdded in the invoice list */
    const alreadyAddedInvoice = getLocalStorage('invoice', []);
   // console.log("alreadyAddedInvoice", alreadyAddedInvoice)

    /* Getting Alredy added invoices, that are not synced to the server */
    const invoiceRecord = getLocalStorage('invoice_record', '', 'string');
    const response = invoiceRecord.replaceAll('"', '');
    /* Decrypting the invoice Record. This is for security reasons */
    const originalInvoiceRecord = decryptData(response, 'secret key 123', '[]');
   // console.log("originalInvoiceRecord",originalInvoiceRecord)
    // console.log("this is originalinvoice record from invoiceitem",invoiceRecord)
    if (!originalInvoiceRecord.length) {
      clearLocalStorage();
      navigate('/login');
    } else {
      const initialInvoice = {
        uuid: uuid(),
        price: 1,
        total: 1,
        quantity: 1,
        id: null,
        productname: filterProduct.length
          ? filterProduct[0].productname
          : values.productcode,
        productcode: filterProduct.length
          ? filterProduct[0].productcode
          : values.productcode,
        pack: 0,
        discount: 0,
        percentageDiscount: 0,
        deleted: 0,
        singlePercentAgeDiscount:
          filterProduct.length && setting.productdiscount
            ? filterProduct[0].sale_discount_percent || 0
            : 0,
        singleDiscount:
          filterProduct.length && setting.productdiscount
            ? filterProduct[0].sale_discount || 0
            : 0,
        promocode: {}
      };
      if (filterProduct.length) {
        const invoiceData = { ...filterProduct[0] };
        //console.log("hhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhh", invoiceData)
        const price =
          (method === 'Sale' || method === 'Sale Return'
            ? invoiceData.sale_price
            : invoiceData.purchase_price) || 1;
        if (alreadyAddedInvoice.length) {
          const [index, productFound] = checkAlreadyExit(
            alreadyAddedInvoice,
            values
          );

          if (productFound && index >= 0) {
            //console.log("here i am")
            const newProductFound = addItemInItemList(
              productFound,
              price,
              invoiceData
            );

            alreadyAddedInvoice.splice(index, 1);
            alreadyAddedInvoice.unshift(newProductFound);
            setLocalStorage('invoice', alreadyAddedInvoice);
          } else {
            const newInvoiceItem = { ...initialInvoice };
            if (setting.productdiscount) {
            //  console.log("i dont know newInvoiceitem", newInvoiceItem)
              newInvoiceItem.percentageDiscount =
                invoiceData.sale_discount_percent
                  ? invoiceData.sale_discount_percent
                  : (newInvoiceItem.discount * 100) / price;
              newInvoiceItem.discount = invoiceData.sale_discount_percent
                ? (newInvoiceItem.singlePercentAgeDiscount * price) / 100
                : invoiceData.sale_discount || 0;
            }

            newInvoiceItem.price = price;

            newInvoiceItem.total =
              newInvoiceItem.price -
              (setting.productdiscount ? newInvoiceItem.discount : 0);
            newInvoiceItem.id = invoiceData.id || null;
            newInvoiceItem.promocode =promoDiscountFunctionForInvoice()
            alreadyAddedInvoice.unshift(newInvoiceItem);
            setLocalStorage('invoice', alreadyAddedInvoice);
          }
        } else {
          const newInvoiceItem = { ...initialInvoice };

          if (setting.productdiscount) {
            newInvoiceItem.percentageDiscount =
              invoiceData.sale_discount_percent
                ? invoiceData.sale_discount_percent
                : (newInvoiceItem.discount * 100) / price;
            newInvoiceItem.discount = invoiceData.sale_discount_percent
              ? (newInvoiceItem.singlePercentAgeDiscount * price) / 100
              : invoiceData.sale_discount || 0;
          }
          newInvoiceItem.price = price;

          newInvoiceItem.total =
            newInvoiceItem.price -
            (setting.productdiscount ? newInvoiceItem.discount : 0);
          newInvoiceItem.id = invoiceData.id || null;
          newInvoiceItem.promocode = promoDiscountFunctionForInvoice()
          setLocalStorage('invoice', [newInvoiceItem]);
        }
      } else if (alreadyAddedInvoice.length) {

        const [index, productFound] = checkAlreadyExit(
          alreadyAddedInvoice,
          values
        );

        if (productFound && index >= 0) {

          const newProductFound = addItemInItemList(productFound);

          alreadyAddedInvoice.splice(index, 1, newProductFound);
          
          setLocalStorage('invoice', alreadyAddedInvoice);
        } else {
        //  console.log("productfoundproductfounf", productFound)
          const newInvoiceItem = { ...initialInvoice };
          alreadyAddedInvoice.unshift(newInvoiceItem);
          setLocalStorage('invoice', alreadyAddedInvoice);
        }
      } else {
        const newInvoiceItem = { ...initialInvoice };

        setLocalStorage('invoice', [newInvoiceItem]);
      }

      // setFormSubmit(true);
      setInvoiceItemChanged(true);
      form.resetFields();
    }
    // setProductSelected(false);
  };

  const handleKeyUp = (event) => {
    if (event.keyCode === 13 && form.getFieldValue('productcode')) {
      ref.current.submit();
    }
  };
  const getRecommendedPrice = (singleProduct) => {
    if (Object.keys(selectedCustomer).length) {
      return customerProducts.filter(
        (product) => product.product_id === singleProduct.id
      ).length
        ? /* eslint-disable */
        `(Previous Price:${customerProducts.filter(
          (product) => product.product_id === singleProduct.id
        )[0].sale_price
        })`
        : '';
      /* eslint-enable */
    }
    return '';
  };


  const roles = useSelector((state) => state.commonReducer.response)?.data.roles;
  // console.log("these are roles", roles);


  return (
    <div className="invoice-form-wrapper">
      <Row>
        <Col xs={24} sm={24} md={24}>
          <Form
            className="invoice-form"
            // labelCol={{ xs: { span: 12, offset: 12 } }}
            name="invoice-form"
            data-testid="invoiceItem-form"
            form={form}
            initialValues={initalValues}
            layout="vertical"
            onFinish={roles && roles.includes("Products.add") && handleFinish}
            ref={ref}
            onKeyUp={handleKeyUp}
          >
            <Input.Group compact className="search-wrapper">
              <Form.Item
                className={`${setting.is_rtl === '1' && 'invoice-form-style-is_rtl'
                  }`}
                rules={[
                  {
                    required: true,
                    message: 'Product Code / Product Name is Required',
                  },
                ]}
                style={{ width: '100%' }}
                requiredMark
                name="productcode"
              >

                <AutoComplete
                  placeholder={`${language[setting.user_language].enterItem ||
                    language.en.enterItem ||
                    'Enter Item'
                    }`}
                  autoFocus

                  onClick={() => roles && roles.includes("Products.view") ? null : alert('Contect Admin To Enable It')}   //adding privacy

                  id="invoice-barcode"

                  options={roles && roles.includes("Products.view") && productList.map((singleProduct, index) => ({       //adding privacy

                    key: `${singleProduct.productcode}-${index}`,
                    value: singleProduct.productname,
                    optionValue: singleProduct.productcode
                      ? `${singleProduct.productname}-${singleProduct.productcode}`
                      : `${singleProduct.productname}`,
                    label: `${singleProduct.productname} (Stock:${singleProduct.stock ? singleProduct.stock : 0
                      }) (Price:${singleProduct.sale_price
                      })  ${getRecommendedPrice(singleProduct)}`,
                    barcode: singleProduct.productcode,
                    productname: singleProduct.productname,

                    id: singleProduct.id,
                  }))}
                  backfill
                  filterOption={(input, option) => {
                    if (option.value !== null && option.optionValue !== null) {
                      return (
                        option.optionValue
                          .toUpperCase()
                          .indexOf(input.toUpperCase()) >= 0
                      );
                    }
                    return customerProducts;
                  }}
                />
              </Form.Item>
              <Form.Item
                className={`${setting.is_rtl === '1' && 'btn-is_rtl'}`}
              >
                {
                  roles && roles.includes("Products.add") ? // adding privacy depending upon permission(Products.add)
                    <Button
                      className='add_item'
                      htmlType="finish"
                      style={{ height: '55px', letterSpace: '2px' }}
                    >
                      {`${language[setting.user_language].addItem ||
                        language.en.addItem ||
                        'Add Item'
                        }`}
                    </Button> : <Button
                      disabled
                      type="primary"
                      htmlType="finish"
                      style={{ height: '55px', letterSpace: '2px' }}
                    >
                      {`${language[setting.user_language].addItem ||
                        language.en.addItem ||
                        'Add Item'
                        }`}
                    </Button>
                }
              </Form.Item>
            </Input.Group>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

InvoiceItem.defaultProps = {
  selectedCustomer: {},
  method: 'sale',
};
InvoiceItem.propTypes = {
  method: PropTypes.string,
  setInvoiceItemChanged: PropTypes.func.isRequired,
  // setTotalQuantityChange: PropTypes.func.isRequired,
  selectedCustomer: PropTypes.object,
  language: PropTypes.object.isRequired,
  setting: PropTypes.object.isRequired,
};

export default memo(InvoiceItem);
