/* eslint-disable */
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Row, Form, Col, Divider, Typography } from 'antd';
import { Input, Alert, Button } from 'common';
import { isAuthFlag } from 'modules/common/utility/utility';
import './Login.scss';
import { useDispatch, useSelector } from 'react-redux';
import { authUser } from 'modules/components/authentication/authReducer';
import { getSetting } from 'modules/components/common/commonReducer';
import { isSettingSet } from 'modules/common/utility/utility';
import { Loading } from 'common';
import { GoogleLogin } from 'react-google-login';
import bannreImage from 'assets/images/bg-4.jpg';

const alertInitialState = {
  type: '',
  message: '',
};

const Login = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [alertMessage, setAlertMessage] = useState(alertInitialState);

  const isError = useSelector((state) => state.authReducer.isError);
  const isLoading = useSelector((state) => state.authReducer.isLoading);
  const loginError = useSelector((state) => state.authReducer.loginError);
  const settingError = useSelector((state) => state.commonReducer.isError);
  const settingLoading = useSelector((state) => state.commonReducer.isLoading);

  useEffect(() => {
    if (isError) {
      setAlertMessage({ type: 'error', message: loginError.message });
    }

    return () => setAlertMessage({ type: '', message: '' });
  }, [isError]);

  useEffect(() => {
    if (isAuthFlag()) {
      dispatch(getSetting());
    }
  }, [isAuthFlag(), isLoading]);

  useEffect(() => {
    if (!settingError && !settingLoading && isSettingSet()) {
      navigate('/invoice');
    }
  }, [settingError, settingLoading, isSettingSet()]);

  const handleSubmit = async (values) => {
    dispatch(authUser({ payload: values }));

    // await userSetting();
  };

  const responseGoogle = (response) => {};

  return settingLoading ? (
    <Loading />
  ) : (
    <div className="login-wrapper">
      <Row style={{ height: '100vh' }}>
        <Col
          xl={9}
          lg={10}
          xs={0}
          sm={0}
          md={0}
          // style={{ height: '100vh' }}
          className="empty-space"
        >
          <div className="image-left-banner" />
        </Col>
        <Col xs={24} sm={24} md={24} lg={14} xl={15} className="login-form">
          <Form onFinish={handleSubmit} form={form}>
            <Typography.Title level={3}>LOGIN</Typography.Title>

            {alertMessage.type && (
              <Form.Item>
                <Alert
                  type={alertMessage.type}
                  message={alertMessage.message}
                />
              </Form.Item>
            )}
            <Form.Item
              name="email"
              rules={[{ required: true, message: 'Email is Required' }, {}]}
            >
              <Input
                // required
                type="email"
                className="login-input-field"
                // placeholder="User Email"
                // name="email"
                // value={userInfo.email}
                // onChange={({ target }) =>
                //   setUserInfo({ ...userInfo, email: target.value })
                // }
              />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[
                { required: true, message: 'Password is Required' },
                {
                  min: 8,
                  message: 'Password should contain atleast 8 characters',
                },
              ]}
            >
              <Input
                // required
                // type="password"
                type="password"
                className="login-input-field"
                // placeholder="Password"
                // name="password"
                // value={userInfo.password}
                // onChange={({ target }) =>
                //   setUserInfo({ ...userInfo, password: target.value })
                // }
              />
            </Form.Item>

            <Form.Item className="forget-button-wrappers">
              <Link to="/login" replace className="nav-link">
                Forgot Password
              </Link>

              <Link to="/signup" replace className="nav-link">
                Sign Up!
              </Link>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="Submit" loading={isLoading}>
                SIGN IN
              </Button>
            </Form.Item>
            {/* <Form.Item>
              <Divider />
              <GoogleLogin
                clientId="194874871248-o013ct9plm8o24iguvkeq8orqid5hr62.apps.googleusercontent.com"
                buttonText="Login"
                onSuccess={responseGoogle}
                onFailure={responseGoogle}
                style={{ display: 'flex' }}
              />
            </Form.Item> */}
          </Form>
        </Col>
        {/* <Col lg={6} xs={0} sm={0} md={0} /> */}
      </Row>
    </div>
  );
};

export default Login;
