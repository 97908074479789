import DatePicker from 'antd/lib/date-picker';
import PropTypes from 'prop-types';
import './DatePicker.scss';

const antDDatePicker = ({ onChange, ...rest }) => (
  <div className="date-picker-wrapper">
    <DatePicker onChange={onChange} {...rest} />
  </div>
);

export default antDDatePicker;

antDDatePicker.defaultProp = {
  onChange: () => {},
};
antDDatePicker.propTypes = {
  onChange: PropTypes.func,
};
