import { message } from 'antd';
import _ from 'lodash';
import CryptoJS from 'crypto-js';

import { getLocalStorage, setLocalStorage } from './localStorage';
import { useSelector } from 'react-redux';
// import { message } from 'antd';

// const type = ['success', 'error', 'warning', 'warn'];
/* eslint-disable */





export const sumOfArrayObject = (array, elementName) => {
  const initialValue1 = 0;
  return array.reduce(
    (prev, current) => (current.deleted ? prev : prev + current[elementName]),
    initialValue1
  );
};

export const isAuth = () => {
  const result = getLocalStorage('user', '', 'string');
  const response = result.replaceAll('"', '');
  return response;
};
export const isAuthFlag = () => !!isAuth();
export const isSettingSet = () => {
  if (isAuthFlag()) {
    const setting = getLocalStorage('setting');
    if (Object.entries(setting).length > 0) {
      return true;
    }
    return false;
  }
  return false;
};

export const getToken = () => {
  if (isAuthFlag()) {
    const bytes = CryptoJS.AES.decrypt(isAuth(), 'secret key 123');
    const originalText = bytes.toString(CryptoJS.enc.Utf8);
    return originalText ? JSON.parse(originalText).token : null;
  }
  return null;
};
export const getEmail = () => {
  if (isAuthFlag()) {
    const bytes = CryptoJS.AES.decrypt(isAuth(), 'secret key 123');
    const originalText = bytes.toString(CryptoJS.enc.Utf8);
    return originalText ? JSON.parse(originalText).email : '';
  }
  return '';
};

export const getFistAlphabet = (data) => {
  const info = _.trim(data);

  return info.length ? _.upperCase(info[0]) : 'A';
};

export const alertSuccess = (msg) => message.success(msg);
export const alertError = (msg) => message.success(msg);
export const alertWarning = (msg) => message.warning(msg);

export const encryptData = (data, key) => {
  const invoiceCipherText = CryptoJS.AES.encrypt(
    JSON.stringify(data),
    'secret key 123'
  ).toString();

  return invoiceCipherText;
};
export const decryptData = (data, key, type) => {
  const bytes = CryptoJS.AES.decrypt(data, 'secret key 123');
  const originalInvoiceRecord = bytes.toString(CryptoJS.enc.Utf8) || type;
  // window.onerror = () => {
  //   clearLocalStorage();
  //   window.location.href = '/login';
  // };
  const invoiceData = JSON.parse(originalInvoiceRecord);

  console.log("qwqwqwwwww",invoiceData)
  return invoiceData;
};

export const getInvoiceLength = (data) => {
  const invoiceRecord = getLocalStorage('invoice_record', '', 'string');
  const response = invoiceRecord.replaceAll('"', '');
  const originalInvoiceRecord = decryptData(response, 'secret key 123', '[]');
  const notDeletedIsExit = originalInvoiceRecord[0] === 'notDeleted';
  if (notDeletedIsExit) {
    return originalInvoiceRecord[1] ? originalInvoiceRecord[1].length : 0;
  }

  return originalInvoiceRecord[0] ? originalInvoiceRecord[0].length : 0;
};

function financial(number, decimals) {
  return Number.parseFloat(number).toFixed(decimals);
}




export const handleChangeInvoices = (name, value, uuid) => {
  value = parseFloat(parseFloat(value).toFixed(2));
  console.log(value,"value")
  const invoiceItems = getLocalStorage('invoice', []);
  const index = invoiceItems.findIndex((item) => item.uuid === uuid);
  const item = { ...invoiceItems[index] };

  if (name === 'percentageDiscount') {
    item[name] = parseFloat(value) || 0;
    item.singlePercentAgeDiscount = parseFloat(value || 0);
  } else if (name === 'discount') {
    item[name] = parseFloat(value || 0);
    item.singleDiscount = parseFloat(value || 0);
  } else {
    item[name] = parseFloat(value || 0);
  }
  if (
    name === 'quantity' ||
    name === 'percentageDiscount' ||
    name === 'discount' ||
    name === 'price'
  ) {
    if (
      name === 'percentageDiscount' ||
      name === 'quantity' ||
      name === 'price'
    ) {
      item.singleDiscount = parseFloat(parseFloat(
        parseFloat(
          parseFloat(item.singlePercentAgeDiscount) * parseFloat(item.price)
        ) / 100
      ).toFixed(2));
      item.discount = parseFloat(parseFloat(
        parseFloat(item.singleDiscount) * parseFloat(item.quantity)
      ).toFixed(2));
    }
    if (name === 'percentageDiscount' || name === 'quantity') {
      item.singlePercentAgeDiscount = parseFloat(parseFloat(
        parseFloat(item.singleDiscount * 100) / parseFloat(item.price)
      ).toFixed(2));

      item.percentageDiscount = parseFloat(parseFloat(
        item.singlePercentAgeDiscount
      ).toFixed(2));
    }
  }

  item.total = parseFloat(parseFloat(
    parseFloat(
      parseFloat(item.quantity) * parseFloat(item.price) -
      +parseFloat(item.discount)
    ).toFixed(2)
  ));
  // item.total = parseFloat(item.total);

  // if (setting.productdiscount) {
  // item.total -= parseFloat(item.discount);
  // }
  invoiceItems[index] = item;
  setLocalStorage('invoice', invoiceItems);
};

export const handleDeleteInvoices = (record) => {
  const data = getLocalStorage('invoice');
  if (data) {
    const index = data.findIndex((element) => element.uuid === record.uuid);
    if (index > -1) {
      if (data[index].deleted) {
        data[index].deleted = 0;
      } else {
        data[index].deleted = 1;
      }
      setLocalStorage('invoice', data);
    }
  }
};


export const promoDiscountFunction = (record,promoDiscountfromState) => {
  if (promoDiscountfromState && promoDiscountfromState.product_type == "all_stock" && promoDiscountfromState.flat_discount !== null) {
    record.promocode = promoDiscountfromState.flat_discount;
    return promoDiscountfromState.flat_discount
  }
  else if (promoDiscountfromState && promoDiscountfromState.product_type == "all_stock" && promoDiscountfromState.discount_percent !== null) {
    record.promocode = promoDiscountfromState.discount_percent;
    return  promoDiscountfromState.discount_percent
  }
  else {
    let result = 0;
    if(promoDiscountfromState&&promoDiscountfromState.product_type == null && promoDiscountfromState.flat_discount !== null) {
        promoDiscountfromState.product_detail.map((item) => {
        if (item == record.id ) {
          result = promoDiscountfromState.flat_discount
          record.promocode = result;
        }
      else result = 0
      })
      return result
    }
    if(promoDiscountfromState&&promoDiscountfromState.product_type == null && promoDiscountfromState.flat_discount === null) {
        promoDiscountfromState.product_detail.map((item) => {
        if (item == record.id ) {
          result = promoDiscountfromState.discount_percent
          record.promocode = result;
        }
       else result = 0
      })    
    return result
    }
  }
}





