import { Divider, Typography } from 'antd';
import DeliveryForm from './DeliveryForm/DeliveryForm';

const Delivery = () => (
  <div className="expenditure-generator-wrapper">
    <div
      style={{
        marginTop: '40px',
        padding: '20px 50px 25px 50px',
        backgroundColor: 'white',
      }}
    >
      <Typography.Title level={4}>Add Delivery</Typography.Title>
      <Divider />
      <DeliveryForm />
    </div>
  </div>
);
export default Delivery;
