import { Divider, Typography } from 'antd';
import ReturnForm from './ReturnForm/ReturnForm';

const Return = () => (
  <div className="expenditure-generator-wrapper">
    <div
      style={{
        marginTop: '40px',
        padding: '20px 50px 25px 50px',
        backgroundColor: 'white',
      }}
    >
      <Typography.Title level={4}>Add Return</Typography.Title>
      <Divider />
      <ReturnForm />
    </div>
  </div>
);
export default Return;
