import { Layout, Row, Col } from 'antd';
import PropTypes from 'prop-types';
import { getSetting } from 'modules/components/common/commonReducer';
import { getProducts } from 'modules/components/product/productReducer';
import { getCustomersApi } from 'modules/components/customer/customerQuery';
// import { getCustomers } from 'service/customer';
import 'theme/globalStyle.css';
import { MenuUnfoldOutlined } from '@ant-design/icons';
import { getLocalStorage } from 'modules/common/utility/localStorage';
import {
  saveInvoice,
  saveInvoiceLoader,
  resetLoader,
} from 'modules/components/invoice/invoiceReducer';
import { decryptData } from 'modules/common/utility/utility';
import { ToolTip } from 'common';
import ProfileMenu from './ProfileMenu/ProfileMenu';
import SyncInvoice from './Sync/Sync';

const { Header } = Layout;

const handleMultipleDetete = (invoiceRecord, index, dispatch) => {
  if (invoiceRecord[index]) {
    dispatch(
      saveInvoice({
        invoiceRecord: invoiceRecord[index],
        requestType: 'inovice',
      })
    );
  }
};

export const handleRefreshApi = async (dispatch) => {
  console.log('hello')
  const invoiceRecord = getLocalStorage('invoice_record', '', 'string');
  const response = invoiceRecord.replaceAll('"', '');
  const originalInvoiceRecord = decryptData(response, 'secret key 123', '[]');
  dispatch(saveInvoiceLoader());
  const flagExit = originalInvoiceRecord[0] === 'notDeleted';
  if (flagExit) {
    handleMultipleDetete(originalInvoiceRecord, 1, dispatch);
  }

  dispatch(resetLoader());
  dispatch(getSetting());
  dispatch(getCustomersApi());
  dispatch(getProducts());
  dispatch(resetLoader());
};

export const HeaderMain = ({ setShowSidebar, width, setting }) => (
  <div className="hide-from-print">
    <Header className="site-layout-background" style={{ padding: 0 }}>
      <Row justify="end">
        <Col flex="1">
          {width > 520 && (
            <MenuUnfoldOutlined
              className="head_icon"
              onClick={() => setShowSidebar(true)}
            />
          )}
        </Col>
        <div className="Sync">
          <ToolTip placement="topRight" title="Update Server">
            <SyncInvoice handleRefreshApi={handleRefreshApi} />
          </ToolTip>
        </div>

        <ProfileMenu setting={setting} />
      </Row>
    </Header>
  </div>
);

export default HeaderMain;

HeaderMain.propTypes = {
  setting: PropTypes.object.isRequired,
  setShowSidebar: PropTypes.func.isRequired,
  width: PropTypes.number.isRequired,
};
