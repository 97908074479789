import { Select } from 'antd';
import PropTypes from 'prop-types';
import './Select.scss';

const { Option } = Select;
const AntDSelect = ({
  options,
  onChange,
  name,
  placeholder,
  fieldValue,
  ...rest
}) => (
  <div className="antd-select-wrapper">
    <Select
      onChange={onChange}
      placeholder={placeholder}
      value={fieldValue}
      name={name}
      {...rest}
    >
      {options?.map(({ value, label, key }) => (
        <Option key={key} value={value}>
          {label}
        </Option>
      ))}
    </Select>
  </div>
);

export default AntDSelect;

AntDSelect.defaultProps = {
  name: '',
  fieldValue: undefined,
  onChange: () => {},
  placeholder: '',
};

AntDSelect.propTypes = {
  name: PropTypes.string,
  fieldValue: PropTypes.any,
  onChange: PropTypes.func,
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
};
