import { Divider, Typography } from 'antd';
import PropTypes from 'prop-types';

const ComponentLayout = ({ title, component: Component }) => (
  <div>
    <div
      style={{
        marginTop: '40px',
        padding: '20px 50px 25px 50px',
        backgroundColor: 'white',
      }}
    >
      <Typography.Title level={4}>{title}</Typography.Title>
      <Divider />
      <Component />
    </div>
  </div>
);
export default ComponentLayout;

ComponentLayout.propTypes = {
  title: PropTypes.string.isRequired,
  component: PropTypes.any.isRequired,
};
