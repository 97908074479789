import { Typography } from "antd";
import { TextArea, InputNumber, Select, Button } from "common";
import { v4 as uuid } from "uuid";
import PropTypes from "prop-types";
import "./MultiplePayment.scss";

const MultiplePayment = ({
  paymentWays,
  setPaymentWats,
  label,
  advanceOption,
  commentText,
  language,
  setting
}) => {
  const handleClick = () => {
    setPaymentWats([
      ...paymentWays,
      { paymentType: "cash", amount: 0, comment: "", id: uuid() },
    ]);
  };

  const handleChange = ({ name, value }, index) => {
    const copyPayment = [...paymentWays];
    copyPayment[index][name] = value;
    setPaymentWats(copyPayment);
    // {name, value} = event;
    // )
  };
  const handleSelectChange = (value, name, index) => {
    const copyPayment = [...paymentWays];
    copyPayment[index][name] = value;
    setPaymentWats(copyPayment);
  };

  const handleDelete = (data) => {
    const paymentData = paymentWays.filter((payment) => payment.id !== data.id);
    setPaymentWats(paymentData);
  };

  /* eslint-disable */
  return (
    <div className="multiple-payment-wrapper">
      {/* <div className="multiple-payment-text-header">
        <Typography.Text className="header-left-text">{label}</Typography.Text>
        <span onClick={handleClick} className="header-right-text">
          {advanceOption}
        </span>
      </div> */}
      {paymentWays.map((payment, index) => (
        <div key={payment.id}>
          <table className="payment-table-wrapper">
            <tbody>
              <tr>
                {/* <td style={{ width: "25%", paddingRight: "10px" }}>
                  <Select
                    onChange={(value) =>
                      handleSelectChange(value, "paymentType", index)
                    }
                    options={[
                      {
                        label:
                          language[setting.user_language].cash ||
                          language.en.cash ||
                          "Cash",
                        value: "cash",
                        key: "cash",
                      },
                      {
                        label:
                          language[setting.user_language].creditCard ||
                          language.en.creditCard ||
                          "Credit",
                        value: "credit",
                        key: "credit",
                      },
                      {
                        label:
                          language[setting.user_language].cheque ||
                          language.en.cheque ||
                          "Cheque",
                        value: "cheque",
                        key: "cheque",
                      },
                    ]}
                    value={payment.paymentType}
                  />
                </td> */}
                <td
                  style={paymentWays.length > 1 ? { paddingRight: "10px" } : {}}
                >
                  <InputNumber
                    // placeholder={
                    //   language[setting.user_language].amount ||
                    //   language.en.amount ||
                    //   "Amount"
                    // }
                    onChange={(value) =>
                      handleSelectChange(value, "amount", index)
                    }
                    value={payment.amount}
                    controls={false}
                    className='payment_receive_input'
                  />
                </td>
                {paymentWays.length > 1 ? (
                  <td style={{ width: "15%" }}>
                    <Button type="text" onClick={() => handleDelete(payment)}>
                      {language[setting.user_language].delete ||
                        language.en.delete ||
                        "Delete"}
                    </Button>
                  </td>
                ) : null}
              </tr>
              {/* <tr>
                <td colSpan="3">
                  <TextArea
                    name="comment"
                    onChange={(event) => handleChange(event.target, index)}
                    value={payment.comment}
                    placeholder={commentText}
                  />
                </td>
              </tr> */}
            </tbody>
          </table>
          <br />
        </div>
      ))}
    </div>
  );
};
export default MultiplePayment;

MultiplePayment.propTypes = {
  paymentWays: PropTypes.array.isRequired,
  setPaymentWats: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  advanceOption: PropTypes.string.isRequired,
  commentText: PropTypes.string.isRequired,
};
