import PropTypes from 'prop-types';
import useWindowDimensions from 'modules/common/utility/WindowDimensionHook';
import CollapseItemList from '../ItemRecord/CollapseItemList/CollapseItemList';
import ItemList from '../ItemRecord/invoiceItemList/ItemList';
import AdvanceOption from '../CustomerPaymentRecord/AdvanceOption/AdvanceOption';
import { useEffect } from 'react';


const ProductsTable = ({
  setting,
  setInvoiceItemChanged,
  invoiceItemChanged,
  setTotal,
  invoiceComplete,
  setInvoiceComplete,
  language,
  editInvoiceLoading,
  params,
  editInvoiceError,
  editInvoice,
  selectedCustomer,
  SearchInvoiceApiProduct,
  SetSearchInvoiceApiProduct,
  SearchAPINumberCount,
  SetsearchAPINumberCount,
}) => {


  const { width } = useWindowDimensions();
  // console.log("this is producttable")

    return width > 900 ?
    (
 
      <ItemList
      SearchAPINumberCount = {SearchAPINumberCount}
      SetsearchAPINumberCount = {SetsearchAPINumberCount}
      SearchInvoiceApiProduct = {SearchInvoiceApiProduct}
      SetSearchInvoiceApiProduct = {SetSearchInvoiceApiProduct}
      invoiceItemChanged={invoiceItemChanged}
      setInvoiceItemChanged={setInvoiceItemChanged}
      setTotal={setTotal}
      invoiceComplete={invoiceComplete}
      setInvoiceComplete={setInvoiceComplete}
      language={language}
      setting={setting}
      editInvoiceLoading={editInvoiceLoading}
      editInvoiceError={editInvoiceError}
      params={params}
      editInvoice={editInvoice}
      selectedCustomer={selectedCustomer}
      
      />
      ) : (
        <CollapseItemList
        invoiceItemChanged={invoiceItemChanged}
        setInvoiceItemChanged={setInvoiceItemChanged}
        setTotal={setTotal}
        invoiceComplete={invoiceComplete}
        setInvoiceComplete={setInvoiceComplete}
        language={language}
        setting={setting}
        editInvoiceLoading={editInvoiceLoading}
        editInvoiceError={editInvoiceError}
        params={params}
        editInvoice={editInvoice}
    />
    
    ) 
  };
export default ProductsTable;

ProductsTable.propTypes = {
  invoiceComplete: PropTypes.bool.isRequired,
  language: PropTypes.object.isRequired,
  setting: PropTypes.object.isRequired,

  editInvoiceLoading: PropTypes.bool.isRequired,
  params: PropTypes.object.isRequired,
  editInvoice: PropTypes.object.isRequired,

  setInvoiceItemChanged: PropTypes.func.isRequired,
  invoiceItemChanged: PropTypes.bool.isRequired,
  setTotal: PropTypes.func.isRequired,
  setInvoiceComplete: PropTypes.func.isRequired,
  editInvoiceError: PropTypes.bool.isRequired,
  selectedCustomer: PropTypes.object.isRequired,


};
