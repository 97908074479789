import React from 'react';
import { Row, Col } from 'antd';
import InvoicePageButton from 'common/InvoicePageButton/InvoicePageButton';

const PaymentOperations = ({language,setting:{user_language}}) => {
  const ln = language[user_language];
  const operations = [
    { label: ln.clear, className: 'calc_button bg_danger payment_operations' },
    { label: ln.enter, className: 'calc_button bg_success payment_operations btn_lg' },
    { label: ln.exit, className: 'calc_button bg_warning payment_operations' },
  ];

  return (
    <Row gutter={[0, 16]}>
      {operations.map((operation, index) => (
        <Col span={24} key={index}>
          <InvoicePageButton type="primary" className={operation.className}>
            {operation.label}
          </InvoicePageButton>
        </Col>
      ))}
    </Row>
  );
};

export default PaymentOperations;
