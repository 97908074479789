import ComponentLayout from 'common/ComponentLayout/Component';
// import GeneratePaymentList from './Generate/GeneratePaymentList';
// import PaymentList from './Generate/PaymentList';
import { AddPayment } from './PaymentForm';

const Payment = () => (
  <div>
    <ComponentLayout
      title="Add a Payment/Journal Voucher"
      component={AddPayment}
    />
  </div>
);

export default Payment;
