import React, { useEffect, useState } from 'react';
import { Button, TextArea, DatePicker } from 'common';
import moment from 'moment';
import { AutoComplete, Form, Input } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { saveInvoice } from 'modules/components/invoice/invoiceReducer';
import { useDispatch, useSelector } from 'react-redux';
import { returnInvoice } from 'modules/components/invoice/invoiceReducer';

const initialValues = {
  date: moment(),
  remainder: '',
};
const wrapperCol = {
  xs: { span: 24 },
  sm: { span: 24 },
  md: { span: 8, offset: 8 },
  lg: { span: 8, offset: 8 },
};





const ReturnForm = () => {
  const ids = useSelector((state) => state.InvoiceIdsReducer.ids);
  const dispatch = useDispatch();
  const [form] = useForm();
  const [idOptions,setIdOptions] = useState([])
  

  useEffect(()=>{
    if(Array.isArray(ids) &&ids.length){
      setIdOptions(ids.map((e) => ({ value: e })))
    }
  },[ids])
  
  const onFinish = (values) => {
    dispatch(returnInvoice(values));
    form.resetFields();
  };

  const filterIds = (text) => {
    if(Array.isArray(ids) &&ids.length){
      return ids?.filter((ele) => text ? ele.value.includes(text) : true)
    }else{
      return []
    }
  }
  return (
    <Form
      form={form}
      name="add-expenditure"
      onFinish={onFinish}
      wrapperCol={wrapperCol}
      initialValues={initialValues}
    >
      <Form.Item
        name="invoiceNumber"
        rules={[{ required: true, message: 'Invoice Id is Required' }]}
      >
      <AutoComplete
        options={idOptions}
        onSearch={(text) => setIdOptions(filterIds(text))}
        placeholder="Invoice"
      />
      </Form.Item>

      <Form.Item name="remarks">
        <TextArea placeholder="Remarks" row={4} />
      </Form.Item>

      <Form.Item>
        <div
          style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
        >
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};
export default ReturnForm;
