import { Collapse, Space } from 'antd';
import PropTypes from 'prop-types';
import ItemListForm from '../ItemListForm/ItemListForm';
import './SingleCollapseItem.scss';

const { Panel } = Collapse;

const SingleCollapseItem = ({
  invoice,
  index,

  setChangeValue,
  setInvoiceItemChanged,
  language,
  setting,
  deleted,
}) => {
  const addAmount = (quantity) => (
    <div>
      <span>{quantity}</span>
    </div>
  );
  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Collapse
        ghost
        expandIconPosition="right"
        // collapsible="header"
        defaultActiveKey={['1']}
        accordion
        style={{ width: '100%' }}
      >
        <Panel
          header={invoice.productname}
          key={`${index + 1}`}
          extra={addAmount(invoice.quantity)}
        >
          <ItemListForm
            invoice={invoice}
            setChangeValue={setChangeValue}
            setInvoiceItemChanged={setInvoiceItemChanged}
            language={language}
            setting={setting}
            deleted={deleted}
          />
        </Panel>
      </Collapse>
    </Space>
  );
};

export default SingleCollapseItem;

SingleCollapseItem.propTypes = {
  setInvoiceItemChanged: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  setChangeValue: PropTypes.func.isRequired,
  invoice: PropTypes.object.isRequired,
  language: PropTypes.object.isRequired,
  setting: PropTypes.object.isRequired,
  deleted: PropTypes.bool.isRequired,
};
