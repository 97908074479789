import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { invoiceApi } from 'service/invoice'; // Update with the correct path

export const getLatestInvoices = createAsyncThunk(
  'invoices/getLatestInvoices',
  async () => {
    try {
      const response = await invoiceApi.getLatestInvoices();
      // console.log('API Response:', response);  // Add this line to log the API response
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

const productSlice = createSlice({
  name: 'invoices',
  initialState: {
    data: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getLatestInvoices.pending, (state) => {
        state.loading = true;
      })
      .addCase(getLatestInvoices.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
        state.error = null;
      })
      .addCase(getLatestInvoices.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default productSlice.reducer;
