import { Col, Row } from "antd";
import React, { useState } from "react";
import InvoicePageButton from "common/InvoicePageButton/InvoicePageButton";
import Modal from "common/Modal/Modal";
import Discount from "./ModalsData/Discount";
import SearchInvoice from "./ModalsData/SearchInvoice";
import ShowJournal from "./ModalsData/ShowJournal";
import SetQty from "./ModalsData/SetQty";

const Buttons = ({
  language,
  setting: { user_language },
  paymentForm,
  setPaymentForm,
  totalPrice,
  SearchInvoiceApiProduct,
  SetSearchInvoiceApiProduct,
  SearchAPINumberCount,
  SetsearchAPINumberCount,
}) => {
  const ln = language[user_language];
  const [isModalVisible, setModalVisibility] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [modalTitle, setModalTitle] = useState("");
  const openModal = (title, content) => {
    setModalTitle(title);
    setModalContent(content);
    setModalVisibility(true);
  };
  const ButtonClickHandler = (title, content) => () => {
    openModal(title, content);
  };
  const handleModalClose = () => {
    setModalVisibility(false);
  };
  const buttonsData = [
    [
      {
        text: language[user_language].searchInvoice || "Search Invoice",
        handler: ButtonClickHandler(ln.searchInvoice, 
        <SearchInvoice 
            SearchInvoiceApiProduct = {SearchInvoiceApiProduct}
            SetSearchInvoiceApiProduct = {SetSearchInvoiceApiProduct}
            SearchAPINumberCount = {SearchAPINumberCount}
            SetsearchAPINumberCount = {SetsearchAPINumberCount}
  
        />),
      },
      {
        text: language[user_language].discount || "discount",
        handler: ButtonClickHandler(
          ln.discount,
          <Discount
            language={language}
            setting={user_language}
            totalPrice={totalPrice}
            paymentForm={paymentForm}
            setPaymentForm={setPaymentForm}
            SearchInvoiceApiProduct = {SearchInvoiceApiProduct}
            SetSearchInvoiceApiProduct = {SetSearchInvoiceApiProduct}
          />
        ),
      },
      { text: language[user_language].setQty || "set qty",
      handler: ButtonClickHandler(
        ln.setQty,
       <SetQty/>
      ),
       },
      {
        text: language[user_language].showJournal || "show journal",
        handler: ButtonClickHandler(ln.showJournal,<ShowJournal />),
      },
      {
        text:
          language[user_language].suspendTransaction || "suspend transaction",
      },
    ],
    [
      { text: language[user_language].renewTransaction || "renew transaction" },
      { text: language[user_language].voidProduct || "void product" },
      { text: language[user_language].voidTransaction || "void transaction" },
      { text: language[user_language].voidPayment || "void payment" },
      { text: language[user_language].shiftOperation || "shift operation" },
    ],
    // Add more rows and buttons as needed
  ];

  return (
    <Col xs={{ span: 24 }} style={{ marginTop: "30px" }}>
      <Row gutter={[0, 16]}>
        {buttonsData.map((row, rowIndex) => (
          <Col xs={{ span: 24 }} key={rowIndex}>
            <Row gutter={[16, 24]} justify="space-between">
              {row.map((button, buttonIndex) => (
                <Col flex="20%" key={buttonIndex}>
                  <InvoicePageButton
                    type="primary"
                    className="invoice_button"
                    onClick={button.handler}
                  >
                    {button.text}
                  </InvoicePageButton>
                </Col>
              ))}
              {isModalVisible && (
                <Modal onClose={handleModalClose} title={modalTitle}>
                  {modalContent}
                </Modal>
              )}
            </Row>
          </Col>
        ))}
      </Row>
    </Col>
  );
};

export default Buttons;
