/* eslint-disable */
import { useEffect, useState } from 'react';
import { Col, Form, Row } from 'antd';
import moment from 'moment';
import { Input, AutoComplete, DatePicker, TextArea, Button } from 'common';
import './AddPayment.scss';
// import { useSelector } from 'react-redux';
import { getLocalStorage } from 'modules/common/utility/localStorage';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomersApi } from 'modules/components/customer/customerQuery';
import { setLocalStorage } from 'modules/common/utility/localStorage';
import { saveInvoice } from 'modules/components/invoice/invoiceReducer';
import language from 'translation/en/en.json';

const initialValues = {
  to: '',
  from: '',
  description: '',
  dateTime: moment(),
  amount: '',
};
const AddPayment = () => {
  const [setting] = useState(getLocalStorage('setting'));
  const [customerId, setCustomerId] = useState({ from_id: '', to_id: '' });
  const [formSubmit, setFormSubmit] = useState(false);
  const isLoading = useSelector((state) => state.invoiceReducer.isLoading);
  const isError = useSelector((state) => state.invoiceReducer.isError);
  
 

  
  const customersResult = useSelector(
    (state) => state.customerReducer.customers
  );
  
  const customersResult1 = useSelector(
    (state) => state.customerReducer
  );

  //console.log("customersResults",customersResult1)
  
  
  const customerLoading = useSelector(
    (state) => state.customerReducer.isLoading
  );
  //console.log("customerloading",customerLoading);
  
  
  const [customers, setCustomers] = useState(getLocalStorage('customers', []));

  const dispatch = useDispatch();

  const [form] = Form.useForm();
  useEffect(() => {
    if (!customers.length) {
      dispatch(getCustomersApi());
    }
  }, []);

  useEffect(() => {
    if (!customers.length) {
      setCustomers(customersResult);
      setLocalStorage('customers', customersResult);
    }
  }, [customerLoading, customersResult.length]);

  const handleCancel = () => {                                                        //helper function
    form.resetFields();
  };

  useEffect(() => {
    if (!isError && !isLoading && formSubmit) {
      handleCancel();
      setFormSubmit(true);
    }
  }, [isLoading]);

  const handleFinish = (values) => {                                                  //helper function
    if (!(values.to || values.from)) {
      return form.setFields([
        {
          name: 'to',
          errors: ['To or From is required'],
        },
      ]);
    }
    const payment = {};
    payment.RequestType = 'payment';
    const newValues = { ...values };
    newValues.from_id = customerId.from_id;
    newValues.to_id = customerId.to_id;
    newValues.dateTime = moment(newValues.dateTime).unix(); // unix
    payment.data = newValues;
    dispatch(saveInvoice(payment));
    setFormSubmit(true);
  };

  const handleSelect = (option, name) => {                                                 //helper function
    setCustomerId({ ...customerId, [name]: option.id });
  };
  return (
    <div className="add-payment-wrapper">
      <Form
        layout="vertical"
        onFinish={handleFinish}
        initialValues={initialValues}
        form={form}
      >
        <Row gutter={12}>
          <Col xs={24} sm={12} md={12} lg={12}>
            <Form.Item name="to">
              <AutoComplete
                autoFocus
                data-testid="party-name"
                placeholder={`${
                  language[setting.user_language].to || language.en.to || 'To'
                }`}
                id="invoice-partyName"
                onSelect={(_, option) => handleSelect(option, 'to_id')}
                options={customers.map((customer) => ({
                  value: customer.customer_name,
                  label: customer.customer_name,

                  id: customer.id,
                }))}
                filterOption={(input, option) =>
                  option.value.toUpperCase().indexOf(input.toUpperCase()) >= 0
                }
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12}>
            <Form.Item name="from">
              <AutoComplete
                placeholder={`${
                  language[setting.user_language].from ||
                  language.en.from ||
                  'From'
                }`}
                // value={customerInformation.partyName}
                id="invoice-partyName"
                onSelect={(_, option) => handleSelect(option, 'from_id')}
                options={customers.map((customer) => ({
                  value: customer.customer_name,
                  label: customer.customer_name,
                  name: customer.customer_name,
                  id: customer.id,
                }))}
                filterOption={(input, option) =>
                  option.value.toUpperCase().indexOf(input.toUpperCase()) >= 0
                }
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12}>
            <Form.Item
              required
              name="amount"
              rules={[{ required: true, message: 'amount is required' }]}
            >
              <Input
                type="number"
                placeholder={`${
                  language[setting.user_language].amount ||
                  language.en.amount ||
                  'Amount'
                }`}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12}>
            <Form.Item name="description">
              <TextArea
                rows={1}
                placeholder={`${
                  language[setting.user_language].description ||
                  language.en.description ||
                  'Description'
                }`}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12}>
            <Form.Item
              required
              help="Please enter the date of Entry"
              name="dateTime"
              rules={[{ required: true }]}
            >
              <DatePicker format="YYYY/MM/DD hh:mm" showTime required />
            </Form.Item>
          </Col>

          <Col span={24} className="save-btn-col">
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Save
              </Button>
              <Button onClick={handleFinish}>Cancel</Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default AddPayment;
