/* eslint-disable */
import { Col, Row, Typography } from 'antd';
import { routes } from 'common/CommonLayout/routing';
import { NavLink } from 'react-router-dom';
import { Timeline } from 'antd';
import { MobileFilled, SmileOutlined } from '@ant-design/icons';
import ComponentContainer from './ComponentContainer';
import { useSelector } from 'react-redux';

/* eslint-disable */

const colors = ['#ECF8FF', '#FFF8DD', '#FFF5F8', '#E8FFF3'];
const textColors = ['#1597BB', '#EEB76B', '#F4ABC4', '#03C4A1'];
const Container = () => {


  let modifiedRoutes = [];
  const roles = useSelector((state) => state.commonReducer.response)?.data.roles
    

  const fun=()=>{
    const routes1=routes.filter(item=>item.id!==2);
    if(roles){
      roles.includes("Invoices.add") ? modifiedRoutes=routes : modifiedRoutes=routes1
    }

    
  }
  fun()
  
  
  return (
    <Row gutter={[24, 16]}>
      <Col xs={24} sm={24} md={12} lg={8} xl={6}>
        <div
          style={{
            backgroundColor: '#FFFFFF',
            height: 500,
            boxShadow: '0 0 0 2px rgba(179, 179, 179, 0.2)',
            position: 'relative',
          }}
        >
          <div
            style={{
              backgroundColor: '#F1416C',
              width: '100%',
              height: '35%',
            }}
          ></div>
          <div
            style={{
              width: '100%',
              height: '65%',
              display: 'flex',
              flexWrap: 'wrap',
              gap: '6px',
              justifyContent: 'center',
              alignContent: 'center',
              position: 'relative',
              bottom: '70px',
            }}
          >
            {modifiedRoutes.map(
              (route, index) =>
                route.showOnDashboard && (
                  <NavLink to={route.path} key={index}>
                    <div
                      style={{
                        height: '125px',
                        minWidth: '125px',
                        backgroundColor: colors[index % 4],
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                        borderRadius: '25px',
                      }}
                    >
                      <Typography
                        style={{
                          color: textColors[index % 4],
                          fontSize: '16px',
                          fontWeight: 'bold',
                        }}
                      >
                        {route.name}
                      </Typography>
                    </div>
                  </NavLink>
                )
            )}
          </div>
        </div>
      </Col>
      <Col
        xs={24}
        sm={24}
        md={12}
        lg={8}
        xl={6}
        style={{
          backgroundColor: 'white',
          height: 500,
          boxShadow: '0 0 0 2px rgba(179, 179, 179, 0.2)',
        }}
      >
        <Timeline style={{ pading: 20, marginTop: '10px' }}>
          <Timeline.Item color="green">
            Create a services site 2015-09-01
          </Timeline.Item>
          <Timeline.Item color="green">
            Create a services site 2015-09-01
          </Timeline.Item>
          <Timeline.Item color="red">
            <p>Solve initial network problems 1</p>
            <p>Solve initial network problems 2</p>
            <p>Solve initial network problems 3 2015-09-01</p>
          </Timeline.Item>
          <Timeline.Item>
            <p>Technical testing 1</p>
            <p>Technical testing 2</p>
            <p>Technical testing 3 2015-09-01</p>
          </Timeline.Item>
          <Timeline.Item color="gray">
            <p>Technical testing 1</p>
            <p>Technical testing 2</p>
            <p>Technical testing 3 2015-09-01</p>
          </Timeline.Item>
          <Timeline.Item color="gray">
            <p>Technical testing 1</p>
            <p>Technical testing 2</p>
            <p>Technical testing 3 2015-09-01</p>
          </Timeline.Item>
          <Timeline.Item color="#00CCFF" dot={<SmileOutlined />}>
            <p>Custom color testing</p>
          </Timeline.Item>
        </Timeline>
      </Col>
    </Row>
  );
};

export default Container;
