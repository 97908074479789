import {
  removeFromLocalStorage,
  setLocalStorage,
} from 'modules/common/utility/localStorage';
import { encryptData } from 'modules/common/utility/utility';
import { auth } from 'service/authentication';

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

export const authUser = createAsyncThunk(
  'auth/login',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await auth.login(payload);
      const ciphertext = encryptData(data.data);
      setLocalStorage('user', ciphertext);
      const invoiceCipherText = encryptData(['notDeleted']);
      setLocalStorage('invoice_record', invoiceCipherText);
      return data;
    } catch (error) {
      removeFromLocalStorage('setting');
      return rejectWithValue({ error, payload });
    }
  }
);

export const registerUser = createAsyncThunk(
  'auth/register',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await auth.register(payload);
      return data;
    } catch (error) {
      return rejectWithValue({ error, payload });
    }
  }
);

const initialState = {
  loginUser: {},
  isLoading: false,
  isError: false,
  loginError: {},
  isLogin: false,
  user: {},
  error: {},
  isSignupError: false,
};
const Auth = createSlice({
  name: 'auth',
  initialState,

  extraReducers: {
    [authUser.pending]: (state) => {
      state.isLoading = true;
      state.isError = false;
      // state.isLogin = false;
    },
    [authUser.fulfilled]: (state, { payload }) => {
      // setLocalStorage('setting', payload.data.userSettings);
      state.isLoading = false;
      state.isError = false;
      state.loginUser = payload;
      // state.isLogin = true;
    },
    [authUser.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isError = true;
      state.loginError = payload.error.data;
    },
    [registerUser.pending]: (state) => {
      state.isLoading = true;
      state.isSignupError = false;
      // state.isLogin = false;
    },
    [registerUser.fulfilled]: (state, { payload }) => {
      // setLocalStorage('setting', payload.data.userSettings);
      state.isLoading = false;
      state.isSignupError = false;
      state.user = payload;
      // state.isLogin = true;
    },

    [registerUser.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSignupError = true;
      state.error = payload.error.data;
    },
  },
});

export default Auth.reducer;
