import { Input } from 'antd';
import PropTypes from 'prop-types';
import './Input.scss';

const antDInput = ({ name, value, onChange, ...rest }) => (
  <div className="antD-input-wrapper">
    <Input name={name} value={value} onChange={onChange} {...rest} />
  </div>
);

export default antDInput;

antDInput.defaultProps = {
  name: '',
  value: '',
  onChange: () => {},
};
antDInput.propTypes = {
  name: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
};
