import React, { useEffect } from "react";
import { invoiceApi } from "service/invoice";
import { useState } from "react";
import { Table } from "common";
import InvoicePageButton from "common/InvoicePageButton/InvoicePageButton";
import { Col, Row, Spin } from "antd";

// function to fetch the journal so it would be than rendered back to modal
const ShowJournal = () => {
  const [journal, setJournal] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const getJournal = async () => {
    try {
      setLoading(true);
      //   fetching journal
      let res = await invoiceApi.showJournal();

      let invoices = res.data.data.customerInovices.inovice;

      //   operator name would be sent by each invoice in future so this below line is temporary
      let operator = res.data.data.customerInovices.operator_name;
      //   mapping the response to a variable as it contain last 20 invoices
      let journal = invoices.map((invoice) => ({
        invoice_no: invoice.rtno,
        amount: invoice.total,
        date: invoice.dt,
        type:
          invoice.type === 0
            ? "Sale"
            : invoice.type === 1
              ? "Purchase"
              : "Return",
        operator: operator,
        register: "572register",
      }));
      //   setting the state of journal to the mapped response
      setJournal(journal);
    } catch (error) {
      console.log("JournalError" + error);
    } finally {
      setLoading(false);
    }
  };
  //   calling getJournal function once the modal opens 
  useEffect(() => {
    getJournal();
  }, []);

  //   buttons data to be mapped
  const buttonsData =
    [
      { title: "Search" },
      { title: "Clear Search" },
      { title: "receipt" },
      { title: "invoice" },
      { title: "return transaction" },
      { title: "gift receipt" },
    ]

  return (
    <div style={{ textAlign: "center" }}>
      {isLoading ? (
        <Spin />
      ) : (
        <>
          <Table columns={columns} dataSource={journal} />
          <Col xs={{ span: 24 }}>
            <Row gutter={[16, 16]}>
              {buttonsData.map((button, i) => {
                console.log(button);
                return (
                  <Col xs={{ span: 4 }}>
                    <InvoicePageButton key={i} className="invoice_button">
                      {button.title}
                    </InvoicePageButton>
                  </Col>
                );
              })}
            </Row>
          </Col>
        </>
      )}
    </div>
  );
};

export default ShowJournal;

// Data for the table header where the journal being displayed
const columns = [
  {
    title: "Receipt",
    dataIndex: "invoice_no",
    key: "invoice_no",
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
  },
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
  },
  {
    title: "Operator",
    dataIndex: "operator",
    key: "operator",
  },
  {
    title: "Register",
    dataIndex: "register",
    key: "register",
  },
];
