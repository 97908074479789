import React, { useState } from 'react';
import Modal from "../../../../../common/Modal/Modal";

const SetQty = () => {
    const [isModalVisible, setModalVisibility] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [modalTitle, setModalTitle] = useState("");


  const openModal = (title, content) => {
    setModalTitle(title);
    setModalContent(content);
    setModalVisibility(true);
  };

  // This Function Open Modal,Passing Content and Title when this function is call
  const ButtonClickHandler = (title, content) => () => {
    openModal(title, content);
  };


    const handleModalClose = () => {
        setModalVisibility(false);
      };
    

  return (
    <> 
<Modal
title='Set Qty For the Items You Select'
onClose={handleModalClose}
>
Hello world
</Modal>
      
    </>
  )
}

export default SetQty
