// Libraries Import
import { Typography } from 'antd';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// CSS Import
import './PaymentDetail.scss';

/**
 * @function PaymentDetail
 * @param {children} children is a child component that we are passing from parent
 * @param {grandTotal} grandTotal is sum after GST, extracharges and discount
 * @param {language, setting} language and setting is for add some restrictions
 */
const PaymentDetail = ({ children, grandTotal, language, setting }) => {
  const payableamount = useSelector((state)=> { return state.commonReducer.payAbleAmount})
  return (
  <div className="payment-detail-wrapper">
    <div className="payment-header-wrapper">
      <Typography>
        {language[setting.user_language].payableAmount ||
          language.en.payableAmount ||
          'Payable Amount'}
      </Typography>
      <Typography>{ payableamount || 0}</Typography>
    </div>
    <div className="payment-content-wrapper">{children}</div>
  </div>
)
};

export default PaymentDetail;

PaymentDetail.propTypes = {
  children: PropTypes.any.isRequired,
  grandTotal: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  language: PropTypes.object.isRequired,
  setting: PropTypes.object.isRequired,
};
