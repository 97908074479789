import { setLocalStorage } from 'modules/common/utility/localStorage';
import instance from './interceptor';

export const productApi = {
  getProductList: async () => instance.get('products/userProducts'),
};

export const getProductsList = async () => {
  try {
    const { data } = await instance.get('products/userProducts');
    setLocalStorage('products', data.data.products);
    return data.data.products;
  } catch (error) {
    return error;
  }
};

export const getCustomerProducts = async (id) => {
  try {
    const { data } = await instance.get(
      `inovice/purchasedProducts/customer/${id}` 
    );
    return data;
  } catch (error) {
    return error;
  }
};


//console.log("this is data getProductList from store",getProductsList);

//console.log("this is data getCustomerproducts from store",getCustomerProducts.data);
