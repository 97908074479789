import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import { Button } from 'common';
import { useState } from 'react';
import PropTypes from 'prop-types';

import './CustomerDetail.scss';

const CustomerDetails = ({
  customerInformation,
  handleComplete,
  invoiceNumber,
  language,
  setting,
}) => {
  const [showPhoneNumber, setShowPhoneNumber] = useState(false);
  const handleVoidInvoice = (event) => {
    handleComplete(event, 'yes');
  };

  return (
    <table className="cutomer-detial-table">
      <tbody>
        <tr className="hide-from-print">
          <td>
            <Typography.Title level={5}>
              {language[setting.user_language].invoiceNumber}
            </Typography.Title>
          </td>
          <td>
            <Typography.Title level={5} className="payment-black-color">
              {invoiceNumber}
            </Typography.Title>
          </td>
          <td style={{ textAlign: 'center' }}>
            <Button type="danger" onClick={(event) => handleVoidInvoice(event)}>
              Void
            </Button>
          </td>
        </tr>
        <tr className="hide-from-print">
          <td>
            <Typography.Title level={5}>
              {' '}
              {language[setting.user_language].party_Name}
            </Typography.Title>
          </td>
          <td>
            <Typography.Title level={5} className="payment-black-color">
              {customerInformation.partyName || 'Walking Customer'}
            </Typography.Title>
          </td>
          <td style={{ textAlign: 'center' }}>
            {showPhoneNumber ? (
              <MinusOutlined
                className="colored-icon"
                onClick={() => setShowPhoneNumber(false)}
              />
            ) : (
              <PlusOutlined
                className="colored-icon"
                onClick={() => setShowPhoneNumber(true)}
              />
            )}
          </td>
        </tr>
        {showPhoneNumber && (
          <tr className="hide-from-print">
            <td>
              <Typography.Title level={5}>
                {language[setting.user_language].phoneNumber}
              </Typography.Title>
            </td>
            <td>
              <Typography.Title level={5}>
                {customerInformation.phoneNumber || ''}
              </Typography.Title>
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default CustomerDetails;

CustomerDetails.propTypes = {
  customerInformation: PropTypes.object.isRequired,
  handleComplete: PropTypes.func.isRequired,
  invoiceNumber: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  language: PropTypes.object.isRequired,
  setting: PropTypes.object.isRequired,
};
