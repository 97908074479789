import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getLocalStorage } from 'modules/common/utility/localStorage';
import { sumOfArrayObject } from 'modules/common/utility/utility';

import SingleCollapseItem from './SingleItem/SignleCollapseItem';

// import './ItemList.scss';

const CollapseItemList = ({
  setInvoiceItemChanged,
  invoiceItemChanged,
  setTotal,
  invoiceComplete,
  setInvoiceComplete,
  editInvoiceLoading,
  params,
  itemDeleted,
  language,
  setting,
}) => {
  const [invoiceList, setInvoiceList] = useState([]);
  const [changevalue, setChangeValue] = useState(false);

  /**  why this use Effect ?
   * Need to improve this useEffect
   */
  useEffect(() => {
    setInvoiceList(getLocalStorage('invoice', []));
    setChangeValue(false);
    setInvoiceItemChanged(false);
    // setInvoiceComplete(false);
  }, [
    invoiceItemChanged,
    itemDeleted,
    invoiceComplete,
    changevalue,
    editInvoiceLoading,
    params,
  ]);

  /**
   * Getting Sum of the invoice
   */
  useEffect(() => {
    /**
     * This method is for getting sum of the array of object
     * @param {array } invoiceList List of all the invoice Items
     * @param {string} total the key of object , for which you want to get sum
     */
    const sum = sumOfArrayObject(invoiceList, 'total');
    setTotal(sum);
    setInvoiceItemChanged(false);
    setInvoiceComplete(false);
  }, [invoiceList.length, itemDeleted, invoiceComplete, changevalue]);

  return (
    <div className="item-list-wrapper">
      {invoiceList.map((invoice, index) => (
        <SingleCollapseItem
          key={invoice.uuid}
          invoice={invoice}
          index={index}
          setChangeValue={setChangeValue}
          setInvoiceItemChanged={setInvoiceItemChanged}
          language={language}
          setting={setting}
          deleted={invoice.deleted}
        />
      ))}
    </div>
  );
};

export default CollapseItemList;

// CollapseItemList.defaultProps = {
//   isLoading: false,
// };
CollapseItemList.propTypes = {
  setInvoiceItemChanged: PropTypes.func.isRequired,
  invoiceItemChanged: PropTypes.bool.isRequired,
  setTotal: PropTypes.func.isRequired,
  invoiceComplete: PropTypes.bool.isRequired,
  setInvoiceComplete: PropTypes.func.isRequired,

  editInvoiceLoading: PropTypes.bool.isRequired,
  params: PropTypes.object.isRequired,
  itemDeleted: PropTypes.bool.isRequired,
  language: PropTypes.object.isRequired,
  setting: PropTypes.object.isRequired,
};
