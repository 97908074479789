import { Col, Divider, Form, Row, Typography } from 'antd';
import { Button } from 'common';
import { DatePicker } from 'common';
import moment from 'moment';
import { useState } from 'react';
import './ExpenditureGenerator.scss';

const initialValues = {
  startDateTime: moment().startOf('day'),
  endDateTime: moment().endOf('day'),
};
const ExpendutureGenerator = () => {
  const [date, setDate] = useState(initialValues);

  const handleFinish = () => {};
  return (
    <div className="expenditure-generator-wrapper">
      <div
        style={{
          marginTop: '40px',
          padding: '20px 50px 25px 50px',
          backgroundColor: 'white',
        }}
      >
        <Typography.Title level={4}>Expenditure Report</Typography.Title>
        <Divider />
        <Form onFinish={handleFinish}>
          <Row gutter={12}>
            <Col xs={24} sm={12} md={12} lg={8}>
              <Form.Item>
                <DatePicker
                  format="YYYY/MM/DD HH:mm"
                  showTime
                  value={date.startDateTime}
                  name="startDateTime"
                  onChange={(value) =>
                    setDate({ ...date, startDateTime: value })
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={8}>
              <Form.Item>
                <DatePicker
                  format="YYYY/MM/DD HH:mm"
                  showTime
                  value={date.endDateTime}
                  name="endDateTime"
                  onChange={(value) => setDate({ ...date, endDateTime: value })}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={4} className="generate-btn">
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Generate
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default ExpendutureGenerator;
