import { Modal } from 'antd'
import React, {useState} from 'react'
import PropTypes from 'prop-types';

const AntDModal = ({onClose,title,children}) => {
    const [modalOpen, setModalOpen] = useState(true);
    const handleModalClose = () => {
        setModalOpen(false);
        onClose(); // Call the onClose callback
    };

  return (
    <Modal
        title={title}
        centered
        onClose={onClose}
        open={modalOpen}
        onOk={handleModalClose}
        onCancel={handleModalClose}
        width={1000}
      >
        {children}
      </Modal>
  )
}

export default AntDModal;
AntDModal.defaultProps= {
    title: 'Invoices',
    children: 'No Data',
    onClose: ()=>{}
}
AntDModal.propTypes = {
    title: PropTypes.string,
    onClose : PropTypes.func,
    children : PropTypes.node
}