import { useSelector } from 'react-redux';
import instance from './interceptor';
import axios from 'axios';

export const invoiceApi = {
  saveInvoice: async (data) => instance.post('activity/save', data),
  editInvoice: async (payload) =>
    instance.get(
      `inovice/show/${payload.invoiceNumber}/${payload.requestType || ''}`
    ),
  returnInvoice: async (data) =>
  instance.get(
    `inovice/change_invoice_type/${data.invoiceNumber}?remarks=${data.remarks}`
  ),
  getInvoiceIds: async () => instance.get('delivery_boy_assign_page_data/inovice'),
  // by amir
  getLatestInvoices: async (GetInvByID) => instance.get(`/inovice/show/${GetInvByID}`),
// by amir
  getTenInvoiceData: async () => instance.get('/inovice/get_invoices')
  
};


/*

//this is instance from ./insterceptor 

const instance = axios.create({
  baseURL: SERVER_PATH,
  withCredentials: true,
});


//this is the request used to send invoice to server to save it

instance.interceptors.response.use(
  (response) => {
    if (response.config.url !== 'activity/save') {
      message.success(response.data.message);
    }

    return Promise.resolve(response);
  },

  (error) => {
    const errorMessage = error.response ? error.response : error.message;
    message.error(errorMessage);
    if (error.response.status === 401) {
      clearLocalStorage();
    }
    return Promise.reject(error.response);
  }
);
export default instance;
*/

