import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Form, Col, Typography } from 'antd';
import { Button, Input, Alert } from 'common';
import { isAuthFlag } from 'modules/common/utility/utility';
import './SignUp.scss';
import { registerUser } from 'modules/components/authentication/authReducer';
import { useDispatch, useSelector } from 'react-redux';

const SignUp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoading = useSelector((state) => state.authReducer.isLoading);
  const isError = useSelector((state) => state.authReducer.isSignupError);
  const error = useSelector((state) => state.authReducer.error);

  const [form] = Form.useForm();

  // const [userInfo, setUserInfo] = useState(initialState);

  useEffect(() => {
    if (isAuthFlag()) {
      navigate('/');
    }
  }, [isAuthFlag()]);

  const handleSubmit = (values) => {
    if (values.password !== values.password_confirmation) {
      return form.setFields([
        {
          name: 'password_confirmation',
          errors: ['Password and Confirm Password should be same'],
        },
      ]);
    }
    return dispatch(registerUser({ payload: values }));
  };
  return (
    <div className="signup-wrapper">
      <Row style={{ height: '100vh' }}>
        <Col xl={9} lg={10} xs={0} sm={0} md={0} style={{ height: '100%' }}>
          <div className="image-left-banner" />
        </Col>

        <Col xs={24} sm={24} md={24} lg={14} xl={15}>
          <Form onFinish={handleSubmit} name="signup-form" form={form}>
            <Typography.Title level={3}>SIGN UP</Typography.Title>
            {isError && (
              <Form.Item>
                <Alert
                  type={isError ? 'error' : 'success'}
                  message={error.message}
                />
              </Form.Item>
            )}
            <Form.Item
              name="email"
              rules={[
                { required: true, message: 'Email is Required' },
                { type: 'email', message: 'Enter a valid email' },
              ]}
            >
              <Input
                className="signup-input-field"
                placeholder="User Email"
                // name="email"
              />
            </Form.Item>
            <Form.Item
              name="phone"
              rules={[{ required: true, message: 'Phone Number is Required' }]}
            >
              <Input
                type="text"
                className="signup-input-field"
                placeholder="Phone Number"
                // name="phone"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                { required: true, message: 'Password is Required' },
                {
                  min: 8,
                  message: 'Password Should have atleast 8 characters',
                },
              ]}
            >
              <Input
                type="password"
                className="signup-input-field"
                placeholder="Password"
              />
            </Form.Item>
            <Form.Item
              name="password_confirmation"
              rules={[
                { required: true, message: 'Confirm Password is Required' },
                {
                  min: 8,
                  message: 'Password Should have atleast 8 characters',
                },
              ]}
            >
              <Input
                type="password"
                className="signup-input-field"
                placeholder="Confirm Password"
                // name="password_confirmation"
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="Submit" loading={isLoading}>
                SIGN UP
              </Button>
            </Form.Item>
            {/* <Form.Item>
              <Divider />
              <Button type="primary" className="margin-right">
                Facebook
              </Button>
              <Button className="margin-right">Google</Button>
              <Button type="danger" className="margin-right">
                Twitter
              </Button>
            </Form.Item> */}
          </Form>
        </Col>
        <Col lg={6} xs={0} sm={0} md={0} />
      </Row>
    </div>
  );
};

export default SignUp;
