import { Tooltip } from 'antd';

const antDToolTip = ({ placement, title, children, ...rest }) => (
  <Tooltip placement="topRight" title={title} {...rest}>
    {children}
  </Tooltip>
);

export default antDToolTip;
// antDToolTip.defaultProps = {
//   row: 1,
// };

// antDToolTip.propTypes = {
//   name: PropTypes.string.isRequired,
//   value: PropTypes.any.isRequired,
//   onChange: PropTypes.func.isRequired,
//   row: PropTypes.number,
// };
