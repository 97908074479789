import React, { useEffect, useState } from 'react';
import { Button, TextArea, DatePicker } from 'common';
import moment from 'moment';
import { AutoComplete, Form, Input } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { saveInvoice } from 'modules/components/invoice/invoiceReducer';
import { useDispatch, useSelector } from 'react-redux';
import { assignDelivery } from 'modules/components/deliveryBoy/deliveryBoyReducer';

const initialValues = {
  date: moment(),
  remainder: '',
};
const wrapperCol = {
  xs: { span: 24 },
  sm: { span: 24 },
  md: { span: 8, offset: 8 },
  lg: { span: 8, offset: 8 },
};
const DeliveryForm = () => {
  const ids = useSelector((state) => state.InvoiceIdsReducer.ids);
  

  
  const deliveryBoys = useSelector((state) => state.deliveryBoyReducer.deliveryBoys)?.map((e) => ({ ...e, value: e.name }));

  const dispatch = useDispatch();
  const [form] = useForm();

  const [idOptions, setIdOptions] = useState([])
  const [deliveryBoysOptions, setDeliveryBoysOptions] = useState(deliveryBoys)

  
  useEffect(()=>{
    if(Array.isArray(ids) &&ids.length){
      setIdOptions(ids.map((e) => ({ value: e })))
    }
  },[ids])
  
  useEffect(()=>{
    if(deliveryBoys.length){
      setDeliveryBoysOptions(deliveryBoys)
    }
  },[deliveryBoys])
  
  const onFinish = (values) => {
    dispatch(assignDelivery(values));
    form.resetFields();
  };

  const filterIds = (text) => {
    if(Array.isArray(ids) &&ids.length){
      return ids?.filter((ele) => text ? ele.value.includes(text) : true)
    }else{
      return []
    }
  }
  const filterDeliveryBoys = (text) => {
    if(Array.isArray(deliveryBoys) &&deliveryBoys.length){
      return deliveryBoys?.filter((ele) => text ? ele.value.includes(text) : true)
    }else{
      return []
    }

  }

  return (
    <Form
      form={form}
      name="add-expenditure"
      onFinish={onFinish}
      wrapperCol={wrapperCol}
      initialValues={initialValues}
    >
      <Form.Item
        name="rtno"
        rules={[{ required: true, message: 'Invoice is Required' }]}
      >
        <AutoComplete
          options={idOptions}
          onSearch={(text) => setIdOptions(filterIds(text))}
          placeholder="Invoice"
        />
      </Form.Item>
      
      <Form.Item name="delivery_boy_id">
        <AutoComplete
          options={deliveryBoysOptions}
          onSearch={(text) => setDeliveryBoysOptions(filterDeliveryBoys(text))}
          placeholder="Delivery Boy"
        />
      </Form.Item>


      <Form.Item name="remarks">
        <TextArea placeholder="remarks" row={4} />
      </Form.Item>

      <Form.Item>
        <div
          style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
        >
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};
export default DeliveryForm;
