import instance from './interceptor';

export const commonApi = {
  getSettings: async () => instance.get('user/settings'),
};

export const intervalRequest = async () => {
  try {
    const { data } = await instance.get('me');

    return data;
  } catch (error) {
    return error;
  }
};
