import Form from 'antd/lib/form';

import { AutoComplete, Input } from 'common';
import { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './SalemanInfo.scss';
import { useDispatch, useSelector } from 'react-redux';
// import { MinusOutlined, PlusOutlined } from '@ant-design/icons'; 
import { getCustomerProduct } from 'modules/components/customer/customerQuery';
import { getLocalStorage } from 'modules/common/utility/localStorage';

const SalemanInfo = ({
  invoiceComplete,
  language,
  setting,
  setSelectedCustomer,
  customerInformation,
  setCustomerInformation,
  editInvoiceLoading,
  params,
  editInvoice,
}) => {
  /* use State Hook */
  const [showadditionalFields, setadditionalFields] = useState(true);
  const [customerList, setCustomerList] = useState([]);

  /* useSelector Hook */
  const customers = useSelector((state) => state.customerReducer.customers);
  const customerLoading = useSelector(
    (state) => state.customerReducer.isLoading
  );
  const customerError = useSelector((state) => state.customerReducer.isError);

  /**
   * We stored customners in localstorage because we need customners when internet or server down
   * Checking if redux thrown an error or  server fetching data in that case use localstorage
   * When data is fetched successfully us data from api
   */
  useEffect(() => {
    if (customerLoading || customerError) {
      setCustomerList(getLocalStorage('customers', []));
    } else {
      setCustomerList(customers);
    }
  }, [customerLoading]);

  const dispatch = useDispatch();

  const resetForm = () => {
    setCustomerInformation({
      ...customerInformation,
      partyName: '',
      phoneNumber: '',
    });
  };

  /**
   * We have API for getting customer products that one bought previously.
   * We setting customerProduct that are from store
   */
  // useEffect(() => {
  //   setCustomerProduct(customerProduct);
  // }, [isLoading]);

  /**
   * We have API for getting customer products that one bought previously.
   * We setting customerProduct that are from store
   */
  useEffect(() => {
    if (params.id && Object.keys(editInvoice).length > 0) {
      if (editInvoice.customerid) {
        const customersFilter = customerList.filter(
          (customer) => customer.id === editInvoice.customerid
        );
        if (customersFilter.length > 0) {
          setCustomerInformation({
            ...customerInformation,
            partyName: customersFilter[0].customer_name,
            phoneNumber: customersFilter[0].customer_phone,
          });
        }
      }
    } else {
      resetForm();
    }
  }, [editInvoiceLoading, params]);

  /**
   * We want to focus Dropdown user render the component 1st time
   */
  // useEffect(() => {
  //   const getPartyElement = document.getElementById('invoice-partyName');
  //   if (getPartyElement) {
  //     getPartyElement.focus();
  //   }
  // }, []);

  /**
   * When user select a customer from dropdown , its phone should add to phone number field
   * getting customer data and setting to input fields
   */
  useEffect(() => {
    const customersCopy = [...customerList];
    const filterData = customersCopy.filter(
      (customer) => customer.customer_name === customerInformation.partyName
    );

    if (filterData.length) {
      setCustomerInformation({
        ...customerInformation,
        phoneNumber: filterData[0].customer_phone,
      });
      // setLocalStorage('selected_customer', filterData[0]);
      // const data = getCustomerData(filterData[0].id);
      setSelectedCustomer(filterData[0]);
    }
  }, [customerInformation.partyName]);

  /**
   * Reseting the form values
   */
  useEffect(() => {
    resetForm();
  }, [invoiceComplete]);

  /**
   * AntD autocompete handleChange return 2 these two thing
   * @param {string | number} value
   * @param {object} optionValue
   * we are checking that if that option exit then set request for customer products.
   */
  const handleChange = async (value, optionValue) => {
    if (value) {
      setCustomerInformation({ ...customerInformation, partyName: value });
      if (optionValue.id) {
        dispatch(getCustomerProduct(optionValue.id));
      }
    } else {
      setCustomerInformation({
        ...customerInformation,
        partyName: '',
        phoneNumber: '',
      });
      setSelectedCustomer({});
      dispatch(getCustomerProduct(-1));
    }
  };

  return (
    <div className="saleman-wrapper">
      <div className="print-spacing" style={{ display: 'flex' }}>
        <Form className="form-wrapper">
          <table className="table-wrapper">
            <tbody>
              <tr>
                <td>
                  <div style={{ position: 'relative' }}>
                    <AutoComplete
                      data-testid="party-name"
                      placeholder={`${
                        language[setting.user_language].party_Name ||
                        language.en.party_Name ||
                        'Party Name'
                      }`}
                      value={customerInformation.partyName}
                      // id="invoice-partyName"
                      options={customerList.map((customer) => ({
                        value: customer.customer_name,
                        label: customer.customer_name,
                        id: customer.id,
                      }))}
                      onChange={(value, optionObject) =>
                        handleChange(value, optionObject)
                      }
                      filterOption={(input, option) => {
                        if (input !== customerInformation.partyName) {
                          return customerList;
                        }
                        return (
                          option.value
                            .toUpperCase()
                            .indexOf(input.toUpperCase()) >= 0
                        );
                      }}
                    />
                      {/* =============Hidden By Amir=========== */}
                    {/* <span
                      role="img"
                      className={`icon-inside-input ${
                        setting.is_rtl === '1'
                          ? 'icon-inside-is_rtl'
                          : 'icon-inside-not-is_rtl'
                      }`}
                    >
                    
                      {showadditionalFields ? (
                        <MinusOutlined
                          className="colored-icon"
                          onClick={() => setadditionalFields(false)}
                        />
                      ) : (
                        <PlusOutlined
                          className="colored-icon"
                          onClick={() => setadditionalFields(true)}
                        />
                      )}
                     
                    </span> */}
                     {/* =============Hidden By Amir=========== */}
                  </div>
                </td>
              </tr>

              {showadditionalFields ? (
                <tr>
                  <td>
                    <Input
                      name="phoneNumber"
                      className={`${
                        customerInformation.partyName ? '' : 'hide-from-print '
                      }`}
                      placeholder={`${
                        language[setting.user_language].phone_Number ||
                        language.en.phone_Number ||
                        'Phone Number'
                      }`}
                      value={customerInformation.phoneNumber}
                      onChange={({ target }) =>
                        setCustomerInformation({
                          ...customerInformation,
                          phoneNumber: target.value,
                        })
                      }
                    />
                  </td>
                </tr>
              ) : null}
              {showadditionalFields ? (
                <tr>
                  <td>
                    <Input
                      name="address"
                      className={`${
                        customerInformation.partyName ? '' : 'hide-from-print '
                      }`}
                      placeholder={`${
                        language[setting.user_language].address ||
                        language.en.address ||
                        'Address'
                      }`}
                      value={customerInformation.address}
                      onChange={({ target }) =>
                        setCustomerInformation({
                          ...customerInformation,
                          address: target.value,
                        })
                      }
                    />
                  </td>
                </tr>
              ) : null}
            </tbody>
          </table>
        </Form>
      </div>
    </div>
  );
};

export default memo(SalemanInfo);

SalemanInfo.propTypes = {
  invoiceComplete: PropTypes.bool.isRequired,
  language: PropTypes.object.isRequired,
  setting: PropTypes.object.isRequired,
  setSelectedCustomer: PropTypes.func.isRequired,
  customerInformation: PropTypes.object.isRequired,
  setCustomerInformation: PropTypes.func.isRequired,
  editInvoiceLoading: PropTypes.bool.isRequired,
  params: PropTypes.object.isRequired,
  editInvoice: PropTypes.object.isRequired,
};
