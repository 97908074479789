import React from 'react';
import { Row, Col } from 'antd';
import InvoicePageButton from 'common/InvoicePageButton/InvoicePageButton';
import { calcStyles } from './CalculatorDigits';

const PaymentOptions = ({language,setting:{user_language}}) => {
  const ln = language[user_language];
  const options = [
    { label: ln.card, className: 'invoice_button payment_options bg_primary' },
    { label: ln.giftCard, className: 'invoice_button payment_options bg_success' },
    { label: ln.pettyExpense, className: 'invoice_button payment_options bg_secondary' },
  ];

  return (
    <Row gutter={[0, 16]}>
      {options.map((option, index) => (
        <Col span={8} key={index} style={index < options.length - 1 ? calcStyles : null}>
          <InvoicePageButton className={option.className}>{option.label}</InvoicePageButton>
        </Col>
      ))}
    </Row>
  );
};

export default PaymentOptions;
