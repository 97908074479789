/* Libraries Import */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import PropTypes from "prop-types";
import { v4 as uuid } from "uuid";
import { Checkbox, Typography } from "antd";

/* Relative paths Import */
import { Button, TextArea } from "common";
import {
  getLocalStorage,
  setLocalStorage,
  removeFromLocalStorage,
} from "modules/common/utility/localStorage";
import { invoiceListComplete } from "modules/components/invoice/invoiceReducer";
import {
  decryptData,
  encryptData,
  sumOfArrayObject,
} from "modules/common/utility/utility";
import MultiplePayment from "./Multiplepayment/MultiplePayment";
import AdvanceOption from "./AdvanceOption/AdvanceOption";
import Payment from "./Payment/Payment";
import PaymentDetail from "./PaymentDetail/PaymentDetail";
import CalculatedPayment from "./Payment/CalculatedPayment/CalculatedPayment";

/* Class Syle Import */
import "theme/globalStyle.css";
import "./CustomerPaymentRecord.scss";
import PaymentCalculator from "./PaymentCalculator/PaymentCalculator";
/**
 * Initial Value for customer Infomation Component
 */

const CustomerPaymentRecord = ({
  setSelectedCustomer,
  invoiceComplete,
  language,
  setting,
  editInvoiceLoading,
  params,
  editInvoice,
  invoiceItemChanged,
  selectedCustomer,
  totalPrice,
  setInvoiceComplete,
  method,
  setMethod,
  setPrintData,
  customerInformation,
  setCustomerInformation,
  paymentForm,
  setPaymentForm,
  initialValues
}) => {
  /**
   *initial Value for Payment Infomation Component
   */
  /* use State Hook */
  const [comment, setComment] = useState("");
  const [invoiceList, setInvoiceList] = useState([]);
  const [itemQuantity, setItemQuantity] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [showAdvance, setShowAdvance] = useState(false);
  const [showComment, setShowComment] = useState(false);
  const [receiptTime, setReceiptTime] = useState({
    datetime: moment(),
    ischanged: false,
  });
  const [invoiceNumber, setInvoiceNumber] = useState(
    moment().format("DDMMYYYYhmmssSSS")
  );
  const [paymentWays, setPaymentWats] = useState([
    { paymentType: "cash", amount: "", comment: "", id: uuid() },
  ]);
  const [showOnReceipt, setShowOnReceipt] = useState(false);
  const [paymentInfo, setPaymentInfo] = useState(initialValues);

  /* use Selector Hook */
  const payAbleAmount = useSelector(
    (state) => state.commonReducer.payAbleAmount
  );

  // console.log("hihihihihhii",payAbleAmount)

  const grandTotal = useSelector((state) => state.commonReducer.grandTotalVal);
  //console.log('this is paymentform from customerpaymentrecord', paymentForm);

  /* Local Variables and Other Hooks */
  const sumOfAmount = sumOfArrayObject(paymentWays, "amount");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const payableAmount = grandTotal - sumOfAmount || 0;

  const handleAddingInvoice = (invoiceRecord, index, invoice) => {
    if (invoiceRecord[index]) {
      invoiceRecord[index].push(invoice);

      const invoiceCipherText = encryptData(invoiceRecord);

      setLocalStorage("invoice_record", invoiceCipherText);
    } else {
      invoiceRecord[index] = [...[]];
      invoiceRecord[index].push(invoice);

      const invoiceCipherText = encryptData(invoiceRecord);

      setLocalStorage("invoice_record", invoiceCipherText);
    }
  };
  let result = 0;
  let calculatedPromo = 0;
  let result1 = 0;
  const itemsList = getLocalStorage("invoice", []);
  const calculatePromo = () => {
    itemsList.map((item) => {
      if (item.deleted !== 1 && item.promocode?.value) {
        console.log("abc");
        if (item.promocode.promo_type == "not percent") {
          console.log("hahahah");
          return (result += parseFloat(item.promocode.value).toFixed(2));
        }

        if (item.promocode.promo_type == "percentage") {
          console.log("hahahah1");
          result =
            (item.total / 100) * parseFloat(item.promocode.value).toFixed(2);
          result1 += result;
          console.log("hahahah12", result1);
          return;
        }
        return;
      }

      return calculatedPromo;
    });
    console.log("na kar yar", result1);
    console.log("calculatedPromo", calculatedPromo);
    return result1;
  };
  const handleComplete = (event, discord = "no") => {
    if (itemsList.length) {
      console.log("customerpayment", itemsList);
      const invoice = {
        data: itemsList,
        type: method,
        isEdit: params.id !== undefined,
        RequestType: discord === "yes" ? "void-invoice" : "inovice",
        customerName:
          selectedCustomer.customer_name || customerInformation.partyName,
        cutomerPhoneNumber:
          selectedCustomer.customer_phone || customerInformation.phoneNumber,
        discountPercent: paymentForm.discountPercentage,
        discount: paymentForm.discount,
        discountDesc: "Discount",
        extraCharges: paymentForm.extraCharges,
        extraChargesDesc: "Extra Charges",
        gstAmmount: paymentForm.gstAmmount,
        gstPercentage: paymentForm.gstPercentage,
        payableamount: payAbleAmount,
        rtno: params.id ? params.id : invoiceNumber,
        invoiceId: editInvoice.id ? editInvoice.id : null,
        promodiscount: calculatePromo(),
        total: totalPrice,
        date: !receiptTime.ischanged
          ? moment(receiptTime.datetime).format("YYYY/MM/DD HH:mm:ss")
          : moment(new Date().toDateString()).format("YYYY/MM/DD HH:mm:ss"),
        //   ? moment(receiptTime.datetime).unix()
        //   : moment().unix(),
        gTotal: grandTotal,
        paymentMethod: paymentWays,
        totalItems,
        totalQuantity: itemQuantity,
        paidAmount: sumOfAmount,
        comment,
        workerId: null,
        showOnReceipt,
      };
      //      debugger;
      const updateTime = moment().format("YYYY/MM/DD HH:mm:ss");
      console.log("this is update time from costumerpaymentrecord", updateTime);
      setReceiptTime(() => ({
        datetime: moment().unix(),
        ischanged: false,
      }));
      const invoiceRecord = getLocalStorage("invoice_record", "", "string");
      const response = invoiceRecord.replaceAll('"', "");
      const originalInvoiceRecord = decryptData(
        response,
        "secret key 123",
        "[]"
      );

      const getInvoices = getLocalStorage("invoice_numbers", []);
      if (getInvoices.length <= 49) {
        getInvoices.push(params.id ? params.id : invoiceNumber);
      } else {
        getInvoices.shift();
        getInvoices.push(params.id ? params.id : invoiceNumber);
      }
      setLocalStorage("invoice_numbers", getInvoices);
      if (selectedCustomer.id) {
        invoice.customerId = selectedCustomer.id;
      }
      const flagExit = originalInvoiceRecord[0] === "notDeleted";
      if (flagExit) {
        handleAddingInvoice(originalInvoiceRecord, 1, invoice);
      }
      if (discord === "no") {
        setPrintData(invoice);
      }

      removeFromLocalStorage("invoice");
      setInvoiceComplete(true);

      setSelectedCustomer({});
      setComment("");
      setInvoiceNumber(moment().format("DDMMYYYYhmmssSSS"));

      dispatch(invoiceListComplete());

      if (params.id) {
        navigate("/");
      }
    }
  };

  useEffect(() => {
    // console.log(receiptTime, '226');
  }, [receiptTime]);

  //console.log(receiptTime, '200004');

  /* use Effect Hooks */
  useEffect(() => {
    const invoiceItems = getLocalStorage("invoice", []);
    setInvoiceList(invoiceItems);
  }, [invoiceItemChanged, invoiceComplete, params.id, editInvoiceLoading]);

  // const settings = useSelector((state) => state.commonReducer.response);
  // if(settings){
  //   console.log("hello",settings.data.roles)
  // }

  useEffect(() => {
    if (!showAdvance) {
      setPaymentForm(paymentForm);
    }
    console.log(" THIS IS HOW SHOW CHANGED");
  }, [showAdvance]);

  useEffect(() => {
    if (params.id && !editInvoiceLoading && Object.keys(editInvoice).length) {
      setPaymentWats(() => [...editInvoice.payment_method]);
    }
  }, [params.id, editInvoiceLoading]);

  useEffect(() => {
    const result = [...invoiceList];
    if (result.length) {
      /**
       * @param result (Array)
       * @param total (string)
       * @returns sum of the specific element of array of objects
       */

      let count = 0;
      for (let i = 0; i < result.length; i += 1) {
        if (!result[i].deleted) {
          count += 1;
        }
      }
      setTotalItems(count);
      const sumOfQuanlity = sumOfArrayObject(result, "quantity");
      setItemQuantity(sumOfQuanlity);
    } else {
      setTotalItems(0);
      setItemQuantity(0);
    }
  }, [
    invoiceList,
    invoiceComplete,
    invoiceItemChanged,
    params.id,
    editInvoiceLoading,
  ]);

  useEffect(() => {
    setInvoiceComplete(false);
  }, [invoiceComplete]);

  return (
    <div className="customer-payment-wrapper">
      <div className="payment-detail-wrapper">
        <PaymentDetail
          grandTotal={payableAmount}
          language={language}
          setting={setting}
        >
          {/* <Button onClick={(event) => handleComplete(event, 'yes')}>
            Void
          </Button> */}
          <Payment
            selectedCustomer={selectedCustomer}
            totalPrice={totalPrice}
            invoiceItemChanged={invoiceItemChanged}
            setPaymentInfo={setPaymentInfo}
            paymentInfo={paymentInfo}
            language={language}
            setting={setting}
            invoiceComplete={invoiceComplete}
            invoiceNumber={invoiceNumber}
            editInvoiceLoading={editInvoiceLoading}
            params={params}
            editInvoice={editInvoice}
            setMethod={setMethod}
            method={method}
            totalItems={totalItems}
            totalQuantity={itemQuantity}
            paymentForm={paymentForm}
            setPaymentForm={setPaymentForm}
            initialValues={initialValues}
            setCustomerInformation={setCustomerInformation}
            customerInformation={customerInformation}
            // setCustomerProduct={setCustomerProduct}
            setSelectedCustomer={setSelectedCustomer}
          />
          <div
            className="customer-payment-advance-wrapepr"
            style={{ marginTop: "-8px", color: "#6E6C85" }}
          >
            <PaymentCalculator
              paymentWays={paymentWays}
              setPaymentWats={setPaymentWats}
              language={language}
              setting={setting}
            />
            <Typography.Text
              onClick={() => setShowAdvance((prev) => !prev)}
              style={{
                textDecoration: "underline",
                cursor: "pointer",
                marginTop: "-5px",
                fontSize: "12px",
              }}
            >
              {language[setting.user_language].advanceOption}
            </Typography.Text>
            {showAdvance && (
              <AdvanceOption
                selectedCustomer={selectedCustomer}
                totalPrice={totalPrice}
                invoiceItemChanged={invoiceItemChanged}
                setPaymentInfo={setPaymentInfo}
                paymentInfo={paymentInfo}
                language={language}
                setting={setting}
                invoiceComplete={invoiceComplete}
                editInvoiceLoading={editInvoiceLoading}
                params={params}
                editInvoice={editInvoice}
                setMethod={setMethod}
                method={method}
                totalItems={totalItems}
                totalQuantity={itemQuantity}
                paymentForm={paymentForm}
                setPaymentForm={setPaymentForm}
                initialValues={initialValues}
                setCustomerInformation={setCustomerInformation}
                customerInformation={customerInformation}
                setSelectedCustomer={setSelectedCustomer}
                handleComplete={handleComplete}
                setReceiptTime={setReceiptTime}
                receiptTime={receiptTime}
                invoiceNumber={invoiceNumber}
              // setReceiptInfo={setReceiptInfo}
              // receiptInfo={receiptInfo}
              />
            )}
          </div>

          {showComment && (
            <div className="comment-wrapper">
              <div
                className={`comment-container ${!showOnReceipt ? "hide-from-print" : ""
                  }`}
              >
                <Typography>
                  {language[setting.user_language].comment}
                </Typography>
                <TextArea
                  value={comment}
                  rows={4}
                  onChange={(event) => setComment(event.target.value)}
                />
              </div>
              <Checkbox
                className="hide-from-print"
                // value={showOnReceipt}
                onChange={(event) => setShowOnReceipt(event.target.checked)}
              >
                {language[setting.user_language].show_comments_on_receipt ||
                  language.en.show_comments_on_receipt ||
                  "Show comments on receipt"}
              </Checkbox>
            </div>
          )}
        </PaymentDetail>
      </div>
    </div>
  );
};
export default CustomerPaymentRecord;

CustomerPaymentRecord.propTypes = {
  setSelectedCustomer: PropTypes.func.isRequired,
  language: PropTypes.object.isRequired,
  setting: PropTypes.object.isRequired,
  editInvoiceLoading: PropTypes.bool.isRequired,
  params: PropTypes.object.isRequired,
  editInvoice: PropTypes.object.isRequired,
  invoiceItemChanged: PropTypes.bool.isRequired,
  selectedCustomer: PropTypes.object.isRequired,
  totalPrice: PropTypes.number.isRequired,
  setInvoiceComplete: PropTypes.func.isRequired,
  method: PropTypes.string.isRequired,
  setMethod: PropTypes.func.isRequired,
  customerInformation: PropTypes.object.isRequired,
  setPrintData: PropTypes.func.isRequired,
  invoiceComplete: PropTypes.bool.isRequired,
  setCustomerInformation: PropTypes.func.isRequired,
};
