import ComponentLayout from 'common/ComponentLayout/Component';
import EditInvoiceForm from './EditInvoiceForm/EditInvoiceForm';

// We have create a layout for styling so i am using that layout.
const EditInvoice = () => {

  return(
  <ComponentLayout title="Edit Invoice" component={EditInvoiceForm} />
  )
  };
export default EditInvoice;
