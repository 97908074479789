import { setLocalStorage } from 'modules/common/utility/localStorage';
import { getCustomerProduct, getCustomersApi } from './customerQuery';

const { createSlice } = require('@reduxjs/toolkit');

const initialState = {
  customers: [],
  isLoading: false,
  isError: false,
  customerProduct: [],
};
const customer = createSlice({
  name: 'customer',
  initialState,
  extraReducers: {
    [getCustomersApi.pending]: (state) => {
      state.isLoading = true;
      state.isError = false;
    },
    [getCustomersApi.fulfilled]: (state, { payload }) => {
      setLocalStorage('customers', payload.data.customers);

      state.isLoading = false;
      state.isError = false;
      state.customers = payload.data.customers;
    },
    [getCustomersApi.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isError = true;
      state.response = payload;
    },
    [getCustomerProduct.pending]: (state) => {
      state.isLoading = true;
      state.isError = false;
    },
    [getCustomerProduct.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isError = false;
      state.customerProduct = payload.data.customerProducts;
    },
    [getCustomerProduct.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isError = true;
      state.response = payload;
    },
  },
});

export default customer.reducer;
