import React from "react";
import { Row, Col } from "antd";
import CalculatorDigits from "./CalculatorDigits";
import PaymentOptions from "./PaymentOptions";
import PayCash from "./PayCash";
import PaymentOperations from "./PaymentOperations";
import MultiplePayment from "../Multiplepayment/MultiplePayment";

const PaymentCalculator = ({
  language,
  setting,
  paymentWays,
  setPaymentWats,
}) => {
  return (
    <div>
      <MultiplePayment
        label={
          language[setting.user_language].addPayment ||
          language.en.addPayment ||
          "Add Payment"
        }
        advanceOption={language[setting.user_language].addMorePayment}
        paymentWays={paymentWays}
        setPaymentWats={setPaymentWats}
        commentText={language[setting.user_language].comment}
        language={language}
        setting={setting}
      />
      <Row gutter={[0, 16]}>
        <Col span={24}>
          <Row>
            <Col span={18}>
              <CalculatorDigits
                paymentWays={paymentWays}
                setPaymentWats={setPaymentWats}
                language={language}
                setting={setting}
              />
            </Col>
            <Col span={6}>
              <PaymentOperations language={language} setting={setting} />
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <PaymentOptions language={language} setting={setting} />
        </Col>
        <Col span={24}>
          <PayCash language={language} setting={setting} />
        </Col>
      </Row>
    </div>
  );
};

export default PaymentCalculator;
