import React from "react";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { promoDiscountApi } from "service/GetPromoDiscount";

export const getPromo = createAsyncThunk(
    'promo/getPromo',
    async (payload, { rejectWithValue }) => {
      try {
        const { data } = await promoDiscountApi.getPromoDiscount();
        return data;
      } catch (error) {
        return rejectWithValue({ error, payload });
      }
    }
  );

  const initialState = {         // this is initial state 
    settings: {},
    isLoading: false,
    isError: false,
    selectedPromo : null
  };

  const promodiscount = createSlice({
    name: 'promodiscount',
    initialState,
    reducers: {
      selectedPromoToState: (state/*(this is initailstate)*/, { payload }) => {
        state.selectedPromo = payload;
      },
    },
    extraReducers: {
      [getPromo.pending]: (state) => {
        state.isLoading = true;
        state.isError = false;
      },
      [getPromo.fulfilled]: (state, { payload }) => {
        state.isLoading = false;
        state.isError = false;
        state.response = payload;
      },
      [getPromo.rejected]: (state, { payload }) => {
        state.isLoading = false;
        state.isError = true;
        state.response = payload;
      },
    },
  });
  
  export const { selectedPromoToState } = promodiscount.actions;
  export default promodiscount.reducer;