import { Breadcrumb } from 'antd';
import { NavLink } from 'react-router-dom';
import language from 'translation/en/en.json';
import PropTypes from 'prop-types';
import { getLocalStorage } from './localStorage';

const BreadCrumb = ({ routeData, params,path, location, className }) => {
  const setting = getLocalStorage('setting', {});
  return routeData.length ? (
    <Breadcrumb
      className={className}
      style={{
        backgroundColor: 'white',
        height: '35px',
        marginBottom: '10px',
      }}
    >
      <Breadcrumb.Item>
        <NavLink to="/dashboard">
          {language[setting.user_language].Dashboard}
        </NavLink>
      </Breadcrumb.Item>
      {routeData[0].name !== 'Dashboard' ? (
        <Breadcrumb.Item>
          <NavLink to={routeData[0].path}>
            {language[setting.user_language][routeData[0].name]}
          </NavLink>
        </Breadcrumb.Item>
      ) : null}
      {params.id ? (
        <Breadcrumb.Item>
          <NavLink to={location.pathname}>{params.id}</NavLink>
        </Breadcrumb.Item>
      ) : null}
    </Breadcrumb>
  ) : null;
};
export default BreadCrumb;

BreadCrumb.propTypes = {
  routeData: PropTypes.array.isRequired,
  params: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
};
