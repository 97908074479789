import { combineReducers } from 'redux';
import authReducer from './authentication/authReducer';
import commonReducer from './common/commonReducer';
import customerReducer from './customer/customerReducer';
import invoiceReducer from './invoice/invoiceReducer';
import productReducer from './product/productReducer';
import PromoDiscount from './PromoDiscount/PromoDiscount';
import InvoiceIdsReducer from './invoiceIds/invoiveIdsReducer';
import deliveryBoyReducer from './deliveryBoy/deliveryBoyReducer'
import productSlice from './SearchInvoiceAPIProductList/productSlice';
const rootReducer = combineReducers({
  invoiceReducer,
  commonReducer,
  productReducer,
  customerReducer,
  authReducer,
  PromoDiscount,
  InvoiceIdsReducer,
  deliveryBoyReducer,
  invoices: productSlice,
});

export default rootReducer;

