import React from 'react';
import { useState, useEffect } from 'react';


import { DeleteOutlined, UndoOutlined } from '@ant-design/icons';
import PropTypes, { object } from 'prop-types';
import {
  sumOfArrayObject,
  handleChangeInvoices,
  handleDeleteInvoices,
} from 'modules/common/utility/utility';
import { Input, Table } from 'common';
import { Form } from 'antd';
import './ItemList.scss';
import { useDispatch, useSelector } from 'react-redux';
import { promoDiscountFunction } from 'modules/common/utility/utility';
import { result } from 'lodash';
import { setLocalStorage, getLocalStorage ,clearLocalStorage } from 'modules/common/utility/localStorage';

const ItemList = ({
  setting,
  setInvoiceItemChanged,
  invoiceItemChanged,
  invoiceComplete,
  setTotal,
  isLoading,
  language,
  editInvoiceLoading,
  params,
  SearchInvoiceApiProduct,
  SetSearchInvoiceApiProduct,
  SearchAPINumberCount,
  SetsearchAPINumberCount,
}) => {

  const [selectionType, setSelectionType] = useState('checkbox');
  const [form] = Form.useForm();
  let [invoiceList, setInvoiceList] = useState();

  const user = SearchInvoiceApiProduct
  console.log('invoiceComplete: ', invoiceComplete);
  console.log('invoiceItemChanged: ', invoiceItemChanged);
  
  const promoDiscountfromState = useSelector(
    (state) => state.PromoDiscount.selectedPromo
  );
  //console.log("promoDiscountfromState from itemlist",promoDiscountfromState)
  const [promoUpdated,setpromoUpdated] = useState(false) 
  const promoDiscountFunctionForInvoice = (record, promoDiscountfromState) => {
    console.log("promoDiscountFunctionForInvoice")
    if (promoDiscountfromState && promoDiscountfromState.product_type == "all_stock" && promoDiscountfromState.flat_discount !== null) {
      record.promocode = ({
        value: promoDiscountfromState.flat_discount || 0,
        name : promoDiscountfromState.name,
        type : "flat",
        promo_type :"not percentage" 
      })
    }
    else if (promoDiscountfromState && promoDiscountfromState.product_type == "all_stock" && promoDiscountfromState.discount_percent !== null) {
      record.promocode = ({
        value: promoDiscountfromState.discount_percent || 0,
        name : promoDiscountfromState.name,
        type : "flat",
        promo_type : "percentage" 
      })
    }
    else {
      let result = 0;
      if (promoDiscountfromState && promoDiscountfromState.product_type == null && promoDiscountfromState.flat_discount !== null) {
        promoDiscountfromState.product_detail.map((item) => {
          if (item == record.id) {
            record.promocode = ({
              value: promoDiscountfromState.flat_discount || 0,
              name : promoDiscountfromState.name,
              type : "not flat",
              promo_type :"not percent" 
            })
          }
          else{
            record.promocode = ({
              value:  0,
              name : promoDiscountfromState.name,
              type : "not flat",
              promo_type :"not percent" 
            })
          } 
        })
      }
      else {
        promoDiscountfromState?.product_detail.map((item) => {
          if(promoDiscountfromState && promoDiscountfromState.product_type == null && promoDiscountfromState.discount_percent !== null)
          {record.promocode = ({
            value: promoDiscountfromState.flat_discount || 0,
            name : promoDiscountfromState.name,
            type : "not flat",
            promo_type :" percent" 
          })}
          else{
            record.promocode = ({
              value: promoDiscountfromState.flat_discount || 0,
              name : promoDiscountfromState.name,
              type : "not flat",
              promo_type :" percent" 
            })
          }
        })
      }
    }
  }
   
  const promoFunction = () => {  
    if(invoiceList.length>0)
    (invoiceList.map(item=>(promoDiscountFunctionForInvoice(item,promoDiscountfromState))))
    setLocalStorage('invoice',invoiceList)
    setInvoiceItemChanged(false)
  }
  
  
  const customerProduct = useSelector(
    (state) => state.customerReducer.customerProduct
  );

  /**  why this use Effect ?
   * Need to improve this useEffect
  */
  invoiceList = getLocalStorage('invoice',[]);

  useEffect(() => {
    console.log("promoDiscountfromState from",promoDiscountfromState)
    promoFunction()
  }, [invoiceItemChanged, editInvoiceLoading, params, invoiceComplete, promoDiscountfromState]);


  //console.log("invoicelist", invoiceList)
  /**
   * 
   * 
   * Getting Sum of the invoice
   */
  useEffect(() => {
    /**
     * This method is for getting sum of the array of object
     * @param {array } invoiceList List of all the invoice Items
     * @param {string} total the key of object , for which you want to get sum
     */
    const sum = sumOfArrayObject(invoiceList, 'total');
    setTotal(sum);
  }, [invoiceList.length, invoiceItemChanged, invoiceComplete]);

  /**
   * This method is for deleting the row.
   * getting data from localStorage that  the invoice items we added for customer
   * @param {object} record
   */
  const handleDelete = (record) => {
    handleDeleteInvoices(record);
    setInvoiceItemChanged(true);
  };

  /**
   *
   * @param {string} name
   * @param {string | number} value
   * @param {string} uuid
   * we have input fields in the table and this handleChangeInvoices invokes when any of input field
   * value changed
   */
  
  // const handleChange = ({ name, value }, uuid) => {
  //   //console.log("handlechange")
  //   handleChangeInvoices(name, value, uuid);
  //   setInvoiceItemChanged(true);
  // };

  // this function update the ItemList Component when dependence valus is change
  function CheckHandleChange (){
    setInvoiceItemChanged(true);
    invoiceList = getLocalStorage('invoice',SearchInvoiceApiProduct);
  }
  useEffect(()=>{
    CheckHandleChange();
  },[SearchAPINumberCount,SearchInvoiceApiProduct])



  const getRecommendedPrice = (record) => {
    if (customerProduct.length) {
      const filteredProduct = customerProduct.filter(
        (prod) => prod.productname === record.productname
      );
      return filteredProduct.length ? filteredProduct[0].sale_price : '';
    }
    return '';
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === 'Disabled User',
      // Column configuration not to be checked
      name: record.name,
    }),
  };


  console.log("itemlist", invoiceList)
  

  const columns = [
    {
      title: `${(
        language[setting.user_language].sr ||
        language.en.sr ||
        'Sr.'
      ).toUpperCase()}`,
      key: 'Sr.',
      align: 'center',
      render: (data, record, index) => <span>{index + 1}</span>,
      width: 35,
    },
    {
      title: `${(
        language[setting.user_language].item ||
        language.en.item ||
        'Item'
      ).toUpperCase()}`,
      dataIndex: 'productname',
      key: 'item',
      render: (data, record) => (
        <span style={+record.deleted ? { textDecoration: 'line-through' } : {}}>
          <span>{`${record.productname}  `}</span>
          {getRecommendedPrice(record) ? (
            <span style={{ color: 'red', fontSize: '9px', paddingLeft: '20px' }}>
              {`Previous Price : ${getRecommendedPrice(record)}`}
            </span>
          ) : (
            ''
          )}
        </span>
      ),
      width: 360,
    },

    {
      title: `${(
        language[setting.user_language].promo_code ||
        language.en.promo_code ||
        'Promo Discount'
      ).toUpperCase()}`,
      dataIndex: 'promo',
      key: 'promo',
      // rendering a element inside antD table
      /* eslint-disable */
      render: (data, record) => {
        return +record.deleted ? (
          <span
            style={+record.deleted ? { textDecoration: 'line-through' } : {}}
          >
            {record.promocode?.value }
          </span>
        ) : (
          promoDiscountfromState ? (<span style={+record.deleted ?
            { textDecoration: 'line-through' } : {}}>
           {record.promocode?.value}
          </span>)
            :
            (<span style={+record.deleted ?
              { textDecoration: 'line-through' } : {}}>
              0
            </span>)

        );
      },

      editable: false,
      width: 100,
    },

    {
      title: `${(
        language[setting.user_language].quantity ||
        language.en.quantity ||
        'Qty'
      ).toUpperCase()}`,
      dataIndex: 'quantity',
      key: 'quantity',
      // rendering a element inside antD table
      /* eslint-disable */
      render: (data, record) => (
          <span
            style={+record.deleted ? { textDecoration: 'line-through' } : {}}
          >
            {record.quantity}
          </span>
   
      ),

      editable: false,
      width: 100,
    },

    {
      title: `${(
        language[setting.user_language].pack ||
        language.en.pack ||
        'Pack'
      ).toUpperCase()}`,
      dataIndex: 'pack',
      key: 'pack',
      hidden: setting.packsquantity === 0,
      render: (data, record) => {
        return +record.deleted ? (
          <span
            style={+record.deleted ? { textDecoration: 'line-through' } : {}}
          >
            {record.pack}
          </span>
        ) : (
          <Input
            value={record.pack}
            name="pack"
            onChange={(event) => handleChange(event.target, record.uuid)}
          />
        );
      },
      editable: true,

      width: setting.packsquantity === 0 ? '0%' : 100,
    },
    {
      title: `${(
        language[setting.user_language].price ||
        language.en.price ||
        'Price'
      ).toUpperCase()}`,
      dataIndex: 'price',
      key: 'price',
      editable: true,
      render: (data, record) => (
        
          <span
            style={+record.deleted ? { textDecoration: 'line-through' } : {}}
          >
            {record.price}
          </span>
      ),
      
      width: 100,
    },
    {
      title: `${(
        language[setting.user_language].discount ||
        language.en.discount ||
        'Discount'
      ).toUpperCase()}`,
      dataIndex: 'discount',
      key: 'discount',
      hidden: setting.productdiscount === 0,
      render: (data, record) => {
        // promoDiscountfromState ? value1 = promoDiscountFunction(record) : value1 = 0
        // //console.log("value1:", value1)
        return +record.deleted ? (
          <span
            style={+record.deleted ? { textDecoration: 'line-through' } : {}}
          >
            {record.discount}
          </span>
        ) :
          <Input
            value={record.discount}
            name="discount"
            disabled={setting.discountedit}
            onChange={(event) => {
              handleChange(event.target, record.uuid)
            }}
          />
      },
      width: setting.productdiscount === 0 ? '0%' : 100,
    },
    {
      title: `${(
        language[setting.user_language].discountPercentage ||
        language.en.discountPercentage
      ).toUpperCase()}`,
      dataIndex: 'percentage_disocunt',
      key: 'percentage_disocunt',
      hidden: setting.productdiscount === 0,
      render: (data, record) => {
        return +record.deleted ? (
          <span
            style={+record.deleted ? { textDecoration: 'line-through' } : {}}
          >
            {record.percentageDiscount}
          </span>

        ) : (
          <Input
            value={record.percentageDiscount}
            name="percentageDiscount"
            disabled={setting.discountedit}
            onChange={(event) => handleChange(event.target, record.uuid)}
          />
        );
      },
      width: setting.productdiscount === 0 ? '0%' : 100,
    },
    {
      title: `${(
        language[setting.user_language].total ||
        language.en.total ||
        'Total'
      ).toUpperCase()}`,
      dataIndex: 'total',
      key: 'total',
      width: 50,
      render: (data, record) => (
        <span style={+record.deleted ? { textDecoration: 'line-through' } : {}}>
          {record.total - (promoDiscountfromState ? record.quantity * promoDiscountFunction(record, promoDiscountfromState) : 0)}
        </span>
      ),

      align: 'center',
    },

    {
      // title: `${'Action'}`,
      dataIndex: 'action',
      // width: 5,
      align: 'center',
      fixed: 'right',
      width: 50,

      render: (data, record) => {
        return +record.deleted ? (
          <UndoOutlined
            className="colored-icon"
            style={{ cursor: 'pointer' }}
            onClick={() => handleDelete(record)}
          />
        ) : (
          <DeleteOutlined
            className="colored-icon"
            style={{ cursor: 'pointer' }}
            onClick={() => handleDelete(record)}
          />
        );
      },
    },

    // we have added hidden flag inside column so filtering item according to hidden flag
  ].filter((item) => !item.hidden);



  let columns1 = columns;
  const roles = useSelector((state) => state.commonReducer.response)?.data.roles;
  if (roles && !roles.includes("Discount.add")) {
    //console.log("a")
    columns1 = columns1.filter(item => item.dataIndex !== 'discount')
    //console.log(columns1)
  }

  if (roles && !roles.includes("Discount Percentage.add")) {                                /*discount check */
    columns1 = columns1.filter(item => item.dataIndex !== 'percentage_disocunt')
  }
  if (roles && !roles.includes("Discount.add") && !roles.includes("Discount Percentage.add")) {
    //console.log("c")
    columns1 = columns1.filter(item => item.dataIndex !== 'percentage_disocunt')
    columns1 = columns1.filter(item => item.dataIndex !== 'discount')
    //console.log(columns1)
  }

console.log("Api data show from ItemList.js ",SearchInvoiceApiProduct)
console.log("data show sucessfully: to Appending ",invoiceList)
  return (
    <div className="item-list-wrapper">
      {console.log("checking")}
      <Form form={form} component={false}>
        <Table
             rowSelection={{
              type: selectionType,
              ...rowSelection,
            }}
          loading={isLoading}
          rowKey={(record) => record.uuid}
          tableLayout="auto"
          bordered
          dataSource={invoiceList}
          columns={columns1}
          pagination={false}
          // scroll={{ x: 240 }}
          size="small"
        />
      </Form>
    </div>
  );
};
export default ItemList;

ItemList.defaultProps = {
  isLoading: false,
};
ItemList.propTypes = {
  setting: PropTypes.object.isRequired,
  setInvoiceItemChanged: PropTypes.func.isRequired,
  invoiceItemChanged: PropTypes.bool.isRequired,
  setTotal: PropTypes.func.isRequired,
  setInvoiceComplete: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  language: PropTypes.object.isRequired,
  editInvoiceLoading: PropTypes.bool.isRequired,
  params: PropTypes.object.isRequired,
  invoiceComplete: PropTypes.bool.isRequired,
};