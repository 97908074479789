// Libraries Import
import { useEffect, useState, useReducer } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'antd';
import PropTypes from 'prop-types';
import { ErrorBoundary } from 'react-error-boundary';

// Relative path Import
import language from 'translation/en/en.json';
import PrintMedia from 'PrintMedia';
import { editInvoice } from 'modules/components/invoice/invoiceReducer';
import { getLocalStorage } from 'modules/common/utility/localStorage';
import { getToken, sumOfArrayObject } from 'modules/common/utility/utility';
import InvoiceItem from '../ItemRecord/invoiceForm/invoiceItem';
import ProductsTable from '../ProductsTable/ProductsTable';
import CustomerPaymentRecord from '../CustomerPaymentRecord/CustomerPaymentRecord';
// Style CSS Import




import Buttons from './InvoiceButtons';

/**
 * Initial Value for customer Infomation Component
 */
const customerInitialValues = {
  partyName: '',
  phoneNumber: '',
};
const MainInvoice = ({ setting}) => {


  const dispatch = useDispatch();
  const params = useParams();

  /* State Variables */
  const editInvoiceError = useSelector((state) => state.invoiceReducer.isError);
  const [invoiceComplete, setInvoiceComplete] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState({});
  const [invoiceItemChanged, setInvoiceItemChanged] = useState(false);
  const [invoiceGrandTotal, setInvoiceGrandTotal] = useState(0);
  const [method, setMethod] = useState('Sale');
  const [printData, setPrintData] = useState({});
  const [SearchAPINumberCount, SetsearchAPINumberCount] = useReducer(x=> x + 1 , 0);
  // SearchInvoiceApiProduct is use in SearchInvoice component to for API data 
  const [SearchInvoiceApiProduct, SetSearchInvoiceApiProduct ] = useState({});
  const [customerInformation, setCustomerInformation] = useState(
    customerInitialValues
  );
  const initialValues = {
    gstPercentage: 0,
    gstAmmount: 0,
    extraChargesName:
      language[setting.user_language].serviceCharges ||
      language.en.serviceCharges ||
      "Service Charges",
    extraCharges: 0,
    discountName:
      language[setting.user_language].discount ||
      language.en.discount ||
      "Discount",
    discountPercentage: 0,
    discount: 0,
    grandTotal: 0,
  };

  // TODO,,, i will have to take this to the upper level and implement discount logic
  const [paymentForm, setPaymentForm] = useState(initialValues);  
  

  /* useSelector Hook */
  const editInvoiceLoading = useSelector(
    (state) => state.invoiceReducer.isLoading
  );


  const editInvoiceData = useSelector((state) => state.invoiceReducer.invoice);

  /**
   *
   * @returns true or Error
   * @description this component will return errors on the screen
   */
  const handleErrorScreen = () => (getToken() ? true : <ErrorBoundary />);

  /* use Effect Hook */

  /**
   * This useEffect will invoke when we want to edit invoice
   */
  useEffect(() => {
    if (params.id) {
      const values = {
        invoiceNumber: params.id,
      };
      dispatch(editInvoice(values));
    }
  }, [params, dispatch]);

  /**
   * Calculating total of invoice price
   * passing invoiceComplete in dependency to reset sum on completing the invoice
   */

  useEffect(() => {
    const result = getLocalStorage('invoice', []);
    if (result.length) {
      /**
       * @param result (Array)
       * @param total (string)
       * @returns sum of the specific element of array of objects
       */
      const promocode = 0;
      const sum = sumOfArrayObject(result, 'total');
      const sum1 = sum - promocode;
      setInvoiceGrandTotal(sum1);
    } else {
      setInvoiceGrandTotal(0);
    }
  }, [invoiceComplete, invoiceItemChanged]);

  // const result = getLocalStorage('invoice', []);
  //   ////////////getting roles                                                           ////////////
  const roles = useSelector((state) => state.commonReducer.response)?.data.roles;
  // const products = useSelector((state) => state.productReducer.products);
  // console.log("these are products",products);

console.log("SearchInvoiceApiProduct in MainInvoice => : ",SearchInvoiceApiProduct)


  return handleErrorScreen() && !Object.keys(printData).length ? (
    <div>
      <div className="invoice-wrapper">
        <Row gutter={[16, 16]}>
          <Col xs={24} lg={16}  >
            <Row>
              <Col xs={{ span: 24 }}>
                <div className="invoice-container-ivoiceItem">
{/* Add Iteam in Invoice */}
                  <InvoiceItem
                    method={method}
                    setInvoiceItemChanged={setInvoiceItemChanged}
                    selectedCustomer={selectedCustomer}
                    language={language}
                    setting={setting}
                  />
                </div>
              </Col>
              {console.log("printdata", printData)}
              <Col xs={{ span: 24 }}>
                {
            // Product list show 
                  roles && roles.includes("Products.view") && // adding privacy depending upon permission   
                  <ProductsTable
                  SearchAPINumberCount = {SearchAPINumberCount}
                  SetsearchAPINumberCount = {SetsearchAPINumberCount}
                  SearchInvoiceApiProduct ={SearchInvoiceApiProduct}
                  SetSearchInvoiceApiProduct = {SetSearchInvoiceApiProduct}
                    invoiceItemChanged={invoiceItemChanged}
                    setInvoiceItemChanged={setInvoiceItemChanged}
                    setTotal={setInvoiceGrandTotal}
                    invoiceComplete={invoiceComplete}
                    setInvoiceComplete={setInvoiceComplete}
                    language={language}
                    setting={setting}
                    editInvoiceLoading={editInvoiceLoading}
                    editInvoiceError={editInvoiceError}
                    params={params}
                    editInvoice={editInvoiceData}
                    selectedCustomer={selectedCustomer}
                  />
                }


              </Col>
            {/* Buttons Invoice Page */}
            <Buttons language={language} 
            setting={setting} 
            totalPrice={invoiceGrandTotal} 
            paymentForm={paymentForm} 
            setPaymentForm={setPaymentForm}
            SearchInvoiceApiProduct = {SearchInvoiceApiProduct}
            SetSearchInvoiceApiProduct = {SetSearchInvoiceApiProduct}
            SearchAPINumberCount = {SearchAPINumberCount}
            SetsearchAPINumberCount = {SetsearchAPINumberCount}
            />
            </Row>
          </Col>
          <Col xs={24} lg={8}>
            <Row>
              <Col xs={{ span: 24 }}>
                {
                  roles && roles.includes("Payments.view") && // adding privacy depending upon permission
                  <CustomerPaymentRecord
                    setSelectedCustomer={setSelectedCustomer}
                    invoiceComplete={invoiceComplete}
                    language={language}
                    setting={setting}
                    editInvoiceLoading={editInvoiceLoading}
                    params={params}
                    editInvoice={editInvoiceData}
                    invoiceItemChanged={invoiceItemChanged}
                    selectedCustomer={selectedCustomer}
                    totalPrice={invoiceGrandTotal}
                    setInvoiceComplete={setInvoiceComplete}
                    method={method}
                    setMethod={setMethod}
                    setPrintData={setPrintData}
                    customerInformation={customerInformation}
                    setCustomerInformation={setCustomerInformation}
                    paymentForm={paymentForm}
                    setPaymentForm={setPaymentForm}
                    initialValues={initialValues}
                  />
                }
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <br />
    </div>
  ) : (
    <div style={{ backgroundColor: 'black', width: '100%' }}>
      <PrintMedia
        printData={printData}
        setPrintData={setPrintData}
        setting={setting}
        language={language}

      />
    </div>
  );
};
export default MainInvoice;

MainInvoice.propTypes = {
  setting: PropTypes.object.isRequired,
};
