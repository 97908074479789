export const host = 'sspos_';

export const setLocalStorage = (key, value) => window.localStorage.setItem(`${host}${key}`, JSON.stringify(value));

export const getLocalStorage = (key, initialValue = {}, type = 'object') => {
  if (type === 'string') {
    return window.localStorage.getItem(`${host}${key}`) || initialValue;
  }

  return (
    JSON.parse(window.localStorage.getItem(`${host}${key}`)) || initialValue
  );
};

export const removeFromLocalStorage = (key) => {
  // const loadStorage = getLocalStorage(`${host}${key}`);
  // if (loadStorage) {
  window.localStorage.removeItem(`${host}${key}`);
  // return true;
  // }
  return true;
};

export const clearLocalStorage = () => {
  window.localStorage.clear();
  return true;
};
  
export const updateLocalStorageArray = (key, value, initalValue = []) => {
  console.log("Local Storage Data is Change Succesfully");
  let data = getLocalStorage(`${host}${key}`, initalValue);
  if (data.length) {
    data.push(value);
  } else {
    data = [value];
  }

  setLocalStorage(`${host}${key}`, data);
};


