import { useEffect, useState } from 'react';
import { Col, Form, Row } from 'antd';
import { Button } from 'common';
import { getLocalStorage } from 'modules/common/utility/localStorage';
import language from 'translation/en/en.json';
import { useNavigate } from 'react-router-dom';
import { AutoComplete } from 'common';

/**
 * Initital Values for this component
 * Setting requestType to undefined because antD issue (need to fix in futrue)
 * https://github.com/ant-design/ant-design/issues/16417
 */

const initialValues = {
  invoiceNumber: '',
  requestType: undefined,
};
const EditInvoiceForm = () => {
  //   const dispatch = useDispatch();

  // fetching data from localStorage
  const [setting] = useState(getLocalStorage('setting'));
  const [invoiceNumbers, setInvoiceNumbers] = useState([]);

  const navigate = useNavigate();
  // antD Form
  const [form] = Form.useForm();

  /**
   *
   * @param {object} values values are the data we entered in the form
   */
  const handleFinish = (values) => {
    if (values.invoiceNumber < 0) {
      return form.setFields([
        {
          name: 'invoiceNumber',
          errors: ["Invoice Number can't be negative"],
        },
      ]);
    }
    return navigate(`/invoice/${values.invoiceNumber}`);
  };

  /**
   * Reseting AntD form
   * resetFields is built in antD method with will reset and initialValue will be assign to the form
   * according to the prop we passed to form <Form ... initialValue={initialValues}/>
   */
  const handleCancel = () => {
    form.resetFields();
  };

  useEffect(() => {
    setInvoiceNumbers(getLocalStorage('invoice_numbers', []));
  }, []);

  return (
    <div className="add-payment-wrapper">
      <Form
        layout="vertical"
        onFinish={handleFinish}
        initialValues={initialValues}
        form={form}
      >
        <Row gutter={12}>
          <Col xs={24} sm={12} md={12} lg={12}>
            <Form.Item
              name="invoiceNumber"
              rules={[{ required: true, message: 'Invoice Number is Requred' }]}
            >
              {/* <Input
                type="number"
                placeholder={`${
                  language[setting.user_language].invoiceNumber ||
                  language.en.invoiceNumber ||
                  'Invoice Number'
                }`}
              /> */}
              <AutoComplete
                placeholder={`${
                  language[setting.user_language].invoiceNumber ||
                  language.en.invoiceNumber ||
                  'Invoice Number'
                }`}
                options={invoiceNumbers.map((invoice) => ({
                  key: invoice,
                  label: invoice,
                  value: invoice,
                  id: invoice,
                }))}
                filterOption={(input, option) =>
                  option.value.toUpperCase().indexOf(input.toUpperCase()) >= 0
                }
              />
            </Form.Item>
          </Col>

          <Col span={24} className="save-btn-col">
            <Form.Item>
              <Button type="primary" htmlType="submit">
                {language[setting.user_language].load ||
                  language.en.load ||
                  'Load'}
              </Button>
              <Button onClick={handleCancel}>Cancel</Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default EditInvoiceForm;
