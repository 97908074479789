import React from 'react'
import { Row, Col } from 'antd'
import InvoicePageButton from 'common/InvoicePageButton/InvoicePageButton';

const PayCash = ({language,setting:{user_language}}) => {
  const ln = language[user_language];
  return (
    <Row gutter={[0,16]}>
        <Col span={24}>
            <InvoicePageButton className='invoice_button pay_cash bg_danger'>{ln.payCash}</InvoicePageButton>
        </Col>
    </Row>
  )
}

export default PayCash