import React from 'react';
import { Button, TextArea, DatePicker } from 'common';
import moment from 'moment';
import { Form } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { saveInvoice } from 'modules/components/invoice/invoiceReducer';
import { useDispatch } from 'react-redux';

const initialValues = {
  date: moment(),
  remainder: '',
};
const wrapperCol = {
  xs: { span: 24 },
  sm: { span: 24 },
  md: { span: 8, offset: 8 },
  lg: { span: 8, offset: 8 },
};
const RemainderForm = () => {
  const dispatch = useDispatch();
  const [form] = useForm();
  const onFinish = (values) => {
    const remainder = {
      RequestType: 'remainder',
      data: values,
    };
    dispatch(saveInvoice(remainder));
    form.resetFields();
  };
  return (
    <Form
      form={form}
      name="add-expenditure"
      onFinish={onFinish}
      wrapperCol={wrapperCol}
      initialValues={initialValues}
    >
      <Form.Item
        name="dateTime"
        rules={[{ required: true, message: 'Date Time is Required' }]}
      >
        <DatePicker
          placeholder="Date"
          showTime
          style={{ width: '100%' }}
          format="YYYY/MM/DD"
        />
      </Form.Item>

      <Form.Item name="remainder">
        <TextArea placeholder="Remainder" row={4} />
      </Form.Item>

      <Form.Item>
        <div
          style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
        >
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};
export default RemainderForm;
