import React, { useState, useEffect } from "react";
import { invoiceApi } from "service/invoice";
import { Input, Spin } from "antd";
import { Table } from "common";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuid } from "uuid";
import { getLatestInvoices } from "modules/components/SearchInvoiceAPIProductList/productSlice";
import {
  setLocalStorage,
  removeFromLocalStorage,
} from "modules/common/utility/localStorage";


const { Search } = Input;

// SearchInvoiceApiProduct is as props of redux store coming from MainInvoice.js File 
const SearchInvoice = ({
  SearchInvoiceApiProduct,
  SetSearchInvoiceApiProduct,
  SearchAPINumberCount,
  SetsearchAPINumberCount,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [invoiceData, setInvoiceData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [GetInvoiceNumOnly , SetInvoiceNumOnly] = useState(0);
  // Redux Toolkit
  const dispatch = useDispatch();
  const invoices = useSelector((state) => state.invoices);
  // ---------
  const [apiAllProductList, setApiAllProductList] = useState([]);
  
  // console.log("show and display apiAllProductList", apiAllProductList);


// it fatch **API** data on Base ID
  const latestInvoices = async (GetInvoiceNumOnly) => {
    try {
      setLoading(true);
      const res = await invoiceApi.getLatestInvoices(GetInvoiceNumOnly);
      console.log("Geting ten data: ", res);
      // save list of all prodcut list in api
      setApiAllProductList(res.data.data.inovice.inovice_prod);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  // called the latestInvoice function once the modal is opened
  useEffect(() => {
    latestInvoices(GetInvoiceNumOnly);
  }, [GetInvoiceNumOnly]);

  // search filter for Invoice List
  useEffect(() => {
    dispatch(getLatestInvoices());
  }, [dispatch]);

// In this it fetch **API** data and filter on SearchBar   // Fetching the latest 10 invoices
const fetchData = async (value) => {
  try {
    const response = await invoiceApi.getTenInvoiceData();
    const users = response.data.data.customerInovices;

    // This show filter the data from API by Invoice Number
    const filteredInvoices = users.filter((invoice) => {
      // Check if both invoice and rtno exist
      if (!invoice || !invoice.rtno) return false;
      // Handle different data types for rtno
      const rtnoString = typeof invoice.rtno === "string" ? invoice.rtno.toLowerCase() : String(invoice.rtno);
      // Filter based on search query match
      return rtnoString.includes(searchQuery.toLowerCase());
    });
      // console.log('results: ', filteredInvoices);  
      if(filteredInvoices == ''){
        // This all Commented console is use as debuge
        // -----------------------------------
        // console.log("filterinvoice is empty");
        // console.log('filteredInvoices: ', filteredInvoices);
        // console.log('invoiceData: ', invoiceData);
        // console.log('searchQuery: ', searchQuery);
        // -----------------------------------
        
        setInvoiceData([
          {
            invoice_no: '',
            amount: '',
            date: '',
          },
        ]);
      }else{
        // This all Commented console is use as debuge
        // -----------------------------------
        // console.log("filterinvoice is not empty be happpy");
        // console.log('filteredInvoices: ', filteredInvoices);
        // console.log('filteredInvoices index number: ', filteredInvoices[0].rtno);
        // console.log('filteredInvoices index number slice: ', filteredInvoices[0].rtno.slice(0, 3));
        // console.log('invoiceData: ', invoiceData);
        // console.log('searchQuery: ', searchQuery);
        // -----------------------------------
        const GetSearchQueryLength = searchQuery.length;
        setInvoiceData([]);
        if(searchQuery == filteredInvoices[0].rtno.slice(0, GetSearchQueryLength)){
          filteredInvoices.map((item, index) => {
            setInvoiceData((prevData) =>[
              ...prevData,
                {
                  key: index,
                  invoice_no: item.rtno,
                  amount: item.total,
                  date: item.dt,
                },
              ]);
            })
            // console.log("every thing is ok");
        }else{
          setInvoiceData([
            {
              invoice_no: '',
              amount: '',
              date: '',
            },
          ]);
          // console.log("every thing not fine");
        }
          
      }
  } catch (error) {
    console.error(error);
  }
};
useEffect(() => {
  fetchData();
}, [searchQuery]);


const handleInvoiceSearch = (value) => {
  setSearchQuery(value);
  fetchData(value);
};

  // In this function it load APi Product list to background Product Table on click.

  function loadDataBackground() {
    // removeFromLocalStorage("invoice");
    // console.log("Redux State:", invoices.data.data.inovice.inovice_prod);
    // SetSearchInvoiceApiProduct(apiAllProductList); this is for store the in redux
    // console.log("Props data shifting: ", SearchInvoiceApiProduct);
  // console.log("ALL API PRODUCT DATA",apiAllProductList)

   const changeWithUUID = apiAllProductList.map((item) => ({
      uuid: uuid(),
      // ...item // it loop in APi automatically but there is few data which didnot show in APi i do manualy,
      price: item.price,
      total: item.quantity * item.price,
      quantity: item.quantity,
      id: null,
      productname: item.productname,
      productcode: item.productname,
      pack: item.pack,
      discount: parseInt(item.discount),
      percentageDiscount: 0,
      deleted:  parseInt(item.deleted),
      singlePercentAgeDiscount: 0,
      singleDiscount: 0,
      promocode: {},
    }));
    SetSearchInvoiceApiProduct(changeWithUUID)

    setLocalStorage("invoice", changeWithUUID);
// console.log("ALL API PRODUCT DATA with uuid",changeWithUUID)
  };
  

  useEffect(()=>{
    loadDataBackground();
  }, [apiAllProductList])
  

  return (
    <div style={{ textAlign: "center" }}>
      {isLoading ? (
        <Spin />
      ) : (
        <>
          <Search
            placeholder="Search Invoice by number"
            allowClear
            // enterButton="Search"
            size="large"
            onChange={(e) => handleInvoiceSearch(e.target.value)}
            value={searchQuery}
            // onSearch={handleInvoiceSearch}
          />
          <Table
            pagination={{
              pageSize: 50,
            }}
            scroll={{
              y: 240,
            }}
            columns={columns}
            dataSource={invoiceData}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  SetInvoiceNumOnly(record.invoice_no);                  
                  loadDataBackground();
                  SetsearchAPINumberCount() ;
                },
              };
            }}
            style={{ cursor: "pointer" }}
          />
        </>
      )}
    </div>
  );
};

export default SearchInvoice;
const columns = [
  {
    title: "Invoice Number",
    dataIndex: "invoice_no",
    key: "invoice_no",
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
  },
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
  },
];
