import { setLocalStorage } from 'modules/common/utility/localStorage';
import { commonApi } from 'service/common';

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

export const getSetting = createAsyncThunk(
  'user/setting',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await commonApi.getSettings();
      return data;
    } catch (error) {
      return rejectWithValue({ error, payload });
    }
  }
);

const initialState = {         // this is initial state 
  settings: {},
  isLoading: false,
  isError: false,
  grandTotalVal: 0,
  payAbleAmount: 0,
};


const common = createSlice({
  name: 'common',
  initialState,
  reducers: {
    updateTotal: (state/*(this is initailstate)*/, { payload }) => {
      state.grandTotalVal = parseFloat(payload.grandTotal);
      state.payAbleAmount = parseFloat(payload.payableAmount);
    },
  },
  extraReducers: {
    [getSetting.pending]: (state) => {
      state.isLoading = true;
      state.isError = false;
    },
    [getSetting.fulfilled]: (state, { payload }) => {
      setLocalStorage('setting', payload.data.userSettings);
      state.isLoading = false;
      state.isError = false;
      state.response = payload;
    },
    [getSetting.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isError = true;
      state.response = payload;
    },
  },
});

export const { updateTotal } = common.actions;
export default common.reducer;
