// Libraries Export
import { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Menu, Dropdown, Avatar } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

// Relative Path Import
import { getFistAlphabet, getEmail } from 'modules/common/utility/utility';
import { removeFromLocalStorage } from 'modules/common/utility/localStorage';
import language from 'translation/en/en.json';
import { auth } from 'service/authentication';

// CSS export

import './ProfileMenu.scss';

/**
 * This is for adding styling for avatar icon
 */
const avartarStyle = {
  backgroundColor: '#e6f8f4',
  color: '#29b69c',
  fontSize: '16px',
  fontWeight: 'bold',
  verticalAlign: 'middle',
};

/**
 * @function ProfileMenu
 * @param {setting} setting is object we are receiving from the server
 * to add different condition on our application
 * This function is responsible for adding Logout functionality and show user email on Nav bar
 */
const ProfileMenu = ({ setting }) => {
  const navigate = useNavigate();

  const menuItems = [
    {
      key: 'logout',
      label: language[`${setting.user_language}`].logout,
    },
  ];

  /**
   * @function handleLogOut
   * @param {} it receives no Params
   * @description This function is responsible for logging out the user and clear localstorage.
   *
   */
  const handleLogOut = async () => {
    try {
      await auth.logout();
      removeFromLocalStorage('products');
      removeFromLocalStorage('customers');
      removeFromLocalStorage('setting');
      removeFromLocalStorage('user');
      document.getElementsByTagName('html')[0].setAttribute('dir', 'ltr');
      navigate('/login');
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * @function onMenuClick
   * @description when user click on dropdown , this click on logout this function invokes.
   */
  const onMenuClick = (event) => {
    const { key } = event;
    if (key === 'logout') {
      handleLogOut();
    }
  };
  /**
   * Creating menu list like `user_profile`,`Logout`
   * Currently we are using only Logout menu
   */
  const menu = (
    <Menu selectedKeys={[]} onClick={onMenuClick} items={menuItems} />
  );
  return (
    <Dropdown
      overlay={menu}
      placement="bottom"
      trigger={['click']}
      arrow
      className="drop-down-wrapper"
    >
      <span
        className="profile-menu-wrapper"
        style={{ backgroundColor: '#302F3E', cursor: 'pointer' }}
      >
        <span>
          <span className="avatar-area">
            <Avatar style={avartarStyle}>{getFistAlphabet(getEmail())}</Avatar>
          </span>
          <span style={{ cursor: 'pointer' }}>
            <span
              style={{
                color: 'white',
                marginRight: '1rem',
                fontSize: '12px',
              }}
            >
              {getEmail()}
            </span>
          </span>
          <DownOutlined
            style={{
              color: 'white',
              marginRight: '2rem',
              fontSize: '16px',
            }}
          />
        </span>
      </span>
    </Dropdown>
  );
};

export default memo(ProfileMenu);

ProfileMenu.propTypes = {
  setting: PropTypes.object.isRequired,
};
