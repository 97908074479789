import './Style/Layout.scss';
import { Layout } from 'antd';
import {
  Navigate,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getLocalStorage } from 'modules/common/utility/localStorage';

import { isAuthFlag } from 'modules/common/utility/utility';
import BreadCrumb from 'modules/common/utility/BreadCrumb';
import useWindowDimensions from 'modules/common/utility/WindowDimensionHook';
import HeaderMain from './HeaderMain';
import LeftSideBar from './LetSideBar';
import { routes } from './routing';
import './LayoutMain.scss';
import MobileMenu from './MobileMenu/MobileMenu';

const LayoutMain = () => {
  const [setting] = useState(getLocalStorage('setting', {}));
  const [showSideBar, setShowSidebar] = useState(false);
  const { width } = useWindowDimensions();
  const navigate = useNavigate();
  const location = useLocation();
  const parameters = useParams();
  let path = { ...location }.pathname;
  if (parameters.id) {
    path = path.replace(`/${parameters.id}`, '');
  }

  const routeData = routes.filter((route) => route.path === path);

  const data = getLocalStorage('setting').is_rtl;
  useEffect(() => {
    if (location.pathname === '/') {
      navigate('/dashboard');
    }
    setShowSidebar(false);
  }, [location.pathname]);

  if (!isAuthFlag()) {
    return <Navigate to="/login" />;
  }

  return (
    <Layout style={{ minHeight: '100vh' }} className="layout-wrapper">
      {/* <LeftSider /> */}
      <LeftSideBar
        placement={data}
        showSideBar={showSideBar}
        setShowSidebar={setShowSidebar}
        path={path}
        width={width}
      />

      <Layout className="site-layout">
        <HeaderMain
          showSideBar={showSideBar}
          setShowSidebar={setShowSidebar}
          width={width}
          setting={setting}
        />
        {width < 520 && <MobileMenu />}
        <BreadCrumb
          routeData={routeData}
          params={parameters}
          path={path}
          location={location}
          className="breadcrumb-wrapper hide-from-print"
        />
        <div>
          <Outlet />
        </div>
        {/* <FooterMain /> */}
      </Layout>
    </Layout>
  );
};
export default LayoutMain;
