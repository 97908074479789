import { configureStore } from '@reduxjs/toolkit';
import rootReducer from 'modules/components/rootReducer';

export const store = configureStore({
  reducer: rootReducer,

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
