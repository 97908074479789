import PropTypes from 'prop-types';
import Button from 'antd/lib/button';
import './Button.scss';

const AntDButton = ({ type, onClick, children, ...rest }) => (
  <div className="antd-btn-wrapper">
    <div className={`antd-btn-${type}`}>
      <Button onClick={onClick} type={type} {...rest}>
        {children}
      </Button>
    </div>
  </div>
);

export default AntDButton;
AntDButton.defaultProps = {
  type: 'default',
  onClick: () => {},
};
AntDButton.propTypes = {
  type: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.any.isRequired,
};
