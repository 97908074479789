import { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import 'theme/globalStyle.css';
import { useDispatch } from 'react-redux';
import { updateTotal } from 'modules/components/common/commonReducer';
import CalculatedPayment from './CalculatedPayment/CalculatedPayment';
import './Payment.scss';

const Payment = ({
  selectedCustomer,
  totalPrice,
  invoiceItemChanged,
  language,
  setting,
  invoiceComplete,
  editInvoiceLoading,
  params,
  editInvoice,
  totalItems,
  totalQuantity,
  paymentForm,
  setPaymentForm,
  initialValues,
  setCustomerInformation,
  customerInformation,
  setSelectedCustomer,
}) => {
  // const [, setPaymentValue] = useState({});
  /**
   * Checking if Party Name is selected and its previous_balance
   */

  const prevBalance = Object.values(selectedCustomer).length
    ? selectedCustomer.current_balance || 0
    : 0;

  // const navigate = useNavigate();

  const dispatch = useDispatch();

  // Calculating grand Total (totalprice - gst + discount - extra charges)
  /**
   * Grand Total is total after adding gst , discount and extracharges
   */

  const grandTotal = parseFloat(
    totalPrice +
      parseFloat(paymentForm.gstAmmount || 0) +
      parseFloat(paymentForm.extraCharges || 0) -
      parseFloat(paymentForm.discount || 0)
  ).toFixed(2);

  /**
   * Calculating payableAmount (totalprice - gst + discount - extra charges - previousBalance)
   * PayableAmount is grantTotal and previous balance of customer
   */
  const payableAmount = parseFloat(
    parseFloat(prevBalance) + parseFloat(grandTotal)
  ).toFixed(2);

  // Dispatchign the value to redux

  useEffect(() => {
    dispatch(updateTotal({ grandTotal, payableAmount }));
  }, [grandTotal, payableAmount, invoiceItemChanged]);

  /**
   * We will receieve gst in percentage and converting it into value
   * @param {string || number} value
   */
  // console.log(paymentForm.form.getFieldValue('gstAmmount'), 766);
  useEffect(() => {
    const gstAmmount =
      paymentForm.gstpercentage && totalPrice
        ? ((totalPrice * paymentForm.gstpercentage) / 100).toFixed(2) || 0
        : 0;

    /* eslint-disable */
    const discount =
      paymentForm.discountpercentage && totalPrice
        ? (totalPrice * parseFloat(paymentForm.discountpercentage)) /
            (100).toFixed(2) || 0
        : 0;
    /* eslint-enable */
    setPaymentForm({
      ...paymentForm,
      gstAmmount: parseFloat(gstAmmount),
      discount: parseFloat(discount),
    });
  }, [totalPrice]);

  const resetForm = () => {
    setPaymentForm({ ...paymentForm, initialValues });
  };
  useEffect(() => {
    if (params.id && Object.keys(editInvoice).length > 0) {
      const gstAmmount =
        ((totalPrice * editInvoice.gstpercent) / 100).toFixed(2) || 0;
      const discount =
        ((totalPrice * editInvoice.discountinpercent) / 100).toFixed(2) || 0;
      setPaymentForm({
        ...paymentForm,
        extraCharges: editInvoice.extracharges || 0,
        discountpercentage: editInvoice.discountinpercent || 0,
        discount,
        discountAmmount: editInvoice.discount || 0,
        gstAmmount,
        gstpercentage: editInvoice.gstpercent,
      });
    }
    return () => resetForm();
  }, [editInvoiceLoading]);
  useEffect(() => {
    resetForm();
  }, [invoiceComplete, params]);

  return (
    <div className="payment-detail-wrapper">
      <div className="show-payment-amounts">
        <CalculatedPayment
          setting={setting}
          language={language}
          paymentForm={paymentForm}
          prevBalance={prevBalance}
          totalItems={totalItems}
          totalPrice={totalPrice}
          grandTotal={grandTotal}
          totalQuantity={totalQuantity}
          selectedCustomer={selectedCustomer}
          invoiceComplete={invoiceComplete}
          invoiceItemChanged={invoiceItemChanged}
          params={params}
          editInvoice={editInvoice}
          customerInformation={customerInformation}
          setSelectedCustomer={setSelectedCustomer}
          editInvoiceLoading={editInvoiceLoading}
          setCustomerInformation={setCustomerInformation}
        />
      </div>
    </div>
  );
};
export default memo(Payment);

Payment.defaultProps = {
  totalPrice: 0,
};
Payment.propTypes = {
  selectedCustomer: PropTypes.object.isRequired,
  totalPrice: PropTypes.number,
  language: PropTypes.object.isRequired,
  setting: PropTypes.object.isRequired,
  invoiceComplete: PropTypes.bool.isRequired,
  editInvoiceLoading: PropTypes.bool.isRequired,
  invoiceItemChanged: PropTypes.bool.isRequired,
  params: PropTypes.object.isRequired,
  editInvoice: PropTypes.object.isRequired,

  totalItems: PropTypes.any.isRequired,
  totalQuantity: PropTypes.any.isRequired,
  paymentForm: PropTypes.object.isRequired,

  setPaymentForm: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  setCustomerInformation: PropTypes.func.isRequired,
  customerInformation: PropTypes.object.isRequired,
  // setCustomerProduct: PropTypes.func.isRequired,
  setSelectedCustomer: PropTypes.func.isRequired,
  // setReceiptInfo: PropTypes.func.isRequired,
  // receiptInfo: PropTypes.object.isRequired,
};

/**
 * Need improvment in this component
 */
