import { useDispatch, useSelector } from 'react-redux';
import Icon, { ReloadOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
// import { getLocalStorage } from 'modules/common/utility/localStorage';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { invoiceListComplete } from 'modules/components/invoice/invoiceReducer';

const SyncInvoice = ({ handleRefreshApi }) => {
  const dispatch = useDispatch();
  //   const [invoiceSize, setInvoiceSize] = useState(0);
  // const sync = useSelector((state) => state.invoiceReducer.sync);

  const isLoading = useSelector((state) => state.invoiceReducer.invoiceLoader);
  const invoiceSize = useSelector((state) => state.invoiceReducer.invoiceSize);
  //console.log("invoiceSize",invoiceSize)
  const isCustomerLoading = useSelector(
    (state) => state.customerReducer.isLoading
  );
  const isProductLoading = useSelector(
    (state) => state.productReducer.isLoading
  );
  //console.log("invoice list",invoiceListComplete())
  useEffect(() => {
    dispatch(invoiceListComplete());
  }, [dispatch]);

  const loaderIcon =
    isLoading || isCustomerLoading || isProductLoading ? (
      <Spin size="small" />
    ) : (
      <Icon
        component={() => (
          <ReloadOutlined
            onClick={() => handleRefreshApi(dispatch, isLoading)}
            className="reload_icon"
          />
        )}
      />
    );

  return (
    <span>
      {loaderIcon}
      <span data-testid="invoice-size" className="superscript">
        {`(${invoiceSize})`}
      </span>
    </span>
  );
};

export default SyncInvoice;

SyncInvoice.propTypes = {
  handleRefreshApi: PropTypes.func.isRequired,
};
