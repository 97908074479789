/* Libraries Import */
import { Profiler, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Form, Typography } from 'antd';
import PropTypes from 'prop-types';

/* Relative Path Import */
import { updateTotal } from 'modules/components/common/commonReducer';
import { InputNumber, Select, DatePicker } from 'common';
import CustomerDetails from './CustomerDetail/CustomerDetial';
import { selectedPromoToState } from 'modules/components/PromoDiscount/PromoDiscount';

/* CSS Style Import */
import 'theme/globalStyle.css';
import { handleChangeInvoices } from 'modules/common/utility/utility';
import { getLocalStorage } from 'modules/common/utility/localStorage';
import { promoDiscountFunction } from 'modules/common/utility/utility';
import { setLocalStorage } from 'modules/common/utility/localStorage';
const AdvanceOption = ({
  selectedCustomer,
  totalPrice,
  language,
  setting,
  invoiceComplete,
  editInvoiceLoading,
  params,
  editInvoice,
  method,
  customerInformation,
  paymentForm,
  setPaymentForm,
  initialValues,
  handleComplete,
  setReceiptTime,
  receiptTime,
  invoiceNumber,
  setInvoiceItemChanged,
  invoiceItemChanged,
}) => {
  const prevBalance = Object.values(selectedCustomer).length
    ? selectedCustomer.current_balance || 0
    : 0;

  const dispatch = useDispatch();




  /* Calculating grand Total (totalprice - gst + discount - extra charges) */
  /**
   * Grand Total is total after adding gst , discount and extracharges
   */
  const grandTotal = parseFloat(
    totalPrice +
    parseFloat(paymentForm.gstAmmount || 0) +
    parseFloat(paymentForm.extraCharges || 0) -
    parseFloat(paymentForm.discount || 0) -
    parseFloat(paymentForm.promoDiscount || 0)                                  // Promo Discount 
  ).toFixed(2);

  /**
   * Calculating payableAmount (totalprice - gst + discount - extra charges - previousBalance)
   * PayableAmount is grantTotal and previous balance of customer
   */
  const payableAmount = parseFloat(
    parseFloat(prevBalance) + parseFloat(grandTotal)
  ).toFixed(2);

  const handleGstPercentage = (value) => {
    const gstAmmount =
      value && totalPrice ? ((totalPrice * value) / 100).toFixed(2) || 0 : 0;

    setPaymentForm({
      ...paymentForm,
      gstPercentage: value,
      gstAmmount: parseFloat(gstAmmount),
    });
  };

  /* reseting the form Values */
  const resetForm = () => {
    setPaymentForm({ ...paymentForm, initialValues });
  };

  /**
   * @function handleSubmit - this function updating the receipt Time stamp
   * @description if user changed the receipt Time stamp, change flag will chaged to true
   * if user not changed the receipt Time stamp, then invoice saved time will be send to the server
   * else updated time that updated by user will be sent to the server
   */

  const handleChangeDate = (value) => {
    setReceiptTime({ datetime: value, ischange: true });
  };

  /**
   * We will receieve discount in percentage and converting it into value
   * @param {string || number} value
   */
  const handleDiscountPercentage = (value) => {
    const discount =
      value && totalPrice
        ? ((totalPrice * parseFloat(value)) / 100).toFixed(2) || 0
        : 0;
    setPaymentForm({
      ...paymentForm,
      discountPercentage: parseFloat(value),
      discount: parseFloat(discount),
    });
  };


  const handlePromoDiscount = (value) => {                     //Promo discount function 
    const discountPromo =
      value && totalPrice
        ? ((totalPrice * parseFloat(value)) / 100).toFixed(2) || 0
        : 0;
    setPaymentForm({
      ...paymentForm,
      discountPercentage: parseFloat(value),
      //  promoDiscount: parseFloat(promoDiscount),
    });
  }

  /**
   * We will receieve extrachages and adding them to state variable
   *
   */
  const handleExtraCharges = (value) => {
    setPaymentForm({
      ...paymentForm,
      extraCharges: value || 0,
    });
  };

  /**
   * We will receieve dicount and adding them to state variable
   *
   */
  const handleDiscountValue = (value = paymentForm.discount) => {
    const discountpercentage =
      value && totalPrice ? ((100 * value) / totalPrice).toFixed(2) : 0;
    setPaymentForm({
      ...paymentForm,
      discountPercentage: parseFloat(discountpercentage),
      discount: parseFloat(value),
    });
  };

  /* useEffects */
  /**
   * this useeffect is for editing invoice. Here we are setting variable to our state variables
   */
  useEffect(() => {
    if (params.id && Object.keys(editInvoice).length > 0) {
      const gstAmmount =
        ((totalPrice * editInvoice.gstpercent) / 100).toFixed(2) || 0;
      const discount =
        ((totalPrice * editInvoice.discountinpercent) / 100).toFixed(2) || 0;
      setPaymentForm({
        ...paymentForm,
        extraCharges: editInvoice.extracharges || 0,
        discountPercentage: editInvoice.discountinpercent || 0,
        discount,
        discountAmmount: editInvoice.discount || 0,
        gstAmmount,
        gstPercentage: editInvoice.gstpercent,
      });
    }
    return () => resetForm();
  }, [editInvoiceLoading]);

  /**
   * Reseting the form
   */
  useEffect(() => {
    resetForm();
  }, [invoiceComplete, params]);

  useEffect(() => {
    const gstAmmount =
      paymentForm.gstPercentage && totalPrice
        ? ((totalPrice * paymentForm.gstPercentage) / 100).toFixed(2) || 0
        : 0;

    /* eslint-disable */
    const discount =
      paymentForm.discountPercentage && totalPrice
        ? (
          (totalPrice * parseFloat(paymentForm.discountPercentage)) /
          100
        ).toFixed(2) || 0
        : 0;
    /* eslint-enable */
    setPaymentForm({
      ...paymentForm,
      gstAmmount: parseFloat(gstAmmount),
      discount: parseFloat(discount),
    });
  }, [totalPrice]);

  // Dispatchign the value to redux
  useEffect(() => {
    dispatch(updateTotal({ grandTotal, payableAmount }));
  }, [invoiceItemChanged]);

  const roles = useSelector((state) => state.commonReducer.response)?.data.roles;
  const isPermittedToChangeDate = roles.includes("Invoice Date.add")


  const promoDiscountValues = useSelector((state) => state.PromoDiscount.response?.data?.promoCode);        //promo code started
  const products = useSelector((state) => state.productReducer.products);



  const { Option } = Select;
  const onChange = (value) => {
    console.log(`selected ${value}`);
  };

  const onSearch = (value) => {
    console.log('search:', value);
  };


  const handleChange = ({ name, value }, uuid) => {
    handleChangeInvoices(name, value, uuid);
    setInvoiceItemChanged(true);
  };

  const customerProduct = useSelector(
    (state) => state.customerReducer.customerProduct
  );

  const promoDiscountfromState = useSelector(
    (state) => state.PromoDiscount.selectedPromo
  );


  const promoDiscountFunctionForInvoice = (record, promoDiscountfromState) => {

    console.log("promoDiscountFunctionForInvoice")
    if (promoDiscountfromState && promoDiscountfromState.product_type == "all_stock" && promoDiscountfromState.flat_discount !== null) {
      record.promocode = ({
        value: promoDiscountfromState.flat_discount || 0,
        name : promoDiscountfromState.name,
        type : "flat",
        promo_type :"not percentage" 
      })
    }
    else if (promoDiscountfromState && promoDiscountfromState.product_type == "all_stock" && promoDiscountfromState.discount_percent !== null) {
      record.promocode = ({
        value: promoDiscountfromState.discount_percent || 0,
        name : promoDiscountfromState.name,
        type : "flat",
        promo_type : "percentage" 
      })
    }
    else {
      let result = 0;
      if (promoDiscountfromState && promoDiscountfromState.product_type == null && promoDiscountfromState.flat_discount !== null) {
        promoDiscountfromState.product_detail.map((item) => {
          if (item == record.id) {
            record.promocode = ({
              value: promoDiscountfromState.flat_discount || 0,
              name : promoDiscountfromState.name,
              type : "not flat",
              promo_type :"not percent" 
            })
          }
        })
      }
      else {
        promoDiscountfromState.product_detail.map((item) => {
          record.promocode = ({
            value: promoDiscountfromState.flat_discount || 0,
            name : promoDiscountfromState.name,
            type : "not flat",
            promo_type :" percent" 
          })
        })
      }
    }
  }
  // const invoiceList = getLocalStorage('invoice', [])
  const promoFunction = (id) => {
    const selectedPromo = promoDiscountValues.filter((item) => item.id === id)[0]; 
    dispatch(selectedPromoToState(selectedPromo))
  }
  return (
    <Profiler id="invoice-partyName-profiler">
      <CustomerDetails
        selectedCustomer={selectedCustomer}
        customerInformation={customerInformation}
        handleComplete={handleComplete}
        setReceiptTime={setReceiptTime}
        receiptTime={receiptTime}
        invoiceNumber={invoiceNumber}
        language={language}
        setting={setting}
      />
      <Form>
        <div>
          <table>
            <tbody>
              <tr className="hide-from-print">
                <td>
                  <Typography.Title level={5}>
                    {language[setting.user_language].date}
                  </Typography.Title>
                </td>
                <td>
                  <DatePicker
                    style={{ width: '100%', height: '30px`' }}
                    onChange={(value) => handleChangeDate(value)}
                    format="YYYY/MM/DD HH:mm"
                    showTime
                    value={moment(receiptTime.datetime)}
                    name="startDateTime"
                    disabled={!isPermittedToChangeDate}
                  />
                </td>
              </tr>
              <tr>
                <td style={{ width: '35%' }}>
                  <Typography.Title level={5}>
                    {language[setting.user_language].invoiceType ||
                      language.en.invoiceType ||
                      'Invoice Type'}
                    :
                  </Typography.Title>
                </td>
                <td>
                <Typography.Title level={5}>
                    {language[setting.user_language].method ||
                      language.en.method ||
                      method}
                    
                  </Typography.Title>
                </td>
              </tr>
              <tr className="hide-from-print">
                <td>
                  <Typography>
                    {language[setting.user_language].gst ||
                      language.en.gst ||
                      'GST'}
                    :
                  </Typography>
                </td>
                <td>
                  <Form.Item>
                    <InputNumber
                      min={0}
                      name="gstPercentage"
                      value={paymentForm.gstPercentage}
                      onChange={(value) => handleGstPercentage(value)}
                      controls={false}
                    />
                  </Form.Item>
                </td>
              </tr>
              <tr className="hide-from-print">
                <td>
                  <Typography>
                    {'Promo Code'}
                  </Typography>
                </td>
                <td>
                  <Select
                    placeholder={"Promo Code"}
                    onChange={(id) => { id !== "Not Apply" && promoDiscountValues && promoFunction(id) }}
                    options={promoDiscountValues && promoDiscountValues.map((item, index) => ({ label: item.name, value: item.id, key: index }))} // label: item.value
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <Typography>
                    {language[setting.user_language].extraCharges ||
                      language.en.extraCharges ||
                      'Extra Charges'}
                    :
                  </Typography>
                </td>
                <td>
                  <Form.Item>
                    <InputNumber
                      name="extracharges"
                      min={0}
                      controls={false}
                      value={paymentForm.extraCharges || 0}
                      onChange={(value) => handleExtraCharges(value)}
                    />
                  </Form.Item>
                </td>
              </tr>
              {
                roles && roles.includes("Products.view") &&
                <tr className="hide-from-print">
                  <td>
                    <Typography>
                      {language[setting.user_language].discount ||
                        language.en.discount ||
                        'Discount'}
                      :
                    </Typography>

                  </td>
                  <td>
                    <Form.Item>
                      <InputNumber
                        name="discountPercentage"
                        min={0}
                        value={paymentForm.discountPercentage}
                        onChange={(value) => handleDiscountPercentage(value)}
                        controls={false}
                        prefix="%"
                      />
                    </Form.Item>
                  </td>
                </tr>
              }
              {
                roles && roles.includes("Products.view") &&
                <tr>
                  <td />
                  <td>
                    <Form.Item>
                      <InputNumber
                        name="discount"
                        min={0}
                        value={paymentForm.discount}
                        onChange={(value) => handleDiscountValue(value)}
                        controls={false}
                      />
                    </Form.Item>
                  </td>
                </tr>
              }
              <tr>
                <td>
                  <br />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Form>
    </Profiler>
  );
};
export default AdvanceOption;

AdvanceOption.propTypes = {
  setting: PropTypes.object.isRequired,
  language: PropTypes.object.isRequired,
  paymentForm: PropTypes.object.isRequired,
  totalPrice: PropTypes.number.isRequired,
  invoiceComplete: PropTypes.bool.isRequired,
  editInvoiceLoading: PropTypes.bool.isRequired,
  params: PropTypes.object.isRequired,
  editInvoice: PropTypes.object.isRequired,
  selectedCustomer: PropTypes.object.isRequired,
  invoiceItemChanged: PropTypes.bool.isRequired,
  method: PropTypes.string.isRequired,
  setPaymentForm: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  customerInformation: PropTypes.object.isRequired,
  handleComplete: PropTypes.func.isRequired,
  setReceiptTime: PropTypes.func.isRequired,
  receiptTime: PropTypes.object.isRequired,
  invoiceNumber: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};
