import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Form, Typography, Col, Row, Button } from "antd";
import { InputNumber } from "common";
import InvoicePageButton from "../../../../../common/InvoicePageButton/InvoicePageButton";
import Modal from "../../../../../common/Modal/Modal";
import { v4 as uuid } from "uuid";
import { Table } from "common";
import { getLocalStorage, setLocalStorage , removeFromLocalStorage , updateLocalStorageArray } from "../../../../../modules/common/utility/localStorage";
import { identity } from "lodash";

const Discount = ({
  language,
  setting: { user_language },
  paymentForm,
  totalPrice,
  setPaymentForm,
  SearchInvoiceApiProduct,
  SetSearchInvoiceApiProduct,
}) => {
  const roles = useSelector((state) => state.commonReducer.response)?.data
    .roles;
  const ln = language[user_language];
  const [isModalVisible, setModalVisibility] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [modalTitle, setModalTitle] = useState("");
  const [IsDiscountVisible, SetIsDiscountVisible] = useState(false);
  const [GetindividualDiscountValue, SetindividualDiscountValue] = useState("");

  const [IsDiscountPercentageVisible, SetIsDiscountPercentageVisible] =
    useState(false);
  const [GetinvoiceData, SetinvoiceData] = useState([]);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [GetCheckData, SetCheckData] = useState([]);
  const [GetNewInvoiceData, SetNewInvoiceData] = useState([]);
  const openModal = (title, content) => {
    setModalTitle(title);
    setModalContent(content);
    setModalVisibility(true);
  };

  // This Function Open Modal,Passing Content and Title when this function is call
  const ButtonClickHandler = (title, content) => () => {
    openModal(title, content);
  };

  // This function close the Modal
  const handleModalClose = () => {
    setModalVisibility(false);
  };

  // In this Function Apply Discount in Percentage on whole Invoice which Run in BackGround
  const handleDiscountPercentage = (value) => {
    const discount =
      value && totalPrice
        ? ((totalPrice * parseFloat(value)) / 100).toFixed(2) || 0
        : 0;
    setPaymentForm({
      ...paymentForm,
      discountPercentage: parseFloat(value),
      discount: parseFloat(discount),
    });
  };

  // In this function Apply Discount Amount/value on Whole Invoice which Run in BackGround
  const handleDiscountValue = (value = paymentForm.discount) => {
    const discountpercentage =
      value && totalPrice ? ((100 * value) / totalPrice).toFixed(2) : 0;
    setPaymentForm({
      ...paymentForm,
      discountPercentage: parseFloat(discountpercentage),
      discount: parseFloat(value),
    });
  };

  // In this function it handle or give Individual Product Discount
  function handleDiscountAmountIndividual(value,numberchange) {
    const data = getLocalStorage("invoice",[]);

   
    // console.log('GetindividualDiscountValue in Discount in function ', numberchange);
    if(value != '' && numberchange != ''){
      for (var key in value) {
        // console.log('numberchange in Discount inner loop ', numberchange);
         data[value[key]].total = data[value[key]].total - numberchange;
        //  console.log('numberchange inside the for loop value ', numberchange);
        //  console.log('data[value[key]].total: ', data[value[key]].total);
        //  console.log('data: ', data);
         setLocalStorage("invoice",data);
         SetSearchInvoiceApiProduct(data)
    }
    }
  }

  useEffect(() => {
    handleDiscountAmountIndividual(selectedRowKeys,GetindividualDiscountValue);
  }, [selectedRowKeys,GetindividualDiscountValue]);

  // On Click on Handler this Function is Call For Show Discount Percent Input field
  const ShowDiscountPercentButtonHandler = (value) => () => {
    SetIsDiscountPercentageVisible(value);
  };

  // On Click on Handler this Function is Call For Show Discount Input field
  const ShowDiscountButtonHandler = (value) => () => {
    SetIsDiscountVisible(value);
  };

  //-------This is use In Discount Table CheckBox To Select the Row Index----------------------
  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  useEffect(() => {
    onSelectChange();
  }, []);
  //----------------------

  //Show Item Data From Product Table into Discount=> Nested Inner Table List of Product
  useEffect(() => {
    const data = getLocalStorage("invoice", []);
    console.log("Get Data Show From LocalStorage Discount ", data);
    SetinvoiceData([]);
    data.map((item, index) => {
      SetinvoiceData((prevData) => [
        ...prevData,
        {
          key: index,
          productname: item.productname,
          quantity: item.quantity,
          total: item.total,
        },
      ]);
    });
  }, []);
  console.log("GetinvoiceData: ", GetinvoiceData);

  const buttonsData = [
    {
      title: "Discount % Individual",
      handler: ButtonClickHandler(
        "Discount % Individual",
        //ContainerStart
        <div>
          <Table
            rowSelection={rowSelection}
            pagination={{
              pageSize: 50,
            }}
            scroll={{
              y: 240,
            }}
            columns={columns}
            dataSource={GetinvoiceData}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  console.log(record);
                  console.log("rowIndex: ", rowIndex);
                  console.log("event: ", event);
                },
              };
            }}
            style={{ cursor: "pointer" }}
          />
          <h1>Enter Value To Discount Per Amount On Individual Product</h1>
          <InputNumber
            type="number"
            value={GetindividualDiscountValue}
            onChange={(e) => SetindividualDiscountValue(e)}
          />
        </div> // ContainerEnd
      ),
    },

    {
      title: "Discount Amount Individual",
      handler: ButtonClickHandler(
        "Discount Amount Individual",
        //ContainerStart
        <div>
          
          <Table
            rowSelection={rowSelection}
            pagination={{
              pageSize: 50,
            }}
            scroll={{
              y: 240,
            }}
            columns={columns}
            dataSource={GetinvoiceData}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  console.log(record);
                  console.log("rowIndex: ", rowIndex);
                },
              };
            }}
            style={{ cursor: "pointer" }}
          />
   
          <h1>Enter Value To Discount Per Amount On Individual Product</h1>
          <InputNumber
            type="number"
            value={GetindividualDiscountValue}
            onChange={(e) => SetindividualDiscountValue(e)}
          />
        </div> // ContainerEnd
      ),
    },

    {
      title: "Discount Amount in Whole",
      handler: ShowDiscountButtonHandler(true),
    },

    {
      title: "Discount % in whole",
      handler: ShowDiscountPercentButtonHandler(true),
    },
  ];

  return (
    <table>
      <tbody>
        {roles &&
          IsDiscountPercentageVisible &&
          roles.includes("Products.view") && (
            <tr className="hide-from-print">
              <td>
                <Form.Item>
                  <InputNumber
                    name="discountPercentage"
                    min={0}
                    value={paymentForm.discountPercentage}
                    onChange={(value) => handleDiscountPercentage(value)}
                    controls={false}
                    prefix="%"
                  />
                </Form.Item>
              </td>
            </tr>
          )}
        {roles && IsDiscountVisible && roles.includes("Products.view") && (
          <tr>
            <td>
              <Form.Item>
                <InputNumber
                  name="discount"
                  min={0}
                  value={paymentForm.discount}
                  onChange={(value) => handleDiscountValue(value)}
                  controls={false}
                />
              </Form.Item>
            </td>
          </tr>
        )}

        {roles && roles.includes("Products.view") && (
          <tr>
            <td>
              <Form.Item>
                <Col xs={{ span: 24 }}>
                  <Row gutter={[16, 16]}>
                    {buttonsData.map((button, i) => {
                      console.log(button);
                      return (
                        <Col xs={{ span: 4 }}>
                          <InvoicePageButton
                            key={i}
                            className="invoice_button"
                            type="primary"
                            onClick={button.handler}
                          >
                            {button.title}
                          </InvoicePageButton>

                          {isModalVisible && (
                            <Modal
                              onClose={handleModalClose}
                              title={modalTitle}
                            >
                              {modalContent}
                            </Modal>
                          )}
                        </Col>
                      );
                    })}
                  </Row>
                </Col>
              </Form.Item>
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default Discount;

const columns = [
  {
    title: "ProductName",
    dataIndex: "productname",
    key: "productname",
  },
  {
    title: "Quantity",
    dataIndex: "quantity",
    key: "quantity",
  },
  {
    title: "Total",
    dataIndex: "total",
    key: "total",
  },
];
