import { message } from 'antd';
import axios from 'axios';
import { clearLocalStorage } from 'modules/common/utility/localStorage';
import { SERVER_PATH } from 'modules/common/utility/ServerPath';
import { getToken } from 'modules/common/utility/utility';

const instance = axios.create({
  baseURL: SERVER_PATH,
  withCredentials: true,
});

instance.interceptors.request.use(
  (config) => {
    if (
      config.url !== `${SERVER_PATH}auth/login` &&
      config.url !== `${SERVER_PATH}auth/register`
    ) {
      config.headers.Authorization = `Bearer ${getToken()}`;
    }
    return Promise.resolve(config);
  },

  (error) => Promise.reject(error)
);

instance.interceptors.response.use(
  (response) => {
    if (response.config.url !== 'activity/save') {
      message.success(response.data.message);
    }

    return Promise.resolve(response);
  },

  (error) => {
    const errorMessage = error.response ? error.response : error.message;
    message.error(errorMessage);
    if (error.response.status === 401) {
      clearLocalStorage();
    }
    return Promise.reject(error.response);
  }
);
export default instance;
