import { Navigate, Route, Routes } from 'react-router-dom';
import PropTypes from 'prop-types';
import Invoice from 'components/sale/invoice/Invoice';

import { LayoutMain } from 'common';
import NewExpenditure from 'components/Expenditure/NewExpenditure';
import Salary from 'components/Salary/Salary';
import MinimalLayout from 'layout/minimalLayout/MinimalLayout';
import { Payment } from 'components/Payment';

import Login from 'components/Autheticate/Login/Login';
import SignUp from 'components/Autheticate/SignUp/SignUp';
import { ExpenditureReport } from 'components/ExpenditureReport';
import { Remainder } from 'components/Remainder';
import { Delivery } from 'components/Delivery';
import { Return } from 'components/Return';

import EditInvoice from 'components/EditInvoice/EditInvoice';
import Error404 from 'components/Error/Error404';
import Dashboard from 'components/Dashboard/Dashboard';
import { isAuthFlag } from '../utility/utility';
import { useSelector } from 'react-redux';
import Forbidden from 'components/forbidden/Forbidden';

// import { isAuthFlag } from '../utility/utility';

export const AuthRoute = ({ children }) => {
  if (!isAuthFlag()) {
    return <Navigate to="/login" />;
  }
  return children;
};

export const AuthPublicRoute = ({ children }) => {
  if (!isAuthFlag()) {
    return <Navigate to="/login" />;
  }
  return children;
};





const ProtectedRouting = () => {

  const roles = useSelector((state) => state.commonReducer.response)?.data?.roles;
  console.log(roles)
  const Checkpermission = ({ children, permission, permission1 }) => {

    if (roles && roles.includes(permission)) return children

    else if (roles && roles.includes(permission && !permission1)) return children

    else { return roles ? <Navigate to="/forbidden" /> : <></> }
  }


  //console.log("these are roles", roles);
  return (
    <Routes>
      <Route
        path="/"
        element={
          <AuthRoute>
            <LayoutMain />
          </AuthRoute>
        }
      >
        <Route path="/forbidden" element={<Forbidden />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/invoice">
          <Route path="" element={<Checkpermission permission="Invoices.add" permission1="Products.view" > <Invoice method='Sale' /> </Checkpermission>}/>
          <Route path="purchase" element={<Checkpermission permission="Invoices.add" permission1="Products.view" > <Invoice method='Purchase' /> </Checkpermission>} />
          <Route path="return" element={<Checkpermission permission="Invoices.add" permission1="Products.view" > <Invoice method='Return' /> </Checkpermission>} />
        </Route>


        {/* under development */}
        {/* <Route path="invoice/:id" element={<Invoice />} /> */}

        <Route path="expenditure" element={<Checkpermission permission="Expenditure.add"><NewExpenditure /></Checkpermission>} />
        <Route path="salary" element={<Salary />} />
        <Route path="payment" element={<Checkpermission permission="Payments.add"><Payment /></Checkpermission>} />
        <Route path="expenditure-report" element={<ExpenditureReport />} />
        {/* <Route path="remainder" element={<Remainder />} /> */}
        <Route path="delivery" element={<Delivery />} />
        <Route path="return" element={<Return />} />
        <Route path="edit-invoice" element={<Checkpermission permission="Invoices.edit"><EditInvoice /></Checkpermission>} />
        <Route path="/dashboard" element={<Dashboard />} />
      </Route>
      <Route path="/" element={<MinimalLayout />}>
        <Route path="login" element={<Login />} />
        <Route path="signup" element={<SignUp />} />
      </Route>
      <Route path="*" element={<Error404 />} />
    </Routes>
  )
}
export default ProtectedRouting;

AuthRoute.propTypes = {
  children: PropTypes.any.isRequired,
};
AuthPublicRoute.propTypes = {
  children: PropTypes.any.isRequired,
};
