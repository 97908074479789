import React from 'react';
import { Row, Col } from 'antd';
import InvoicePageButton from 'common/InvoicePageButton/InvoicePageButton';
export const calcStyles = {
  paddingRight: '16px'
}


const CalculatorDigits = ({ language, setting, paymentWays, setPaymentWats }) => {
  const ln = language[setting.user_language];
  const buttons = [
    { label: ln.seven, span: 8 },
    { label: ln.eight, span: 8 },
    { label: ln.nine, span: 8 },
    { label: ln.four, span: 8 },
    { label: ln.five, span: 8 },
    { label: ln.six, span: 8 },
    { label: ln.one, span: 8 },
    { label: ln.two, span: 8 },
    { label: ln.three, span: 8 },
    { label: ln.zero, span: 16 },
    { label: '.', span: 8 },
  ]




  return (
    <Row gutter={[0, 16]}>
      {buttons.map((button, index) => (
        <Col span={button.span} key={index} style={calcStyles}>
          <InvoicePageButton type="primary" className="calc_button" onClick={() => setPaymentWats([{ ...paymentWays, amount: button.label }])}>
            {button.label}
          </InvoicePageButton>
        </Col>
      ))}
    </Row>
  );
};

export default CalculatorDigits;
