import { setLocalStorage } from 'modules/common/utility/localStorage';
import { productApi } from 'service/products';

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

export const getProducts = createAsyncThunk(
  'products/get-products',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await productApi.getProductList();
      return data;
    } catch (error) {
      return rejectWithValue({ error });
    }
  }
);

const initialState = {
  products: [],
  isLoading: false,
  isError: false,
};
const product = createSlice({
  name: 'product',
  initialState,
  extraReducers: {
    [getProducts.pending]: (state) => {
      state.isLoading = true;
      state.isError = false;
    },
    [getProducts.fulfilled]: (state, { payload }) => {
      setLocalStorage('products', payload.data.products);
      state.isLoading = false;
      state.isError = false;
      state.products = payload.data.products;
    },
    [getProducts.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isError = true;
      state.response = payload;
    },
  },
});

export default product.reducer;
