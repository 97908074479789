import Alert from 'antd/lib/alert';
import PropTypes from 'prop-types';
import './Alert.scss';

const AntDAlertMessage = ({ message, description, type, showIcon }) => (
  <div className="antd-alert-wrapper">
    <Alert
      message={message}
      description={description}
      type={type}
      
      showIcon={showIcon}
    />
  </div>
);

export default AntDAlertMessage;

AntDAlertMessage.defaultProps = {
  showIcon: false,
  description: '',
};

AntDAlertMessage.propTypes = {
  message: PropTypes.string.isRequired,
  description: PropTypes.string,
  type: PropTypes.string.isRequired,
  showIcon: PropTypes.bool,
};
