/* eslint-disable */
import { Swiper, SwiperSlide } from 'swiper/react';
import { Menu } from 'antd';
import { NavLink } from 'react-router-dom';
import { routes } from 'common/CommonLayout/routing';
import 'swiper/css';
import './MobileMenu.scss';
/* eslint-enable */

const MobileMenu = () => (
  <div className="mobile-menu-wrapper">
    <Swiper spaceBetween={30} slidesPerView={4}>
      <Menu theme="dark" mode="inline">
        {routes.map(({ path, name, icon, key }) => (
          <SwiperSlide key={key}>
            {key !== '-1' ? (
              <div className="nav-link">
                <NavLink
                  to={path}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  {icon}
                  <span style={{ marginTop: '5px' }}>{name}</span>
                </NavLink>
              </div>
            ) : (
              <div>
                <a
                  target="_blank"
                  href={path}
                  rel="noreferrer"
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  {icon}
                  <span style={{ marginTop: '5px' }} className="website-link">
                    {' '}
                    {name}
                  </span>
                </a>
              </div>
            )}
          </SwiperSlide>
        ))}
      </Menu>
    </Swiper>
  </div>
);

export default MobileMenu;
