import React from 'react';
import PropTypes from 'prop-types';
import { Row } from 'antd';

const VerticalSpacer = ({ value }) => {
  const Style = { marginBottom: value };
  return (
    <div>
      <Row style={Style} />
    </div>
  );
};
export default VerticalSpacer;

VerticalSpacer.defaultProps = {
  value: '100px',
};
VerticalSpacer.propTypes = {
  value: PropTypes.any,
};
