// React Libraries and Components

import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
// import moment from 'moment';

// Relative Path

import { getLocalStorage } from 'modules/common/utility/localStorage';
import { getProducts } from 'modules/components/product/productReducer';
import { handleRefreshApi } from 'common/CommonLayout/HeaderMain';
import { getCustomersApi } from 'modules/components/customer/customerQuery';
// import moment from 'moment';

import MainInvoice from './MainInvoice/MainInvoice';

// CSS
import './Invoice.scss';
import 'theme/globalStyle.css';

/**
 * Initial Values for Saleman Information Component
 */
// const salemanInitialValues = {
//   receiptTime: moment(),
// };

const totalMilliSecond = 60000;

const Invoice = () => {
  // dispatch variables
  const dispatch = useDispatch();

  // reducer slector variables
  // state variables

  const [settings] = useState(getLocalStorage('setting'));

  // const [invoiceLoad, setInvoiceLoad] = useState(false);
  // const [receiptInfo, setReceiptInfo] = useState(salemanInitialValues);

  // const [totalQuantityChange, setTotalQuantityChange] = useState(false);

  // Need this code in future
  // const isSettingLoading = useSelector(
  //   (state) => state.commonReducer.isLoading
  // );

  /**
   * Dispatching action to redux to call products and cutomer API
   * So that we can update our products and customers
   */
  useEffect(async () => {
    dispatch(getProducts());
    dispatch(getCustomersApi());
  }, []);

  /**
   * We are hitting server after every interval. When saleman saved created a invoice for
   * a cusomter and internet was down then we will store invoice to localstorage and when server
   * is back to working , this interval will send invoice to server
   * Updating localstorage according to request.
   */
  useEffect(() => {
    const intervalLoop = setInterval(() => {
      handleRefreshApi(dispatch);
    }, totalMilliSecond * 30);
    return () => clearInterval(intervalLoop);
  }, []);

  // useEffect(() => {}, [params]);

  document.getElementsByTagName('html')[0].setAttribute('dir', settings.is_rtl === '1' ? 'rtl' : 'ltr');

  // return !Object.keys(printData).length ? (
  return (
    <div>
      <MainInvoice
        // setReceiptInfo={setReceiptInfo}
        // receiptInfo={receiptInfo}
        setting={settings}
      />
    </div>
  );
};
export default Invoice;
